import { Injectable } from '@angular/core';
import { FieldsOptionsService } from '@vdms-hq/api-contract';
import { map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  Extra,
  FieldDefinitionModel,
  FieldType,
  filterEmpty,
  Option,
  SelectOption,
  SelectOptionKey,
  ValueFormat,
} from '@vdms-hq/shared';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import { FormBuilderService } from './form-builder.service';
import { groupBy, isEqual, omitBy } from 'lodash';
import { fieldDefinitions } from '@vdms-hq/fields';
import { ApiFieldsSourceService, LanguageSources, LocalSources } from '@vdms-hq/selectors';
import { FormControl } from '@angular/forms';

function recordKeys<K extends PropertyKey, T>(object: Record<K, T>) {
  return Object.keys(object) as K[];
}

@Injectable({ providedIn: 'root' })
export class FieldRelationsService {
  filters: Record<string, (selectOptions: SelectOption[]) => SelectOption[]> = {};
  #destroyed$ = new Subject<void>();
  loadingFields$ = new BehaviorSubject<Record<string, boolean>>({});

  constructor(
    private fieldsOptionsService: FieldsOptionsService,
    private formBuilder: FormBuilderService,
    private apiFieldsSourceService: ApiFieldsSourceService,
  ) {}

  #prevSerialized = '{}';

  listenToFieldRelations() {
    this.filters = {};
    this.#prevSerialized = '{}';
    this.formBuilder.formGroup?.valueChanges
      .pipe(
        takeUntil(this.#destroyed$),
        map(() => {
          const next = this.formBuilder.formGroup?.getRawValue();
          const nextSerialized = JSON.stringify(next);

          if (nextSerialized === this.#prevSerialized) {
            return null;
          }

          const prevDeserialized = JSON.parse(this.#prevSerialized);
          const changed = omitBy(next, (value: SelectOptionKey, key: string) => {
            return key in prevDeserialized && isEqual(prevDeserialized[key], value);
          });

          const changedValue = Object.entries(changed);
          this.#prevSerialized = nextSerialized;

          if (changedValue.length > 1) {
            return null;
          } else {
            const [fieldId, value] = changedValue[0];

            const definition = this.formBuilder.getConfig(fieldId);

            if (value === null && definition) {
              // todo reset children
              return null;
            }

            if (!definition) {
              return null;
            }
            return [definition, value] as [FieldDefinitionModel, SelectOptionKey];
          }
        }),
        filterEmpty(),
        mergeMap(([definition, value]) => this.#getSelectedOptionDetails(definition, value).pipe(take(1))),
      )
      .subscribe((data) => {
        this.#getChildrenFieldsAndFilterOptions(data.childrenFields);
        this.#getParentFieldAndSetValue(data.parentField);
        this.#getSiblingsAndSetValue(data.extra);
      });
  }

  reset() {
    this.filters = {};
  }

  onDestroy() {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  #getSelectedOptionDetails(definition: FieldDefinitionModel, optionKey: SelectOptionKey) {
    const isLanguageSource = Object.values(LanguageSources).includes(definition.sourceListKey as LanguageSources);
    const isLocalSource = Object.values(LocalSources).includes(definition.sourceListKey as LocalSources);

    if (
      !definition ||
      !definition.sourceListKey ||
      isLanguageSource ||
      isLocalSource ||
      definition.input?.type === FieldType.TEXT
    ) {
      return EMPTY;
    }

    return this.#getFieldTypeOptionsByName(definition).pipe(
      switchMap((fieldOptions) => {
        const selectedOption = fieldOptions.filter((option) => option.key === optionKey)[0];
        if (selectedOption) {
          return this.#getFieldByUuid(selectedOption?.uuid);
        }
        return EMPTY;
      }),
      map((details) => ({
        optionName: definition.sourceListKey,
        childrenFields: this.#groupChildrenBy(details.child_fields),
        parentField: details.parent_field,
        extra: this.#reformatExtra(details.extra),
      })),
    );
  }

  #reformatExtra(extra: Extra | null): { type_name: string; value: string }[] {
    if (!extra) {
      return [];
    }
    const parsedExtra = JSON.parse(extra.siblings);
    if (parsedExtra.siblings && parsedExtra.siblings.length > 0) {
      return parsedExtra.siblings.map((item: { key: string; value: string }) => ({
        type_name: item.key,
        value: item.value,
      }));
    }
    return [];
  }

  #getParentFieldAndSetValue(parentField: Option | undefined) {
    if (!parentField) {
      return;
    }

    const fieldDefinition = this.#getFieldDefinition(parentField);
    if (fieldDefinition) {
      const control = this.formBuilder.getControl(fieldDefinition.id as string);
      control && control?.patchValue(parentField?.key);
      control && this.#markControlTouched(control);
    }
  }

  #getSiblingsAndSetValue(siblings: { type_name: string; value: string }[]) {
    if (siblings.length > 0) {
      siblings.forEach((item) => {
        const control = this.formBuilder.getControl(item.type_name);
        if (control) {
          control.patchValue(item.value);
          this.#markControlTouched(control);
        }
      });
    }
  }

  #getChildrenFieldsAndFilterOptions(childrenFields: Record<string, Option[]> | undefined) {
    if (!childrenFields) {
      return;
    }

    const includeOrphanChildren = false;

    const childrenKeys: SelectOptionKey[] = recordKeys(childrenFields);

    for (const childKey of childrenKeys) {
      const childKeyString = String(childKey);

      const children = childrenFields[childKeyString].map((orphan) => orphan.key);

      if (includeOrphanChildren) {
        this.apiFieldsSourceService
          .listAllByType(childKeyString)
          .pipe(
            take(1),
            map((fields) => fields.filter((field) => !field.parent_field)),
          )
          .subscribe((orphanFields) => {
            orphanFields.filter((item) => !item.parent_field).forEach((item) => children.push(item.key));

            this.#filterChild(childKeyString, children);
          });
      } else {
        this.#filterChild(childKeyString, children);
      }
    }
  }

  #getFieldDefinition(parentField: Option | undefined): FieldDefinitionModel | null {
    if (!parentField) {
      return null;
    }
    return fieldDefinitions.filter(
      (field) => field.sourceListKey === parentField?.type_name && field.format === ValueFormat.SELECTOR,
    )[0];
  }

  #getFieldTypeOptionsByName(field: FieldDefinitionModel) {
    this.#setLoading(field, true);
    return this.fieldsOptionsService.getTypeByName(field?.sourceListKey as string).pipe(
      map((field) => field.fields),
      tap(() => {
        this.#setLoading(field, false);
      }),
    );
  }

  #getFieldByUuid(fieldUuid: string) {
    return this.fieldsOptionsService.getField(fieldUuid).pipe(take(1));
  }

  #groupChildrenBy(children: Option[] | undefined) {
    if (!children) {
      return;
    }

    return groupBy(children, (child) => child.type_name);
  }

  #markControlTouched(value: FormControl<any>) {
    value.markAsTouched();
    value.markAsDirty();
    value.enable();
    return;
  }

  #filterChild(childKeyString: string, children: string[]) {
    this.filters[childKeyString] = (selectOptions) =>
      selectOptions.filter((selectOption) => {
        if (!selectOption.key) {
          return true;
        }

        return children.includes(String(selectOption.key));
      });
  }

  #setLoading(field: FieldDefinitionModel, isLoading: boolean) {
    const nextValue = {
      ...this.loadingFields$.value,
    };
    nextValue[field.id] = isLoading;
    this.loadingFields$.next(nextValue);
  }
}
