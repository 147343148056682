import { DataAction } from '@vdms-hq/ui';
import { AssetFlatView2Model } from './transformation/asset-flat-view-2.model';

export enum ResultsActions {
  PREVIEW = 'preview',
  APPROVE = 'approve',
  REJECT = 'reject',
  APPROVE_ORDER = 'approve_order',
  CLEAR_SELECTION = 'clear_selection',
  DESELECT_ALL = 'deselect_all',
  BATCH_UPDATE = 'batch_update',
  ADD_TO_COLLECTION = 'add_to_collection',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_LICENSED_PACKAGE = 'add_to_licensed_package',
  DELETE = 'delete',
  REMOVE_ALL = 'remove_all',
  EXPORT = 'export',
  EDIT = 'edit',
  DOWNLOAD_ALL_ASPERA_FOLDERS = 'download_all_aspera_folders',
  DOWNLOAD_ALL_ASPERA = 'download_all_aspera',
  DOWNLOAD_ASPERA = 'download_aspera',
  DOWNLOAD_SELECTED_ASPERA_FOLDERS = 'download_selected_aspera_folders',
  DOWNLOAD_SELECTED_ASPERA = 'download_selected_aspera',
  DOWNLOAD_SELECTED_HTTPS = 'download_selected_https',
  NEW_DELIVERY = 'new_delivery',
  TOAST_EMBARGO_ERROR = 'toast_embargo_error',
  TRANSCODE_STATUS = 'transcode_status',
  SET_AS_COLD = 'set_as_cold',
  SETTINGS = 'settings',
  DOWNLOAD_CLEARANCE = 'download_clearance_notes',
  RETRY_DELIVERY_DESTINATIONS = 'retry_delivery_destinations',
  UUID = 'uuid',
  TAB = 'tab',
  WINDOW = 'window',
  SEND_FILTERS_TO_LICENSED_PACKAGE = 'send_filters_to_licensed_package',
  SEND_FILTERS_TO_COLD = 'send_filters_to_cold',
  CHECKOUT = 'cart.checkout',
  CHECKOUT_DELIVERY_DETAILS = 'checkout_delivery_details',
  CHECKOUT_PLACE_ORDER = 'checkout_place_order',
  SUBMIT_CART = 'submit_cart',
  IMPOSE_QUARANTINE = 'asset.impose_quarantine',
  LIFT_QUARANTINE = 'asset.lift_quarantine',
  BATCH_LIFT_QUARANTINE = 'batch.lift_quarantine',
  BATCH_IMPOSE_QUARANTINE = 'batch.impose_quarantine',
  TRIGGER_NOTIFICATIONS = 'trigger_notifications',
}

export const assetResultsMenuConfig2: DataAction<AssetFlatView2Model>[] = [
  {
    key: 'tab',
    icon: 'tab',
    label: 'Open in new tab',
  },
  {
    key: 'window',
    icon: 'window',
    label: 'Open in new window',
  },
  {
    key: 'uuid',
    icon: 'uuid',
    label: 'Copy asset Uuid',
  },
];

export interface MinimalAssetModel {
  uuid: string;
  collections?: string[];
}
