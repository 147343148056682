import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission, PermissionService } from '@vdms-hq/activated-client';
import { CartApiService } from '@vdms-hq/api-contract';
import { CartAssetViewModel, CartStateService } from '@vdms-hq/cart-core';
import { ToastService } from '@vdms-hq/toast';
import { DataAction, UIConfirmationDialogService } from '@vdms-hq/ui';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { CartActions } from '../models/cart-actions.model';
import { CartCheckoutService } from './cart-checkout.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CartActionsService {
  #popToast = {
    DELETE_FAILURE: () =>
      this.toastService.error({
        id: 'cart_item_remove',
        message: 'pages.cart.notifications.remove.failed',
      }),
    DELETE_SUCCESS: () =>
      this.toastService.success({
        id: 'cart_item_remove',
        message: 'pages.cart.notifications.remove.done',
      }),
  };

  constructor(
    private cartService: CartApiService,
    private toastService: ToastService,
    private confirmationDialog: UIConfirmationDialogService,
    private permissionService: PermissionService,
    private cartStateService: CartStateService,
    private cartSubmitService: CartCheckoutService,
    private translate: TranslateService,
  ) {}

  removeCartItems(itemUuids: string[]) {
    this.confirmationDialog
      .openDelete({
        title: 'common.dialogs.cart.remove.title',
        message:
          itemUuids.length > 1
            ? this.translate.instant('common.dialogs.cart.remove.message_multiple', { count: itemUuids.length })
            : 'common.dialogs.cart.remove.message',
        okAction: {
          label: 'common.global.remove',
          color: 'warn',
        },
      })
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => this.cartStateService.isUpdating$.next(true)),
        switchMap(() => this.cartService.delete(itemUuids)),
      )
      .subscribe({
        next: () => this.#removeSuccess(),
        error: (err) => this.#removeFailed(err),
      });
  }

  removeAllItems() {
    this.confirmationDialog
      .openDelete({
        title: 'common.dialogs.cart.remove.title',
        message: 'common.dialogs.cart.remove.message_all',
        okAction: {
          label: 'common.global.remove',
          color: 'warn',
        },
      })
      .pipe(
        take(1),
        filter(Boolean),
        tap(() => this.cartStateService.isUpdating$.next(true)),
        switchMap(() => this.cartService.deleteAllItems()),
      )
      .subscribe({
        next: () => this.#removeAllSuccess(),
        error: (err) => this.#removeFailed(err),
      });
  }

  buildRemoveAction$<T extends CartAssetViewModel = CartAssetViewModel>(): Observable<DataAction<T> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART]).pipe(
      take(1),
      map((hasAccess) =>
        hasAccess
          ? {
              key: 'cart.delete',
              label: 'common.global.remove_from_cart',
              icon: 'delete',
              onDoubleClick: false,
            }
          : null,
      ),
    );
  }

  handleRemoveAction<T extends CartAssetViewModel = CartAssetViewModel>($event: { key: string; item?: T }): void {
    if ($event.key !== 'cart.delete' || !$event.item) {
      return;
    }

    this.removeCartItems([$event.item.context.uuid]);
  }

  buildCheckoutAction$<T extends CartAssetViewModel = CartAssetViewModel>(): Observable<DataAction<T> | null> {
    return this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART]).pipe(
      take(1),
      map((hasAccess) =>
        hasAccess
          ? {
              key: 'cart.checkout',
              label: 'pages.cart.actions.checkout',
              icon: 'cart',
              onDoubleClick: false,
            }
          : null,
      ),
    );
  }

  handleCheckoutAction<T extends CartAssetViewModel = CartAssetViewModel>($event: { key: string; item?: T }): void {
    if ($event.key !== CartActions.CHECKOUT) {
      return;
    }
    this.cartSubmitService.doCheckout();
  }

  applyDiscountCode($event: { key: string }) {
    if ($event.key !== CartActions.CHECKOUT_DISCOUNT_CHECK) {
      return;
    }
    this.cartStateService.checkDiscount();
  }

  resetDiscountCode($event: { key: string }) {
    if ($event.key !== CartActions.CHECKOUT_DISCOUNT_RESET) {
      return;
    }
    this.cartStateService.checkDiscount(true, !this.cartStateService.department$.value.uuid);
  }

  #removeSuccess() {
    this.#popToast.DELETE_SUCCESS();
    this.cartStateService.refreshCart();
  }

  #removeAllSuccess() {
    this.#popToast.DELETE_SUCCESS();
    this.cartStateService.refreshCart();
  }

  #removeFailed(err: HttpErrorResponse) {
    console.error(err);
    this.#popToast.DELETE_FAILURE();
  }
}
