import { inject, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRouteSnapshot, ParamMap, Router } from '@angular/router';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Filters, PageOptions, PersistenceSearchParams, PersistenceSearchQuery } from '@vdms-hq/api-contract';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { LibrarySchemaService } from './library-schema.service';
import { filterEmpty } from '@vdms-hq/shared';
import { AuthService } from '@vdms-hq/auth';
import { ClientAccessChecker } from '@vdms-hq/clients';
import { LibraryAssetSearchService } from './library-asset-search-service';

@Injectable({
  providedIn: 'root',
})
export class LibraryRouterService {
  private static delimiter = '--';

  private router = inject(Router);
  private assetSearchService = inject(LibraryAssetSearchService);
  private schemaService = inject(LibrarySchemaService);
  private activatedClientService = inject(ActivatedClientService);
  private clientAccessChecker = inject(ClientAccessChecker);
  private authService = inject(AuthService);

  #listeners?: Subscription;
  resolve(route: ActivatedRouteSnapshot): Observable<PersistenceSearchParams> {
    const segmentValues = route.queryParamMap.get('segments');
    const paramsMap = route.queryParamMap;

    return this.schemaService.activeSchemaFields$.pipe(
      withLatestFrom(this.activatedClientService.clientIdDefinite$),
      switchMap(([segments, selectedClientId]) =>
        this.#getPageOptions(route.paramMap).pipe(map((pageOptions) => ({ pageOptions, segments, selectedClientId }))),
      ),
      map(({ pageOptions, segments, selectedClientId }) => {
        const filters: Filters = {};
        let queryParams: PersistenceSearchQuery;

        if (segmentValues) {
          const values = segmentValues.split(LibraryRouterService.delimiter) ?? [];

          values.forEach((value, index) => {
            const filterDef = segments[index];
            if (!filterDef) {
              return;
            }

            if (!filterDef.filters.aggregationKey) {
              return;
            }

            filters[filterDef.id] = {
              filterDef,
              value,
            };
          });

          queryParams = PersistenceSearchQuery.fromFilters('', filters);
        } else {
          queryParams = PersistenceSearchQuery.empty();
        }

        const searchParams = new PersistenceSearchParams(
          queryParams,
          pageOptions,
          undefined,
          undefined,
          false,
          paramsMap.get('clientId') || selectedClientId,
        );

        this.assetSearchService.applyParams(searchParams);

        return searchParams;
      }),
      switchMap((searchParams) => {
        return this.clientAccessChecker.checkAccess(searchParams).pipe(
          filter((hasAccess) => hasAccess),
          map(() => searchParams),
        );
      }),
      tap((persistenceQueryParams) => {
        this.assetSearchService.applyParams(persistenceQueryParams);
      }),
    );
  }

  #toNumber(param?: string | number | null): number | null {
    if (param === undefined || param === null) {
      return null;
    }

    if (isNaN(Number(param))) {
      return null;
    }

    return Number(param);
  }

  #getPageOptions(paramsMap: ParamMap): Observable<PageOptions> {
    return this.authService.userAttributes$.pipe(
      filterEmpty(),
      take(1),
      map((userAttributes) => {
        const pageOptions: PageOptions = {
          perPage: userAttributes.vida?.preferredPageSize ?? PersistenceSearchParams.defaultPerPage,
          page: PersistenceSearchParams.defaultPage,
        };

        const perPage = this.#toNumber(paramsMap.get('perPage'));
        const page = this.#toNumber(paramsMap.get('page'));

        if (page !== undefined && page !== null) {
          pageOptions.page = Math.max(0, page);
        }
        if (perPage !== undefined && perPage !== null) {
          pageOptions.perPage = Math.max(0, Math.min(perPage, 300));
        }

        return pageOptions;
      }),
    );
  }

  registerListeners = () => {
    if (this.#listeners) {
      return;
    }

    this.#listeners = this.assetSearchService.currentParams$
      .pipe(withLatestFrom(this.schemaService.activeSchema$))
      .subscribe(([persistence, activeSchema]) => {
        const segments = activeSchema?.config ?? [];
        const values = segments
          .map((segment) => persistence.query.get(segment.id))
          .filter((filter) => filter !== null)
          .map(({ value }) => value);

        const segmentsQueryParams = values.join(LibraryRouterService.delimiter);

        this.router.navigate(['browse-library', activeSchema.id], { queryParams: { segments: segmentsQueryParams } });
      });
  };
}
