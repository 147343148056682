import { Injectable } from '@angular/core';
import { FieldConfigId, LocalDataSource } from '@vdms-hq/shared';
import { FieldDefinitionModel } from '@vdms-hq/shared';
import { map, switchMap, take } from 'rxjs/operators';
import { ClientFieldConfigModel } from '@vdms-hq/firebase-contract';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { FieldsFetcherService } from '@vdms-hq/fields';

@Injectable({
  providedIn: 'root',
})
export class FieldsDataSource extends LocalDataSource<FieldDefinitionModel> {
  constructor(private adminFieldsConfigService: FieldsFetcherService, private activatedClient: ActivatedClientService) {
    const fields$ = adminFieldsConfigService
      .getConfiguration$('_admin-all-fields')
      .pipe(map((config) => config.system));

    super(fields$, {
      pageSizeOptions: [100, 200, 300],
      defaultPageSize: 100,
    });
  }

  save(id: FieldConfigId, value?: Partial<FieldDefinitionModel>) {
    return this.activatedClient.fieldsValueChanges$.pipe(
      take(1),
      switchMap((fields) => {
        const nextFields: ClientFieldConfigModel[] = fields.filter((field) => field.id !== id);
        if (value) {
          nextFields.push({
            id,
            override: value,
          });
        }
        return this.activatedClient.update({ fields: nextFields });
      }),
    );
  }
}
