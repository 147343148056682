import { DestinationModel } from '../destinations/destination.model';
import {
  ApprovalStatus,
  FilterOrderStatus,
  OrderDeliveryStatus,
  OrderDownloadStatus,
  OrderStatus,
} from './order-status.model';
import { GroupCurrencyISO } from '@vdms-hq/shared';
import { JobTypeService } from '../jobs/jobs.model';

export enum OrderRoot {
  ROOT = 'order',
  ROOT_LIST = 'orders',
  ROOT_MEDIA_PULSE = 'orders/mediapulse',
}

export enum OrderEndpoints {
  CHECKOUT = '',
  DELIVERY_CONFIRMATION = 'delivery-confirmation/pdf',
  MANY_DELIVERY_CONFIRMATION = 'delivery-confirmations/pdf',
  DOWNLOAD_STATUS = 'download/status',
}

export interface OrdersFilters {
  'orders-type': OrdersType;
  status?: FilterOrderStatus;
  'download-status'?: OrderDownloadStatus;
  'delivery-status'?: OrderDeliveryStatus;
  'name-subject'?: string;
  text?: string;
  package_title?: string;
  'tx-date-from'?: string;
  'tx-date-to'?: string;
  'date-from'?: string;
  'date-to'?: string;
  wo_no?: string;
  po?: string;
}

export interface OrderAssetFilters {
  type: string[];
  'facility-of-origin': string;
  text: string;
  'tx-date-from': string;
  'tx-date-to': string;
}

export interface FlatMediaPulseOrder {
  wo_no: number;
  wo_desc: string;
  account_rep_desc: string;
  contact: string;
  po?: string;
  phase_code: string;
  status_no?: string;
  wo_end_dt: string;
  date_changed: string;
}

export interface FlatOrder {
  uuid: string;
  delivery_emails: string | null;
  package_title: string | null;
  wo_desc: string | null;
  wo_no: number | null;
  po: string | null;
  purchase_order_no: string | null;
  delivery_date: string | null;
  delivery_status: OrderDeliveryStatus;
  media_pulse_id: string | null;
  used_department?: string;
  sales_force_id: string | null;
  sales_force_name: string | null;
  status: OrderStatus;
  user: string;
  approval_status: ApprovalStatus;
  approval_comment: string | null;
  created_at: string;
  cartable: boolean;
  comment: string | null;
  sales_force_uri: string | null;
  total_assets: number;
  total_downloadable_assets: number;
  total_downloaded_assets: number;
  total_bytes: number;
  thumbnail?: string;
  shared_by: string;
  currency?: GroupCurrencyISO | null;
  total_price?: number | null;
  expires_at: string | null;
  completed_items?: number;
  has_embargoed_asset?: boolean;
  has_approved_item?: boolean;
  has_rejected_item?: boolean;
  transcode_progress: number | null;
  publish_progress: number | null;
  delivery_destinations?: DestinationModel[];
  type?: ORDER_TYPE;
  could_be_downloaded: boolean;
}

export interface OrderModel {
  uuid: string;
  approval_status: ApprovalStatus;
  approval_comment: string | null;
  clearance_notes_uri: string | null;
  cartable: boolean;
  expires_at: string | null;
  total_assets: number;
  total_downloadable_assets: number;
  total_downloaded_assets: number;
  delivery_status: OrderDeliveryStatus;
  comment: string | null;
  could_be_downloaded: boolean;
  created_at: string;
  package_title: string | null;
  delivery_emails: string | null;
  purchase_order_no: string | null;
  delivery_date: string | null;
  media_pulse_id: string | null;
  used_department?: string;
  sales_force_id: string | null;
  sales_force_name: string | null;
  estimated_restore_at: Date;
  status: OrderStatus;
  transcode_progress: number | null;
  publish_progress: number | null;
  user: string;
  sales_force_uri: string | null;
  total_bytes: number;
  thumbnail: string;
  shared_by: string;
  delivery_destinations?: DestinationModel[];
  embargoed_to?: string;
  completed_items?: number;
  has_embargoed_asset?: boolean;
  has_approved_item?: boolean;
  has_rejected_item?: boolean;
  type?: ORDER_TYPE;
}

export interface OrderModelPost {
  uuid: string;
  type?: ORDER_TYPE;
  subject?: string;
  package_title?: string;
  delivery_date: string | null;
  expires_at: string | null;
  comment?: string;
  delivery_emails?: string[];
  notification_emails?: string[];
  send_as_shared_pack: boolean;
  has_estimate: boolean;
  purchase_order_no?: string;
  destinations?: DestinationPostModel[];
  jobs?: JobTypePayloadInterface[];
  target_languages?: string[];
  sales_force?: {
    contact_uuid: string;
    opportunity_uuid: string;
    broadcaster_uuid: string;
    broadcaster_name: string;
    deadline: string;
    usage_rights_required: string;
    context_of_use: string;
    additional_details: string;
    end_client: string;
  };
  discount_code?: string | null;
  download_limit?: number | null;
  department_field_uuid?: string;
}

export enum ORDER_TYPE {
  DELIVERY_DESTINATION = 'delivery_destination',
  EMAIL_DELIVERY = 'email_delivery',
  WARM_UP = 'warm_up',
  WORKFLOW = 'workflow',
}

export type JobTypePayloadInterface = {
  uuid: string;
  service: JobTypeService;
  context: string;
  payload?: { [key: string]: any };
};

export interface OrderModelResponse {
  data: {
    id: number;
    uuid: string;
  };
  status: string;
}

export interface pathSource {
  source: string;
}

export interface OrdersExportMetadataPost {
  fields: {
    value: string;
    label: string;
  }[];
}

export type OrdersType = 'shared-packs' | 'all' | 'shared' | 'own';

type DestinationPostModel = { config_uuid?: string; uuid?: string };

export interface OrderConfirmationPdfResponse {
  url: string;
}

export type ManyOrderConfirmationPdfResponse = OrderConfirmationPdfResponse[];

export type OrderConfirmationPdfRequest = { uuids: string[] };

export interface PostAsperaDownloadStatus {
  transferUuid: string;
  status: ASPERA_DOWNLOAD_STATUS;
}

export enum ASPERA_DOWNLOAD_STATUS {
  SUCCESS = 'success',
  ERROR = 'error',
}
