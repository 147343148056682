import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransferableBatch, UploadStatus } from '../../logic/model/transferable-file.interface';
import { UIButtonModule, UIStatusModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'vdms-hq-storage-transferable-batch[batch]',
  templateUrl: './transferable-batch.component.html',
  styleUrls: ['./transferable-batch.component.scss'],
  standalone: true,
  imports: [CommonModule, UIStatusModule, TranslateModule, UIButtonModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferableBatchComponent {
  @Output() closeElement = new EventEmitter<void>();
  @Output() deleteElement = new EventEmitter<void>();
  readonly VISIBLE_FILES = 3;
  @Input() batch!: TransferableBatch;
  @Input() isProgressBar = true;
  cancellableStates: UploadStatus[] = ['pending', 'done', 'entity_created'];
}
