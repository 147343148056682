export const publicPrefixes = [
  '/user/login',
  '/user/link',
  '/user/custom-token',
  '/request-new-account',
  '/order/download',
  '/order/aspera-download',
  '/order/aspera-download/**',
  '/credential/youtube',
];
