<vdms-hq-orders-results
  [tableType]="tableType"
  [columnsSettingsScope]="scope"
  [title]="title"
  [filtersConfig]="filtersConfig"
  [actions]="(actions$ | async) || []"
  (action)="handleAction($event)"
  (changeDefaultView)="changeDefaultView($event)"
  [sharedE2eId]="'orders-wrapper'"
>
</vdms-hq-orders-results>
