<vdms-hq-ui-layout-section [breadcrumbs]="[{ name: 'Changeset:' }]" [margin]="false">
  <div class="code">
    <div class="code__item" *ngFor="let item of changeset | keyvalue">
      <ng-container *ngIf="!(item.value | isEmpty)">
        <div class="code__item--label">{{ item.key.toString() | formatLabel }}:</div>
        <div>{{ item.value | valueFormat }}</div>
      </ng-container>
    </div>
  </div>
</vdms-hq-ui-layout-section>
