import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DestinationDataSourceService } from '../../logic/destination-data-source';
import {
  FormSectionComponent,
  SimpleDetailsList,
  UIButtonModule,
  UIConfirmationDialogService,
  UIFormModule,
  UILayoutModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { E2eIdDirective, filterEmpty, SharedModule } from '@vdms-hq/shared';
import { DestinationFormService } from '../../logic/destination-form.service';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { transformVideoRuleToSendableObject } from '../../logic/delivery-destination-transformer';
import { MatDialog } from '@angular/material/dialog';
import { DestinationEditDialogComponent } from '../../components/destination-edit-dialog/edit-destination-dialog.component';
import { DestinationAccessMethodEnum, DestinationModel, UpdateDestinationModel } from '@vdms-hq/api-contract';

@Component({
  selector: 'vdms-hq-destination-details',
  templateUrl: './destination-details.component.html',
  styleUrls: ['./destination-details.component.scss'],
  imports: [
    CommonModule,
    UILayoutModule,
    RouterOutlet,
    UIFormModule,
    FormSectionComponent,
    TranslateModule,
    UIButtonModule,
    SharedModule,
    UISimpleDetailsListModule,
    E2eIdDirective,
  ],
  standalone: true,
})
export class DestinationDetailsComponent implements OnInit, OnDestroy {
  public dataSource = inject(DestinationDataSourceService);
  public formService = inject(DestinationFormService);
  private toastService = inject(ToastService);
  private dialog = inject(MatDialog);
  private confirmation = inject(UIConfirmationDialogService);

  #popToast = {
    UPDATE_SUCCESS: () =>
      this.toastService.success({
        id: 'update_delivery_success',
        message: 'common.delivery_destinations.update.success',
      }),
    DELETED_CONFIG: () =>
      this.toastService.success({
        id: 'delete_config_success',
        message: 'common.delivery_destinations.deleted_config_success',
      }),
    UPDATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'update_delivery_failure',
        message: 'common.delivery_destinations.update.failure',
        interpolatedParams: { reason },
      }),
  };

  #destroy = new Subject<void>();
  loading = false;
  destinationDetailsListConfig: SimpleDetailsList[] = [];

  ngOnInit(): void {
    this.dataSource.currentItem$.pipe(takeUntil(this.#destroy), filterEmpty()).subscribe((destination) => {
      this.formService.initialize(destination);
      this.setDDListConfig(destination);
    });
  }

  ngOnDestroy(): void {
    this.#destroy.next();
    this.dataSource.currentConfigId$.next(null);
    this.formService.form.controls.configs.clear();
    this.formService.form.reset();
    this.formService.initialized$.next(false);
    this.formService.currentDestination = null;
  }

  setDDListConfig(data: Partial<DestinationModel>) {
    this.destinationDetailsListConfig = [
      {
        title: 'common.delivery_destinations.fields.name',
        value: data.name,
      },
      {
        title: 'common.delivery_destinations.fields.method',
        value: this.formService.form.controls.method.value,
      },
      {
        title: 'common.delivery_destinations.fields.type',
        value: this.formService.form.controls.type.value,
      },
      {
        title: 'common.delivery_destinations.fields.email_delivery',
        value: data.email?.delivery ? data.email?.delivery.join(', ') : null,
      },
      {
        title: 'common.delivery_destinations.fields.delivery_emails',
        value: data.email?.notification ? data.email?.notification.join(', ') : null,
        size: 'wide',
      },
    ];
  }

  markAsTouched() {
    this.formService.form.markAllAsTouched();
  }

  delete() {
    this.confirmation
      .openDelete()
      .pipe(filter(Boolean), take(1))
      .subscribe(() => {
        const currentConfigId = this.formService.currentConfigId$.value;
        if (!currentConfigId) {
          return;
        }

        const links = <DestinationModel>{
          ...this.dataSource.currentItem$.value,
          configs: this.dataSource.currentItem$.value?.configs.filter((link) => link.uuid !== currentConfigId),
        };
        this.dataSource.currentItem$.next(links);
        links.configs?.[0]?.uuid &&
          (() => {
            this.formService.currentConfigId$.next(links.configs[0].uuid);
            this.formService.setConfigId(links.configs[0].uuid);
          })();
        this.save(true);
      });
  }

  save(deleteConfig?: boolean) {
    if (this.formService.form.invalid) {
      return;
    }

    const nextValue = {
      ...this.formService.value,
      configs: this.formService.value.configs?.map((config) => ({
        ...config,
        rules: config.rules.map(transformVideoRuleToSendableObject),
      })),
      publish:
        this.formService.form.controls.method.value === DestinationAccessMethodEnum.VIDA
          ? null
          : (this.formService.form.controls.publish.value as UpdateDestinationModel['publish']),
    };

    if (!nextValue) {
      return;
    }

    this.loading = true;

    this.dataSource.update(nextValue).subscribe({
      error: () => {
        this.loading = false;
        this.#popToast.UPDATE_FAILURE();
      },
      next: (deliveryDestination) => {
        this.loading = false;
        if (deleteConfig) {
          this.#popToast.DELETED_CONFIG();
        } else {
          this.#popToast.UPDATE_SUCCESS();
        }
        this.formService.initialize(deliveryDestination);
      },
    });
  }

  editDetails() {
    const editDialog = this.dialog.open(DestinationEditDialogComponent);
    editDialog.afterClosed().subscribe((result) => {
      if (result?.success) {
        this.formService.form.patchValue(result.value);
        this.formService.form.controls.email.controls.delivery.setValue(
          result.value.emailDelivery !== '' ? result.value.emailDelivery.split(',') : [],
        );
        this.formService.form.controls.email.controls.notification.setValue(
          result.value.deliveryEmails !== '' ? result.value.deliveryEmails.split(',') : [],
        );
        this.setDDListConfig(result.value as DestinationModel);
        this.save();
      }
    });
  }
}
