import { Progress, TransferableFile } from '../../model/transferable-file.interface';
import { Observable } from 'rxjs';
import { AsperaFile } from '../model/aspera-select-event.model';
import * as uuid from 'uuid';
import { AsperaTransferFileSerialized } from '../model/aspera-transfer-event.model';

export class AsperaTransferFile implements TransferableFile {
  private readonly extension?: string;

  destinationFilename: string;

  constructor(
    public id: string,
    public readonly filename: string,
    public readonly fileType: string,
    public sourcePath: string,
    public state$: Observable<Progress>,
  ) {
    this.extension = filename.split('.').pop();
    this.destinationFilename = `${this.id}.${this.extension}`;
  }

  public static fromAsperaSelect(file: AsperaFile, state$: Observable<Progress>, id?: string) {
    id = id ?? uuid.v4();
    const filename = file.name.split(/[\\/]/).pop() ?? '';

    const sourcePath = file.name;
    const fileType = file.type;
    return new AsperaTransferFile(id, filename, fileType, sourcePath, state$);
  }

  public static fromArray(file: AsperaTransferFileSerialized, state$: Observable<Progress>) {
    return new AsperaTransferFile(file.id, file.filename, file.fileType, file.sourcePath, state$);
  }

  toArray = (): AsperaTransferFileSerialized => ({
    id: this.id,
    destinationFilename: this.destinationFilename,
    fileType: this.fileType,
    filename: this.filename,
    sourcePath: this.sourcePath,
  });

  updateId(uuid: string) {
    this.id = uuid;
    this.destinationFilename = `${this.id}.${this.extension}`;
  }
}
