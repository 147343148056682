<h2>{{ 'common.generate_api_token.heading' | translate }}</h2>
<ng-container *ngIf="message$ | async; let message">
  <vdms-hq-ui-info-bar
    [type]="(type$ | async) ?? InfoBarType.WARNING"
    [message]="message"
    [disableClose]="true"
    [clickAction]="InfoBarClickAction.DISABLE"
  ></vdms-hq-ui-info-bar>
</ng-container>
<div class="action">
  <vdms-hq-ui-form-input-date
    format="utc"
    [label]="'common.generate_api_token.expire_date' | translate"
    [withFooter]="false"
    [(ngModel)]="validDate"
  ></vdms-hq-ui-form-input-date>
  <vdms-hq-ui-button color="secondary" (click)="generateToken()">{{
    'common.generate_api_token.generate_button' | translate
  }}</vdms-hq-ui-button>
</div>
