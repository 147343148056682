<vdms-hq-ui-layout-page-wrapper>
  <div class="aspera-wrapper">
    <span class="upload-header">{{ 'pages.upload.upload_asset_aspera' | translate }}</span>
    <div class="spacer"></div>
    <vdms-hq-storage-aspera-selector
      id="master-upload-asset"
      size="compact"
      [fullWidth]="true"
      [clearSelectedOnDone]="true"
      (dropBatch)="assetUploadService.startUpload($event)"
      (cancelBatch)="assetUploadService.cancelUpload($event)"
    ></vdms-hq-storage-aspera-selector>
  </div>
</vdms-hq-ui-layout-page-wrapper>
