import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject, debounceTime, EMPTY, Observable, switchMap, take } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserDialogResponse } from '../../models/user-dialog.model';
import { SimpleType, Supplier, SupplierService, UserApiService, UserModel } from '@vdms-hq/api-contract';
import { catchError, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { DialogResponse, emailPatternValidator, SelectOption, filterEmpty, Destroyable } from '@vdms-hq/shared';
import {
  FormSectionComponent,
  UIButtonModule,
  UIConfirmationDialogService,
  UIDialogWrapperModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { v4 as uuidv4 } from 'uuid';
import { EmailExistValidator } from '../../validators/email-exist.validator';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@vdms-hq/auth';
import { ToastService } from '@vdms-hq/toast';
import { GenerateUserApiTokenComponent } from '../generate-user-api-token/generate-user-api-token.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedClientModule, WithPermissions } from '@vdms-hq/activated-client';
import { PolicyTypeByView } from '../../logic/policy-type-by-view';
import { MatTabsModule } from '@angular/material/tabs';
import { ManagePoliciesListComponent } from '../manage-policies/manage-policies-list.component';
import { UserAdminDialogDataSource } from '../../logic/user-admin-dialog-datasource';

type UserFormGroupType = {
  name: FormControl<string | null>;
  email: FormControl<string>;
  office_phone_number: FormControl<string | null>;
  mobile_number: FormControl<string | null>;
  office_address: FormControl<string | null>;
  timezone: FormControl<string | null>;
  date_format: FormControl<string | null>;
  time_format: FormControl<string | null>;
  supplier: FormControl<string | null>;
};

@Component({
  standalone: true,
  selector: 'vdms-hq-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
  imports: [
    CommonModule,
    UIFormModule,
    UIDialogWrapperModule,
    UIButtonModule,
    FormSectionComponent,
    TranslateModule,
    GenerateUserApiTokenComponent,
    ActivatedClientModule,
    MatTabsModule,
    ManagePoliciesListComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUserDialogComponent extends Destroyable(WithPermissions()) implements OnInit, OnDestroy {
  private confirmationDialog = inject(UIConfirmationDialogService);
  private userApiService = inject(UserApiService);
  private supplierService = inject(SupplierService);
  private authService = inject(AuthService);
  private toast = inject(ToastService);
  private clipboard = inject(Clipboard);

  public data = inject(MAT_DIALOG_DATA) as UserModel;
  public dataSource = inject(UserAdminDialogDataSource);
  public dialogRef = inject(MatDialogRef<AddUserDialogComponent, UserDialogResponse>);

  @Input() cancelButton = false;

  userForm = new FormGroup<UserFormGroupType>({
    name: new FormControl<string | null>('', [Validators.required]),
    email: new FormControl<string>('', { nonNullable: true }),
    office_phone_number: new FormControl<string | null>(null),
    mobile_number: new FormControl<string | null>(null),
    office_address: new FormControl<string | null>(null),
    timezone: new FormControl<string | null>(null),
    date_format: new FormControl<string | null>(null),
    time_format: new FormControl<string | null>(null),
    supplier: new FormControl<string | null>(null),
  });
  timezones: SelectOption[] = [{ key: 'UTC+1', label: 'UTC+1' }];
  dateFormats: SelectOption[] = [{ key: 'ISO (2020-02-22)', label: 'ISO (2020-02-22)' }];
  timeFormats: SelectOption[] = [{ key: 'ISO (24H)', label: 'ISO (24H)' }];

  suppliers$: Observable<Supplier[]> = EMPTY;
  suppliersSelectOptions$: Observable<SelectOption[]> = EMPTY;
  userToken$ = new BehaviorSubject<string | null>(null);
  userTokenExpiredAt$ = new BehaviorSubject<string | null>(null);
  private readonly minimalUserData = {
    picture: '',
    landing_page: '',
    theme: '',
  };

  isUpdating$ = new BehaviorSubject<boolean>(false);

  users: UserModel[] = [];
  userPolicies = this.dataSource.userPolicies;
  userGroups = new FormControl<string[]>([], { nonNullable: true });

  ngOnInit(): void {
    this.suppliers$ = this.supplierService.getSuppliers();
    this.userToken$.next(this.data?.static_api_token_chars ?? null);
    this.userTokenExpiredAt$.next(this.data?.static_api_token_expires_at ?? null);
    this.suppliersSelectOptions$ = this.suppliers$.pipe(
      map((suppliers: Supplier[]) => suppliers.map(({ uuid: key, name: label }: SimpleType) => ({ key, label }))),
      map((suppliers) => {
        return [
          {
            key: null,
            label: 'N/A',
          },
          ...suppliers,
        ];
      }),
    );

    const isEdit = !!this.data?.uuid;

    if (isEdit && this.data) {
      this.isUpdating$.next(true);
      this.userApiService
        .getUser(this.data.uuid)
        .pipe(take(1), takeUntil(this.isDestroyed$))
        .subscribe((user: UserModel) => {
          user.policies ? this.dataSource.emitSelectedPolicies(user.policies) : null;
          this.userPolicies.setValue(user?.policies ? [...user.policies].map(({ uuid }) => uuid) : []);
          this.userGroups.setValue(user?.groups ? [...user.groups].map(({ uuid }) => uuid) : []);
          this.isUpdating$.next(false);
        });
    }

    this.setFormData(isEdit);
  }

  ngOnDestroy() {
    this.dataSource.reset();
    this.destroy();
  }

  setFormData(isEdit: boolean) {
    this.userForm.patchValue({
      name: this.data?.name || null,
      email: this.data?.email || '',
      office_phone_number: this.data?.office_phone_number || null,
      mobile_number: this.data?.mobile_number || null,
      office_address: this.data?.office_address || null,
      timezone: 'UTC+1',
      date_format: 'ISO (2020-02-22)',
      time_format: 'ISO (24H)',
      supplier: this.data?.supplier?.uuid || '',
    });

    this.userForm.controls['email'].valueChanges
      .pipe(
        takeUntil(this.isDestroyed$),
        filter((value: string) => value.length > 5),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.userApiService
          .getPaginatedUsersShareMailStrict(value)
          .pipe(
            take(1),
            switchMap((users) =>
              this.authService.email$.pipe(
                filterEmpty(),
                map((email) => ({ email, users })),
              ),
            ),
          )
          .subscribe(({ email, users }) => {
            this.users = users.data;
            const emailValidators = isEdit
              ? [Validators.required, emailPatternValidator()]
              : [Validators.required, emailPatternValidator(), EmailExistValidator(this.users, email)];
            this.userForm.controls['email'].setValidators(emailValidators);
            this.userForm.controls['email'].markAsTouched();
            this.userForm.controls['email'].updateValueAndValidity();
          });
      });
  }

  onSubmit() {
    if (this.userForm.invalid) {
      return;
    }

    const user = {
      ...this.minimalUserData,
      ...this.data,
      ...this.userForm.value,
      default_policy_to_attach: PolicyTypeByView(window.location.href),
    };
    /**
     * @remarks Remove the comment when backend will resolve supplier detaching
     * @todo Think about a generic solution
     */
    user.supplier ||= null;
    if (!user.name || !user.email) {
      return;
    }
    this.isUpdating$.next(true);
    const userData = {
      ...(user as Omit<UserModel, 'policies'>),
      name: user?.name || '',
      email: user?.email || '',
      uuid: user?.uuid || uuidv4(),
      groups: this.userGroups.value,
      policy_uuids: this.userPolicies.value,
    };

    if (user.uuid) {
      this.userApiService.patchUser(user.uuid, userData).subscribe({
        next: () =>
          this.dialogRef.close({ status: DialogResponse.OK, user: userData as unknown as UserModel, edited: true }),
        error: () => {
          this.isUpdating$.next(false);
        },
      });
    } else {
      this.userApiService
        .postUser(userData)
        .pipe(
          catchError((error) => {
            if (error.status === 400) {
              return this.confirmationDialog
                .open({
                  title: 'pages.users.create_existing_title',
                  message: 'pages.users.create_existing_msg',
                })
                .pipe(
                  switchMap((confirmed) => {
                    if (confirmed) {
                      const user = {
                        ...userData,
                        confirm: true,
                      };
                      return this.userApiService.postUser(user).pipe(
                        switchMap((user) => {
                          this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel });
                          return EMPTY;
                        }),
                      );
                    }
                    this.isUpdating$.next(false);
                    return EMPTY;
                  }),
                );
            }
            this.toast.error({
              id: error.status,
              message: error.error.error,
            });
            this.isUpdating$.next(false);
            return EMPTY;
          }),
        )
        .subscribe((user) => {
          this.dialogRef.close({ status: DialogResponse.OK, user: user as UserModel });
        });
    }
  }

  abort() {
    this.dialogRef.close({ status: DialogResponse.ABORT });
  }

  generateToken(validDate: string | null) {
    if (!this.data?.uuid) {
      return;
    }

    this.userApiService.generateApiToken(this.data?.uuid, validDate).subscribe(({ token }) => {
      this.toast.success({
        id: 'token_generated',
        message: 'New token has been generated and copied to your clipboard',
      });
      this.clipboard.copy(token);
      this.userToken$.next(token);
    });
  }
}
