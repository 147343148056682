import { Injectable, inject } from '@angular/core';
import { ApiService } from '../api.service';
import { GetFilenameConvention, PostFilenameConvention, PatchFilenameConvention } from './filename-convention.model';
import { ApiResponse } from '../api.model';
import { GetResponseData } from '../../operators/get-response-data.operator';

enum FilenameConventionEndpoints {
  GET_ALL = 'filename-conventions',
  GET_ONE = 'filename-convention/{uuid}',
  CREATE = 'filename-convention',
  EDIT = 'filename-convention/{uuid}',
  DELETE = 'filename-convention/{uuid}',
}

const examples = [
  '{seriesName[titleCase,noSpace]}S{seriesNumber[padZero(2)]}E{episodeNumber[padZero(2)]}{episodeName[toHyphen]}{codec}{resolution[noSpace]}',
  '{genre[camelCase]}_{releaseYear[left(4)]}_{contentClass[toUpperCase]}_{seriesNumber[padZero(2)]}',
  '{language[toUpperCase]}_{audioLayout[noSpace]}_{codec[noSpace]}{resolution[right(3)]}',
  '{productionCompany[regexRemove(/[^a-zA-Z0-9]/)]}_S{seriesNumber[padZero(3)]}E{episodeNumber[padZero(2)]}_{releaseYear[right(2)]}',
  '{theme[toLowerCase]}_{category[toUnderscore]}_{audioLanguage[titleCase]}_{contentType[noSpace]}',
  '{orderPackageTitle[titleCase,noSpace]}_{orderNo[padZero(6)]}_{facilityOfOrigin[toUpperCase]}',
  '{seasonTitle[titleCase,removeDots]}S{seriesNumber[padZero(3)]}_E{episodeNumber[padZero(2)]}_{resolution[noSpace]}',
  '{language[titleCase]}_{programmeNumber[regexMatch(/\\d+/)]}_{elements[toHyphen]}_{segments[toHyphen]}',
  '{seriesName[camelCase]}{seriesNumber[padZero(2)]}Ep{episodeNumber[padZero(2)]}{variation[noSpace]}',
  '{audioLanguage[titleCase]}_{audioLayout[toHyphen]}_{contentClass[noSpace]}_{genre[toHyphen]}_{releaseYear[left(4)]}',
  '{productionCompany[regexRemove(/[^a-zA-Z0-9]/)]}_S{seriesNumber[padZero(3)]}Ep{episodeNumber[padZero(2)]}',
  '{elements[toHyphen]}_{segments[right(3)]}_{audioLanguage[titleCase]}_{resolution[noSpace]}',
  '{seriesName[toLowerCase,noSpace]}_{productionCompany[noSpace]}_{theme[kebabCase]}_{releaseYear[left(4)]}',
  '{facilityOfOrigin[camelCase]}_{category[titleCase,noSpace]}_{contentType[titleCase,noSpace]}_{codec[toLowerCase]}_{resolution[noSpace]}',
  '{productionCompany[titleCase,removeDots]}_{facilityOfOrigin[regexMatch(/[A-Z]/)]}_{releaseYear[padZero(4)]}',
  '{genre[toLowerCase]}_{theme[toHyphen]}_{contentClass[noSpace]}_{orderNo[padZero(6)]}',
  '{seriesName[titleCase]}_{audioLayout[camelCase]}_{episodeNumber[padZero(3)]}_{audioLanguage[regexMatch(/[A-Za-z]{2}/)]}',
  '{language[toUpperCase]}_{programmeNumber[regexRemove(/[^\\d]/)]}_{productionCompany[noSpace]}_{category[kebabCase]}',
  '{contentType[toUpperCase]}_{genre[titleCase,noSpace]}_{releaseYear[right(2)]}_{resolution[right(3)]}',
  '{seasonTitle[titleCase,removeDots]}S{seriesNumber[padZero(2)]}_Ep{episodeNumber[padZero(2)]}',
  '{orderPackageTitle[toUpperCase]}_{orderNo[padZero(4)]}_{facilityOfOrigin[camelCase]}_{audioLanguage[toLowerCase]}',
  '{programmeNumber[padZero(5)]}_{audioLayout[camelCase]}_{codec[noSpace]}_{resolution[noSpace]}',
  '{seriesName[camelCase]}_{releaseYear[right(4)]}_{category[snakeCase]}_{elements[toHyphen]}',
  '{audioLayout[toLowerCase]}_{orderNo[right(3)]}_{productionCompany[titleCase,removeDots]}_{segments[toHyphen]}',
  '{theme[titleCase,noSpace]}_{contentType[toLowerCase]}_{releaseYear[right(4)]}_{codec[toLowerCase]}_{resolution[noSpace]}',
];

@Injectable({ providedIn: 'root' })
export class FilenameConventionsService {
  private apiService = inject(ApiService);

  /**
   * @description with pagination
   */
  getAll = () =>
    this.apiService
      .get<ApiResponse<GetFilenameConvention[]>>(`${FilenameConventionEndpoints.GET_ALL}`)
      .pipe(GetResponseData);

  getOne = (uuid: string) =>
    this.apiService
      .get<ApiResponse<GetFilenameConvention>>(`${FilenameConventionEndpoints.GET_ONE.replace('{uuid}', uuid)}`)
      .pipe(GetResponseData);

  create = (data: PostFilenameConvention) =>
    this.apiService.post<PostFilenameConvention, GetFilenameConvention>(`${FilenameConventionEndpoints.CREATE}`, data);

  edit = (uuid: string, data: PatchFilenameConvention) =>
    this.apiService.patch<PatchFilenameConvention, GetFilenameConvention>(
      `${FilenameConventionEndpoints.EDIT.replace('{uuid}', uuid)}`,
      data,
    );

  /*
   * @response 204
   */
  delete = (uuid: string) => this.apiService.delete(`${FilenameConventionEndpoints.DELETE.replace('{uuid}', uuid)}`);
}
