<vdms-hq-ui-dialog-wrapper [size]="'narrow'">
  <div title>
    <span class="title">{{
      uuid ? ('common.credentials.edit' | translate) : ('common.credentials.create' | translate)
    }}</span>
  </div>

  <div content [formGroup]="credentialForm">
    <vdms-hq-ui-loader *ngIf="loading$ | async; else page" [backdrop]="true"></vdms-hq-ui-loader>

    <ng-template #page>
      <vdms-hq-ui-form-input-select
        [selectOptions]="credentialTypes"
        [label]="'common.credentials.form.type' | translate"
        formControlName="type"
      ></vdms-hq-ui-form-input-select>

      <ng-container *ngIf="credentialForm.get('type').value; let type">
        <vdms-hq-ui-form-input-text
          *ngIf="type !== 'YOUTUBE'"
          formControlName="name"
          [label]="'common.credentials.form.name' | translate"
        ></vdms-hq-ui-form-input-text>

        <ng-container *ngIf="hasCredentialData; else infoBar">
          <vdms-hq-ui-info-bar
            [type]="InfoBarType.INFO"
            [message]="credentialInfoBarMsg"
            [disableClose]="true"
            [clickAction]="InfoBarClickAction.EMIT"
            [emitMsgTooltip]="'common.credentials.form.info_bar' | translate"
            (action)="hasCredentialData = false"
          ></vdms-hq-ui-info-bar>
        </ng-container>

        <ng-container *ngIf="type === 'YOUTUBE'">
          <vdms-hq-ui-info-bar
            [type]="InfoBarType.INFO"
            message="Please share following url with the user to get the credentials"
            [disableClose]="true"
          ></vdms-hq-ui-info-bar>

          <div class="url">
            {{ urlToYoutubeAuth$ | async }}
          </div>
        </ng-container>

        <ng-template #infoBar>
          <ng-container formGroupName="visible_data" *ngIf="visibleData">
            <ng-container *ngFor="let field of visibleData">
              <vdms-hq-ui-form-input-text
                *ngIf="field !== 'region'"
                [formControlName]="field"
                [label]="field | fieldLabel | translate"
              ></vdms-hq-ui-form-input-text>
              <vdms-hq-ui-form-input-select
                *ngIf="field === 'region'"
                [selectOptions]="WasabiRegionsOptions"
                [label]="field | fieldLabel | translate"
                [formControlName]="field"
              ></vdms-hq-ui-form-input-select>
            </ng-container>
          </ng-container>

          <ng-container formGroupName="secured_data" *ngIf="securedData">
            <ng-container *ngFor="let field of securedData">
              <vdms-hq-ui-form-input-text
                *ngIf="field !== 'sshPrivateKey'"
                [formControlName]="field"
                [label]="field | fieldLabel : 'secured_data' | translate"
                [enableShowPasswordIcon]="true"
                [matTooltip]="setTooltip | translate"
                [matTooltipDisabled]="!uuid"
              ></vdms-hq-ui-form-input-text>
              <vdms-hq-ui-form-input-text-area
                *ngIf="field === 'sshPrivateKey'"
                [formControlName]="field"
                [label]="field | fieldLabel : 'secured_data' | translate"
                [matTooltip]="setTooltip | translate"
                [matTooltipDisabled]="!uuid"
              >
              </vdms-hq-ui-form-input-text-area>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>

  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="close()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button
      color="primary"
      [loading]="loading$ | async"
      (click)="save()"
      [disabled]="credentialForm.invalid"
    >
      {{ 'common.global.save' | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
