import { StorefrontSettings } from './client-storefront.model';
import { LaunchpadSettings } from './client-launchpad.model';
import { ContentCornerSettings } from './client-content-corner.model';
import { ClientVidaModel } from './client-vida.model';
import { AssetViewConfig, AssetViewConfigKey } from './asset-view.model';
import { FieldConfigId, FieldDefinitionModel } from '@vdms-hq/shared';
import { Permission } from '../policy';

export type SubAppName = 'storefront' | 'contentCorner';

export type ClientRef = string;

export interface FirebaseClientModel {
  uuid: ClientRef;
  name: string;
  libraryUpload?: 'multipart' | 'aspera';
  vida?: ClientVidaModel;
  launchpad?: LaunchpadSettings;
  storefront?: StorefrontSettings;
  contentCorner?: ContentCornerSettings;
  hostnames?: Hostname[];
  assets?: {
    logo?: {
      path: string;
    };
  };
  integrations?: Integrations;

  fields?: ClientFieldConfigModel[];
  columnsConfig?: ColumnsConfig;
  columns?: Record<string, ColumnsConfig>;
  assetView: Record<string, AssetViewConfig>;
  search: {
    filters: FieldConfigId[];
  };
  filters?: Record<string, FieldConfigId[]>;
  libraryConfigs?: ClientVidaLibrarySchema[];
  version?: number;
  timestamp?: Timestamp;

  /** @deprecated use client.libraryConfigs instead */
  library?: {
    structure: FieldConfigId[];
  };
  /** @deprecated use user.permissions instead */
  permissions: string[];
  /** @deprecated use vida.displayUploadDate instead */
  display_upload_date: DisplayUploadDate;
}

export interface ClientModel {
  uuid: ClientRef;
  name: string;
  user?: { email?: string; id?: string };
  libraryUpload: 'multipart' | 'aspera';
  launchpad?: LaunchpadSettings;
  storefront?: StorefrontSettings;
  contentCorner?: ContentCornerSettings;
  hostnames: Hostname[];
  integrations: Integrations;
  vida?: Partial<ClientVidaModel>;

  fields: ClientFieldConfigModel[];
  columnsConfig: ColumnsConfig;
  columns?: Record<string, ColumnsConfig>;
  assetView: Record<AssetViewConfigKey, AssetViewConfig>;
  filters?: Record<string, FieldConfigId[]>;
  search: {
    filters: FieldConfigId[];
  };
  /**
   * @deprecated use libraryConfigs instead
   */
  library: {
    structure: FieldConfigId[];
  };
  libraryConfigs?: ClientVidaLibrarySchema[];
  statsConfig?: StatsConfig;
  version?: number;
  timestamp?: Timestamp;
}

type Hostname = string;

export interface ClientVidaLibrarySchema {
  id: string;
  name: string;
  structure: FieldConfigId[];
}

export interface ColumnsConfig {
  available: FieldConfigId[];
  default: FieldConfigId[];
}

export interface StatsConfig {
  enableDailyCost?: boolean;
}

export interface ListColumn {
  name: string;
  order: number;
  default: boolean;
}

export type Integrations = {
  salesforce?: {
    enabled: boolean;
  };
};

interface Timestamp {
  seconds: number;
  nanoseconds: number;
  date: Date;
}

export interface ClientFieldConfigModel {
  id: FieldConfigId;
  override: Partial<FieldDefinitionModel>;
}

export enum PermissionsComparators {
  SOME = 'some',
  EVERY = 'every',
}

export interface PermissionFieldsModel {
  permissions: Permission[];
  comparator: PermissionsComparators;
}

/** @deprecated legacy setting */
export interface ColumnsSettings {
  asset: ListColumn[];
}
/** @deprecated legacy setting */
export type DisplayUploadDate = boolean;
