import { inject, Injectable } from '@angular/core';
import { UserContractService } from '@vdms-hq/firebase-contract';
import { FieldsScopeKey, filterEmpty } from '@vdms-hq/shared';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '@vdms-hq/auth';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Observable, of } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';

@Injectable({
  providedIn: 'root',
})
export class FieldsSaverService {
  #toastService = inject(ToastService);
  #authService = inject(AuthService);
  #activatedClient = inject(ActivatedClientService);
  #userContractService = inject(UserContractService);

  saveUserColumnsConfig(scope: FieldsScopeKey, columnsConfig: string[]): Observable<boolean> {
    return this.#authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.#activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.columns?.[scope] ?? client.columns?.['default'];
        const userLevel = columnsConfig;
        const sameConfig =
          clientLevel?.default.length === userLevel.length &&
          clientLevel?.default.every((value, index) => value === userLevel[index]);

        return this.#userContractService.updateClient(user.email, client.uuid, {
          columnsConfig: {
            [scope]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
      map(() => true),
      catchError(() => {
        this.#toastService.error({
          id: 'columns-config-update',
          message: 'Error while saving user columns config',
        });
        return of(false);
      }),
      tap(() => {
        this.#toastService.success({
          id: 'columns-config-update',
          message: 'Columns configuration updated successfully',
        });
      }),
    );
  }

  saveUserFilterConfig(scope: FieldsScopeKey, columnsConfig: string[]) {
    return this.#authService.auth$.pipe(
      filterEmpty(),
      take(1),
      withLatestFrom(this.#activatedClient.clientDefinite$),
      switchMap(([user, client]) => {
        const clientLevel = client.columnsConfig.default;
        const userLevel = columnsConfig;
        const sameConfig =
          clientLevel.length === userLevel.length && clientLevel.every((value, index) => value === userLevel[index]);

        return this.#userContractService.updateClient(user.email, client.uuid, {
          filtersConfig: {
            [scope]: sameConfig ? [] : (userLevel as string[]),
          },
        });
      }),
      map(() => true),
      catchError(() => {
        this.#toastService.error({
          id: 'columns-config-update',
          message: 'Error while saving user filters config',
        });
        return of(false);
      }),
      tap(() => {
        this.#toastService.success({
          id: 'columns-config-update',
          message: 'Filters configuration updated successfully',
        });
      }),
    );
  }
}
