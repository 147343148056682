import { Component, Input } from '@angular/core';
import { UILoaderModule } from '../../../ui-loader/ui-loader.module';
import { E2eIdDirective, isLoadableDataSource, LoadableDataSource } from '@vdms-hq/shared';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-ui-loader-ds',
  imports: [UILoaderModule, CommonModule, E2eIdDirective],
  template: `
    <ng-container *ngIf="isLoadableDataSource(dataSource); let loadableDs">
      <vdms-hq-ui-loader
        [mode]="'over-parent'"
        [backdrop]="true"
        [sharedE2eId]="'loader'"
        *ngIf="loadableDs.isLoading$ | async"
      ></vdms-hq-ui-loader>
    </ng-container>
  `,
  standalone: true,
})
export class LoaderDsComponent {
  @Input() dataSource?: Partial<LoadableDataSource>;
  protected readonly isLoadableDataSource = isLoadableDataSource;
}
