import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share, switchMap } from 'rxjs/operators';
import { CookieApi } from '../rest/cookie/cookie-api';
import { API_CONFIG, ApiConfig } from '../config-token';
import { CookieService } from 'ngx-cookie-service';
import moment from 'moment';
import { CloudFrontCookie } from '../rest/cookie/cookie.model';
import { isApplicableUrl } from './helper/applicable-url';

@Injectable()
export class CloudfrontInterceptor implements HttpInterceptor {
  private readonly cookieNonRequiredPrefixes = ['/user/login', '/user/custom-token', 'cookie', '/credential/youtube'];
  #retrieveCookie = this.cookieApi.get().pipe(
    map((data) => ({
      ...data,
      'CloudFront-Expiration': moment().add(18, 'hours').toISOString(),
    })),
    map((cookie) => this.saveToCookie(cookie)),
    share(),
  );

  constructor(
    @Inject(API_CONFIG) private env: ApiConfig,
    private cookieApi: CookieApi,
    private cookieService: CookieService,
  ) {
    const config = this.env.interceptors.cloudfrontCookie;

    if (typeof config !== 'boolean') {
      this.cookieNonRequiredPrefixes.push(...(config.notRequiredForPrefixes ?? []));
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!isApplicableUrl(this.env.apiUrl, request.url, this.cookieNonRequiredPrefixes)) {
      return next.handle(request.clone());
    }

    if (this.isCookieValid()) {
      return next.handle(request.clone());
    }

    return this.#retrieveCookie.pipe(switchMap(() => next.handle(request.clone())));
  }

  isCookieValid(): boolean {
    const lastCookieExpiresIn = this.cookieService.get('CloudFront-Expiration');

    if (!lastCookieExpiresIn) {
      return false;
    }

    return moment(lastCookieExpiresIn).isAfter(moment());
  }

  private saveToCookie(data: CloudFrontCookie): void {
    for (const cookieName of Object.keys(data)) {
      this.cookieService.set(cookieName, data[cookieName as keyof CloudFrontCookie], 28800, '/', this.env.cookieDomain);
    }
  }
}
