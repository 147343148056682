import { APP_INITIALIZER, Provider } from '@angular/core';
import { LastFiltersService } from './logic/services/last-filters.service';
import { ReturnButtonService } from './logic/services/return-button.service';
import { BrowseRouterService } from './logic/services/browse-router.service';

export const configureSearch = () => {
  const providers: Provider[] = [
    {
      provide: APP_INITIALIZER,
      useFactory: (routeParamListener: BrowseRouterService) => () => routeParamListener.registerListeners(),
      multi: true,
      deps: [BrowseRouterService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (lastFiltersService: LastFiltersService) => () => lastFiltersService.registerListeners(),
      multi: true,
      deps: [LastFiltersService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (returnButtonService: ReturnButtonService) => () => returnButtonService.registerListeners(),
      multi: true,
      deps: [ReturnButtonService],
    },
  ];

  return providers;
};
