import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  ActionIdentifier,
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UILoaderModule,
  UIResultsWrapperModule,
} from '@vdms-hq/ui';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardModel } from '@vdms-hq/api-contract';
import { DashboardCreateEditDialogComponent } from '../dashboard-create-dialog/dashboard-create-edit-dialog.component';
import { DashboardsService } from '../../logic/dashboards.service';
import { DashboardsDs } from '../../logic/dashboards.ds';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { Permission } from '@vdms-hq/firebase-contract';
import { PermissionService } from '@vdms-hq/activated-client';

@Component({
  selector: 'vdms-hq-dashboards',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    UILoaderModule,
    UIResultsWrapperModule,
    DashboardCreateEditDialogComponent,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
    DynamicFiltersModule,
  ],
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent implements OnInit, OnDestroy {
  #destroy$ = new Subject<void>();

  enabled = ['uuid', 'is_default', 'name', 'created_at', 'updated_at', 'actions'];
  viewConfiguration$!: Observable<MultipleViewConfiguration<DashboardModel>>;
  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.dashboards.table.filter',
      resource: [ResourceModel.RATING],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-dashboards'],
    },
  ];

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });
  headerActions$: Observable<ActionContextLess[]> = this.permissionService
    .verifyWithOwnedPermissions$([Permission.CREATE_DASHBOARDS])
    .pipe(
      map((hasPermission) =>
        hasPermission
          ? [
              {
                key: 'add',
                label: 'common.global.add',
                color: 'primary',
              },
            ]
          : [],
      ),
    );

  constructor(
    public dashboardsService: DashboardsService,
    public dataSource: DashboardsDs,
    private permissionService: PermissionService,
  ) {
    this.dataSource.optionsChanged(null);
  }

  async handleAction($event: { key: ActionIdentifier; item?: DashboardModel }) {
    switch ($event.key) {
      case 'add':
        this.dashboardsService.openCreateDialog();
        return;
    }

    if (!$event.item) return;
    switch ($event.key) {
      case 'edit':
        this.dashboardsService.openEditDialog($event.item);
        return;
    }
  }

  ngOnInit() {
    this.dataSource.applyFilter('');
    this.filters.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe((value) => {
      this.dataSource.applyFilter(value.keyword ?? '', ['name']);
    });

    this.viewConfiguration$ = this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_DASHBOARDS]).pipe(
      map((canEdit) => {
        return {
          tableAdvanced: {
            actions: canEdit
              ? [
                  {
                    key: 'edit',
                    label: 'common.global.edit',
                    icon: 'edit',
                    onDoubleClick: false,
                    hiddenIf: () => !canEdit,
                  },
                ]
              : [],
            columnsEnabled: this.enabled,
            columns: [
              {
                id: 'uuid',
                label: 'Uuid',
                valuePath: 'uuid',
              },
              {
                id: 'is_default',
                label: 'Is Default?',
                valuePath: 'is_default',
                sortable: true,
                viewFormat: {
                  modifiers: {
                    yesNo: true,
                  },
                  pills: {
                    true: 'done',
                  },
                },
              },
              {
                id: 'name',
                label: 'Dashboard name',
                valuePath: 'name',
                sortable: true,
              },
              {
                id: 'created_at',
                label: 'Created At',
                valuePath: 'created_at',
                sortable: true,
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
              {
                id: 'updated_at',
                label: 'Last updated',
                valuePath: 'updated_at',
                sortable: true,
                viewFormat: {
                  modifiers: {
                    dateFormat: 'date-time',
                  },
                },
              },
              {
                id: 'actions',
                type: 'actions',
              },
            ],
          },
        };
      }),
    );
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
