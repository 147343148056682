import { Component, inject, OnInit } from '@angular/core';
import { map } from 'rxjs';
import {
  BreadCrumb,
  ConfigDragDropComponent,
  InfoBarType,
  UIButtonModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { AdminFieldsConfigService } from '../../logic/admin-fields-config.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AdminFieldsFormService } from '../../logic/admin-fields-form.service';
import { take } from 'rxjs/operators';

interface AssetConfigTab {
  key: 'table' | 'list' | 'filters';
  viewName: string;
  label: string;
}

@Component({
  selector: 'vdms-hq-page-results-settings',
  templateUrl: './page-results-settings.component.html',
  styleUrls: ['./page-results-settings.component.scss'],
  imports: [
    UILayoutModule,
    CommonModule,
    MatTabsModule,
    ConfigDragDropComponent,
    ReactiveFormsModule,
    UIButtonModule,
    TranslateModule,
    UILoaderModule,
  ],
  standalone: true,
})
export class PageResultsSettingsComponent implements OnInit {
  readonly InfoBarType = InfoBarType;
  currentIndex = 0;
  #adminFieldsConfigService = inject(AdminFieldsConfigService);
  #adminFieldsFormService = inject(AdminFieldsFormService);

  configKey$ = this.#adminFieldsConfigService.currentScopeKey$;
  form = this.#adminFieldsFormService.form;

  allFields$ = this.#adminFieldsFormService.allFields$;
  formSettings$ = this.#adminFieldsFormService.settings$;

  isLoading$ = this.#adminFieldsFormService.isLoading$;
  isSaving$ = this.#adminFieldsFormService.isSaving$;

  tabs$ = this.configKey$.pipe(
    map((configKey) => {
      return [
        configKey.hasTable && {
          key: 'table',
          label: 'Table',
          viewName: 'table view',
        },
        configKey.hasList && {
          key: 'list',
          label: 'List',
          viewName: 'list view',
        },
        configKey.hasFilters && {
          key: 'filters',
          label: 'Filters',
          viewName: 'filters view',
        },
      ].filter(Boolean) as AssetConfigTab[];
    }),
  );

  breadcrumb$ = this.configKey$.pipe(
    map((config) => {
      return <BreadCrumb[]>[
        {
          name: 'common.admin.sidebar.tables_and_lists.name',
          path: '/admin/tables-and-lists',
        },
        {
          name: config.name,
          path: `/admin/tables-and-lists/${config.key}`,
        },
      ];
    }),
  );

  save() {
    this.#adminFieldsFormService.save();
  }
  reset() {
    this.#adminFieldsFormService.reset();
  }
  revertToDefault() {
    this.#adminFieldsFormService.revertToDefault();
  }

  ngOnInit(): void {
    this.tabs$.subscribe((tabs) => {
      this.#adminFieldsFormService.prepareForm(tabs[0].key);
      this.currentIndex = 0;
    });
  }

  onTabChange(index: number) {
    this.currentIndex = index;
    this.tabs$.pipe(take(1)).subscribe((tabs) => {
      this.#adminFieldsFormService.prepareForm(tabs[index].key);
      this.currentIndex = 0;
    });
  }
}
