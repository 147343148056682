import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { CartApiService, DISCOUNT_STATUS, OrderModelPost, OrderService, ORDER_TYPE } from '@vdms-hq/api-contract';
import { CartStateService, CheckoutStep } from '@vdms-hq/cart-core';
import { Permission } from '@vdms-hq/firebase-contract';
import { QuarantineService } from '@vdms-hq/quarantine';
import { ToastService } from '@vdms-hq/toast';
import { ButtonColors, ButtonColorsEnum, LEAVING_CART_DIALOG_CONFIG, UIConfirmationDialogService } from '@vdms-hq/ui';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  EMPTY,
  firstValueFrom,
  iif,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { catchError, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CartDataSource } from '../data-sources/cart-data-source';
import { CartActions, CartActionsType } from '../models/cart-actions.model';
import { CartCheckoutFormService, DeliveryMethods, ValidationErrorList } from './cart-checkout-form.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CheckoutValidationErrorsDialogComponent,
  ddCartErrorsChecks,
  cartErrorsChecks,
  validationErrorsInput,
  validationErrorsOutput,
  ValidationErrorsOutput,
} from '@vdms-hq/cart';
import { JobTypePayloadInterface } from '@vdms-hq/api-contract';
import { FormGroup } from '@angular/forms';

export interface SubmitData {
  destinations: { uuid: any; configUuid: any; hasBurnInText: any; burnInTextValue: any }[] | null | undefined;
  emails: string[] | null | undefined;
  notificationEmails: string[] | null | undefined;
  packageTitle: string | null | undefined;
  purchaseOrderNo: string | null | undefined;
  subject: string | null | undefined;
  deliveryDate: string | null | undefined;
  expiresAt: string | null | undefined;
  comment: string | null | undefined;
  downloadLimit: number | null | undefined;
  department_field_uuid: string | null | undefined;
}

export interface CartCheckoutAction {
  color: ButtonColors;
  visible: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  size?: 'small' | 'medium' | 'fieldset' | 'full' | undefined;
  label: { text: string; params?: Record<string, string> } | string;
  action: CartActionsType;
  sharedE2eId?: string;
}

export type CartCheckoutActions = CartCheckoutAction[];

@Injectable({ providedIn: 'root' })
export class CartCheckoutService {
  #previousValidatePayload?: JobTypePayloadInterface[];

  private readonly router = inject(Router);
  private readonly activatedClientService = inject(ActivatedClientService);
  private readonly cartDataSource = inject(CartDataSource);
  private readonly apiService = inject(OrderService);
  private readonly cartApiService = inject(CartApiService);
  private readonly toastService = inject(ToastService);
  private readonly permissionService = inject(PermissionService);
  private readonly confirmation = inject(UIConfirmationDialogService);
  private readonly dialog = inject(MatDialog);
  private readonly cartState = inject(CartStateService);
  private readonly cartFormService = inject(CartCheckoutFormService);
  private readonly toast = inject(ToastService);
  private readonly quarantineService = inject(QuarantineService);

  #popToast = {
    SUBMIT_SUCCESS: () =>
      this.toastService.success({
        id: 'cart_submit',
        message: 'pages.cart.notifications.submit.done',
      }),
    SUBMIT_FAILURE: () =>
      this.toastService.error({
        id: 'cart_submit',
        message: 'pages.cart.notifications.submit.failed',
      }),
  };

  submitted = false;
  submittedOrderUuid = '';
  checkoutInProgress$ = new BehaviorSubject(false);

  navigateToCart$ = defer(() => this.router.navigate(['cart-v3']));
  navigateToCheckout$ = defer(() => this.router.navigate(['cart-v3', 'checkout']));
  navigateToCustomizedCheckout$ = defer(() => this.router.navigate(['customized-checkout']));

  actionsConfig$: Observable<CartCheckoutActions> = combineLatest([
    this.cartDataSource.total$,
    this.cartState.checkoutStep$,
    this.cartState.isUpdating$,
    this.cartState.isSubmitting$,
    this.cartState.isValidated$,
    this.cartFormService.orderTitleValid$,
    this.cartFormService.purchaseOrderValid$,
    this.cartFormService.departmentValid$,
    this.cartFormService.jobsFormValid$,
    this.cartFormService.jobsValidated$,
    this.cartFormService.overwriteApproved$,
    this.cartFormService.missingRates$,
    this.cartFormService.requiresValidation$,
    this.cartFormService.hasValidationErrors$,
    this.cartFormService.isFormValid$,
    this.cartFormService.isLoading$,
  ]).pipe(
    map(
      ([
        total,
        step,
        isUpdating,
        isSubmitting,
        isValidated,
        orderValid,
        purchaseValid,
        departmentValid,
        jobsFormValid,
        jobsValidated,
        overwriteApproved,
        missingRates,
        requiresValidation,
        hasValidationErrors,
        isFormValid,
        isLoading,
      ]) => {
        const detailsValid = departmentValid && orderValid && purchaseValid;
        const actions: CartCheckoutActions = [
          {
            color: ButtonColorsEnum.SECONDARY,
            visible: total > 0 && step === CheckoutStep.CART,
            disabled: isUpdating,
            isLoading: false,
            label: 'pages.cart.checkout.remove_all',
            action: CartActions.REMOVE_ALL,
            sharedE2eId: 'remove-all',
          },
          {
            color: ButtonColorsEnum.SECONDARY,
            visible: step === CheckoutStep.DELIVERY_DETAILS,
            action: CartActions.CHECKOUT_PREVIOUS_STEP,
            label: 'pages.cart.checkout.back_to_method',
          },
          {
            color: ButtonColorsEnum.SECONDARY,
            visible: step === CheckoutStep.RECIPIENT_DETAILS,
            action: CartActions.CHECKOUT_PREVIOUS_STEP,
            label: 'pages.cart.checkout.back_to_details',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible: step === CheckoutStep.CART,
            disabled: !total || isUpdating,
            isLoading: isSubmitting,
            action: CartActions.CHECKOUT,
            label: { text: 'pages.cart.checkout.do_checkout', params: { count: total.toString() } },
            sharedE2eId: 'do-checkout',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible:
              step === CheckoutStep.DELIVERY_METHOD && !['Warm up', null].includes(this.cartFormService.deliveryMethod),
            disabled: this.cartFormService.deliveryMethod === null || !isValidated || hasValidationErrors,
            isLoading: isSubmitting,
            action: CartActions.CHECKOUT_NEXT_STEP,
            label: 'pages.cart.checkout.delivery_details',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible: step === CheckoutStep.DELIVERY_DETAILS && this.cartFormService.deliveryMethod === 'Warm up',
            disabled: this.cartFormService.deliveryMethod !== 'Warm up' || hasValidationErrors || !detailsValid,
            isLoading: isSubmitting,
            action: CartActions.CHECKOUT_NEXT_STEP,
            label: 'pages.cart.checkout.place_warm_up_order',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible: step === CheckoutStep.DELIVERY_DETAILS && this.cartFormService.deliveryMethod === 'Workflow',
            disabled: this.cartFormService.deliveryMethod !== 'Workflow' || !detailsValid,
            isLoading: isSubmitting,
            action: CartActions.CHECKOUT_NEXT_STEP,
            label: 'pages.cart.checkout.choose_ai_actions',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible:
              step === CheckoutStep.DELIVERY_DETAILS &&
              this.cartFormService.deliveryMethod !== 'Workflow' &&
              this.cartFormService.deliveryMethod !== 'Warm up',
            isLoading: isSubmitting || isLoading,
            action: CartActions.CHECKOUT_NEXT_STEP,
            disabled:
              !detailsValid ||
              (requiresValidation && !this.cartFormService.areDestinationsValid) ||
              hasValidationErrors,
            label: 'pages.cart.checkout.order_details', //go to recipient details
            sharedE2eId: 'order-details',
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible: step === CheckoutStep.RECIPIENT_DETAILS && this.cartFormService.deliveryMethod === 'Workflow',
            isLoading: isSubmitting || isLoading,
            action: CartActions.CHECKOUT_NEXT_STEP,
            disabled:
              !jobsFormValid || !jobsValidated || hasValidationErrors || overwriteApproved === false || missingRates,
            label: 'pages.cart.checkout.run_ai_actions', //go to AI summary
          },
          {
            color: ButtonColorsEnum.PRIMARY,
            visible: step === CheckoutStep.RECIPIENT_DETAILS && this.cartFormService.deliveryMethod !== 'Workflow',
            isLoading: isSubmitting,
            action: CartActions.CHECKOUT_NEXT_STEP,
            disabled: !isFormValid,
            label: 'pages.cart.checkout.place_order',
            sharedE2eId: 'place-order',
          },
        ];
        return actions;
      },
    ),
  );

  get orderType(): ORDER_TYPE {
    return this.cartFormService.orderType;
  }

  async nextStep() {
    this.checkoutInProgress$.next(true);
    await firstValueFrom(
      this.cartFormService.getForm().pipe(
        map((form) => {
          const currentStep = this.cartState.checkoutStep$.value;
          const deliveryMethod = form.controls.deliveryMethod.valid;
          const deliveryDate = form.controls.deliveryDate.valid;
          const expiryDate = form.controls.expiryDate.valid;
          const isWarmUp = form.controls.deliveryMethod.value === DeliveryMethods.WARM_UP;
          const isAssetProcessing = form.controls.deliveryMethod.value === DeliveryMethods.WORKFLOW;

          // todo see if can be simplified or completely avoided
          const prerequisitesForStep1 = this.cartDataSource.total$.value > 0;
          const prerequisitesForStep2 = prerequisitesForStep1 && deliveryMethod;
          const prerequisitesForStep3 = prerequisitesForStep2 && deliveryDate && expiryDate;
          const prerequisitesForStep4 = prerequisitesForStep3 && this.cartFormService.isFormValid;
          const prerequisitesForWorkflow =
            this.cartFormService.jobsValidated$.value && this.cartFormService.overwriteApproved$.value !== false;

          switch (currentStep) {
            case CheckoutStep.CART: {
              if (prerequisitesForStep1) {
                this.cartState.nextStep();
              }
              break;
            }
            case CheckoutStep.DELIVERY_METHOD: {
              if (prerequisitesForStep2) {
                this.cartState.nextStep();
              }
              break;
            }
            case CheckoutStep.DELIVERY_DETAILS: {
              if (isWarmUp) {
                this.cartFormService.isFormValid$.next(true);
                this.checkoutInProgress$.next(false);
                this.cartState.checkoutStep$.next(4);
                this.router.navigate(['cart-v3', 'order-summary']);
                break;
              }
              if (prerequisitesForStep3) {
                this.cartState.nextStep();
              }
              break;
            }
            case CheckoutStep.RECIPIENT_DETAILS: {
              if (isAssetProcessing && prerequisitesForWorkflow) {
                this.cartFormService.isFormValid$.next(true);
                this.cartState.checkoutStep$.next(4);
                this.checkoutInProgress$.next(false);
                this.router.navigate(['cart-v3', 'order-summary']);
              }
              if (prerequisitesForStep4) {
                this.cartState.checkoutStep$.next(4);
                this.checkoutInProgress$.next(false);
                this.router.navigate(['cart-v3', 'order-summary']);
              }
              break;
            }
            case CheckoutStep.DELIVERY_SUMMARY: {
              this.submitCart();
              break;
            }
          }
          return EMPTY;
        }),
      ),
    );
  }

  previousStep() {
    const currentStep = this.cartState.checkoutStep$.value;
    if (currentStep === CheckoutStep.DELIVERY_DETAILS) {
      this.cartDataSource.cartSummary$.next(null);
    }
    if (currentStep === CheckoutStep.DELIVERY_DETAILS && this.cartFormService.deliveryMethod === 'Workflow') {
      this.cartFormService.form.controls.jobs = new FormGroup({});
      this.cartFormService.jobsValidated$.next(false);
      this.cartFormService.jobsDetails = {};
      this.#previousValidatePayload = undefined;
    }

    if (currentStep > 0) {
      this.cartState.checkoutStep$.next(currentStep - 1);
    }
  }

  resetCart() {
    this.cartFormService.formInitialized === false;
    this.cartFormService.resetForm();
    this.cartState.checkoutStep$.next(0);
    this.cartFormService.listenersSubscription.unsubscribe();
  }

  doCheckout() {
    this.cartState.isOrderApproved$.next(false);
    this.cartFormService.resetForm();

    this.activatedClientService.integrations$
      .pipe(
        withLatestFrom(this.permissionService.verifyWithOwnedPermissions$([Permission.SHOPPING_CART_V2])),
        take(1),
        switchMap(([integrations, hasAccessToV2]) =>
          iif(
            () => Boolean(integrations.salesforce?.enabled) && hasAccessToV2,
            this.navigateToCustomizedCheckout$,
            this.navigateToCheckout$,
          ),
        ),
      )
      .subscribe();
  }

  submitCart() {
    this.cartState.isSubmitting$.next(true);

    this.cartDataSource.total$
      .pipe(
        take(1),
        withLatestFrom(this.activatedClientService.clientDefinite$, this.cartFormService.getForm()),
        map(([total, client, form]) => {
          this.submitted = true;

          if (!client || total <= 0 || !this.cartFormService.isFormValid$.value) {
            return;
          }

          const destinations = form.controls.destinations.value
            ?.map(({ configUuid, uuid, hasBurnInText, burnInTextValue }) => ({
              configUuid,
              uuid,
              hasBurnInText,
              burnInTextValue,
            }))
            ?.filter(({ uuid, configUuid }) => uuid && configUuid);
          const data: SubmitData = {
            destinations: destinations?.length && form.controls.destinations.enabled ? destinations : undefined,
            emails: form.controls.emails.enabled ? form.controls.emails.value : undefined,
            notificationEmails: form.controls.notificationEmails.enabled
              ? form.controls.notificationEmails.value
              : undefined,
            packageTitle: form.controls['packageTitle'].value,
            purchaseOrderNo: form.controls['purchaseOrder'].value,
            subject: form.controls['subject'].value,
            deliveryDate:
              form.controls.deliveryDelay.value === 'immediate' ? null : form.controls['deliveryDate'].value,
            expiresAt: form.controls['expiryDate'].value,
            comment: form.controls['comment'].value,
            downloadLimit:
              form.controls.downloadLimit.dirty || form.controls.unlimited.dirty
                ? form.controls.downloadLimit.value
                : undefined,
            department_field_uuid: form.controls['department_field_uuid'].value,
          };

          return this.submitStandardCart(data, client?.vida?.sharedPacks ?? false);
        }),
      )
      .subscribe();
  }

  validateEmailDelivery() {
    this.cartFormService.validationErrorList$.next(null);
    this.cartFormService.validationErrorAssetUuids$.next([]);
    this.cartFormService.isLoading$.next(true);
    this.cartFormService.emailDeliveryValidated$.next(false);

    const payload = {
      order_type: ORDER_TYPE.EMAIL_DELIVERY,
      order_data: {},
    };

    combineLatest([
      this.cartApiService.validate(payload),
      this.quarantineService.canUseQuarantinedAssets$,
      this.cartDataSource.getCartSummary(ORDER_TYPE.EMAIL_DELIVERY),
    ])
      .pipe(
        catchError(({ message }) => {
          this.toast.error({ id: 'error', message });
          this.cartFormService.validationErrorList$.next([]);
          this.cartFormService.isLoading$.next(false);
          this.cartFormService.emailDeliveryValidated$.next(false);
          this.cartState.isUpdating$.next(false);
          this.checkoutInProgress$.next(false);
          this.cartState.isValidated$.next(false);
          return throwError(message);
        }),
        tap(() => this.cartFormService.isLoading$.next(false)),
        tap(([validationResult, canUseQuarantined]) => {
          if (!validationResult?.length) {
            this.noValidationErrors();
          }
          const errorChecks = cartErrorsChecks(validationResult, canUseQuarantined);

          const missingDefaultPolicy = errorChecks.validationErrorList.some((error) =>
            error.message.includes('Default Policy'),
          );
          this.cartFormService.missingDefaultPolicy$.next(missingDefaultPolicy);

          this.setValidationErrors(errorChecks, !missingDefaultPolicy);
          this.cartFormService.emailDeliveryValidated$.next(true);
          if ((errorChecks.errorUuids.length === 0 && !missingDefaultPolicy) || !validationResult?.length) {
            this.nextStep();
          }
        }),
      )
      .subscribe();
  }

  validateDeliveryDestinations() {
    if (!this.cartFormService.areDestinationsValid) {
      return EMPTY;
    }
    const delivery_config_uuids: string[] = this.cartFormService.destinations.value
      .map(({ configUuid }) => configUuid)
      .filter(Boolean) as string[];
    if (!delivery_config_uuids?.length) {
      return EMPTY;
    }
    this.cartFormService.validationErrorList$.next(null);
    this.cartFormService.validationErrorAssetUuids$.next([]);
    this.cartFormService.isLoading$.next(true);

    const payload = {
      order_type: ORDER_TYPE.DELIVERY_DESTINATION,
      order_data: { delivery_config_uuids },
    };

    return combineLatest([
      this.cartApiService.validate(payload),
      this.cartDataSource.getCartSummary(ORDER_TYPE.DELIVERY_DESTINATION),
    ]).pipe(
      catchError(({ message }) => {
        this.toast.error({ id: 'error', message });
        this.cartFormService.validationErrorList$.next([]);
        this.cartFormService.isLoading$.next(false);
        return throwError(message);
      }),
      withLatestFrom(this.cartFormService.allDeliveriesConfig$),
      tap(() => this.cartFormService.isLoading$.next(false)),
      tap(([[validationResult], configs]) => {
        this.cartDataSource.getCartSummary();
        if (!validationResult?.length) {
          this.cartFormService.destinations.markAsPristine();
          this.noValidationErrors();
        }

        const errorChecks = ddCartErrorsChecks(validationResult, configs);

        this.setValidationErrors(errorChecks);
        this.cartFormService.destinationsValidated$.next(true);
      }),
    );
  }

  validateWarmUp() {
    this.cartState.isSubmitting$.next(true);
    this.cartFormService.isLoading$.next(true);

    const payload = {
      order_type: ORDER_TYPE.WARM_UP,
      order_data: {},
    };

    combineLatest([this.cartApiService.validate(payload), this.cartDataSource.getCartSummary(ORDER_TYPE.WARM_UP)])
      .pipe(
        catchError(({ message }) => {
          this.toast.error({ id: 'error', message });
          this.cartFormService.validationErrorList$.next([]);
          this.cartFormService.isLoading$.next(false);
          return throwError(message);
        }),
        tap(([validationResult]) => {
          this.cartDataSource.getCartSummary();
          if (!validationResult?.length) {
            this.noValidationErrors();
          }

          const errorChecks = cartErrorsChecks(validationResult);
          this.setValidationErrors(errorChecks);
          this.cartFormService.warmUpValidated$.next(true);
          if (errorChecks.errorUuids.length === 0 || !validationResult?.length) {
            this.nextStep();
          }
        }),
      )
      .subscribe();
  }

  validateJobs() {
    if (!this.cartFormService.formInitialized) {
      this.cartFormService.isLoading$.next(false);
      return;
    }

    const analysisPayload: JobTypePayloadInterface[] = this.cartFormService.workflowJobs;

    if (
      !!this.#previousValidatePayload &&
      JSON.stringify(this.#previousValidatePayload) === JSON.stringify(analysisPayload)
    ) {
      this.cartFormService.isLoading$.next(false);
      return;
    }

    this.#previousValidatePayload = analysisPayload;

    if (analysisPayload.length === 0) {
      this.cartFormService.isLoading$.next(false);
      return;
    }

    this.cartFormService.validationErrorList$.next(null);
    this.cartFormService.validationErrorAssetUuids$.next([]);
    this.cartFormService.missingRates$.next(false);
    this.cartFormService.isLoading$.next(true);

    const payload = {
      order_type: ORDER_TYPE.WORKFLOW,
      order_data: {
        jobs: analysisPayload,
      },
    };

    combineLatest([this.cartApiService.validate(payload), this.cartDataSource.getCartSummary(ORDER_TYPE.WORKFLOW)])
      .pipe(
        catchError(({ message }) => {
          this.toast.error({ id: 'error', message });
          this.cartFormService.validationErrorList$.next([]);
          this.cartFormService.isLoading$.next(false);
          this.cartFormService.jobsValidated$.next(false);
          this.cartState.isUpdating$.next(false);
          this.checkoutInProgress$.next(false);
          this.cartState.isValidated$.next(false);
          return throwError(message);
        }),
        tap(() => this.cartFormService.isLoading$.next(false)),
        tap(([validationResult]) => {
          if (!validationResult?.length) {
            this.noValidationErrors();
          }

          const errorChecks = cartErrorsChecks(validationResult);
          const missingRates = errorChecks.validationErrorList.some((error) => error.subType === 'missing-rates');
          this.cartFormService.missingRates$.next(missingRates);

          if (errorChecks.validationErrorList.length > 0) {
            this.cartFormService.overwriteApproved$.next(
              !errorChecks.validationErrorList.some((error) => error.subType === 'will-overwrite'),
            );
          } else {
            this.cartFormService.overwriteApproved$.next(null);
          }

          this.setValidationErrors(errorChecks, !missingRates);
          this.cartFormService.jobsValidated$.next(true);
        }),
      )
      .subscribe();
  }

  openValidationErrorDialog(errorUuids: string[]) {
    const errorList = this.cartFormService.validationErrorList$.value;
    if (!errorList) {
      return;
    }

    const errorDialog = this.dialog.open<
      CheckoutValidationErrorsDialogComponent,
      validationErrorsInput,
      validationErrorsOutput
    >(CheckoutValidationErrorsDialogComponent, {
      data: { errors: errorList as ValidationErrorList[], orderType: this.orderType },
    });
    errorDialog
      .afterClosed()
      .pipe(
        take(1),
        switchMap((result) => {
          if (!result?.confirmed) {
            this.cartState.isValidated$.next(false);
            return EMPTY;
          } else {
            this.cartState.isUpdating$.next(true);
            return this.cartApiService.delete(errorUuids).pipe(
              switchMap(() => {
                this.cartState.resetAfterAssetsRemoved();
                this.cartFormService.validationErrorList$.next(null);
                this.cartFormService.validationErrorAssetUuids$.next([]);
                return this.navigateToCart$;
              }),
              catchError(() => {
                this.toast.error({ id: 'error', message: 'Failed to remove assets' });
                return of(EMPTY);
              }),
            );
          }
        }),
      )
      .subscribe();
  }

  private setValidationErrors(errorChecks: ValidationErrorsOutput, validated = true) {
    const { validationErrorList } = errorChecks;
    const { errorUuids } = errorChecks;

    this.cartFormService.validationErrorAssetUuids$.next(errorUuids);
    this.cartFormService.validationErrorList$.next(validationErrorList);

    this.cartState.isValidated$.next(validated);
    this.cartFormService.isLoading$.next(false);
    this.cartState.isSubmitting$.next(false);
    this.cartState.isUpdating$.next(false);
  }

  private noValidationErrors() {
    this.toast.success({ id: 'validation_success', message: 'Validation success.' });
    this.cartFormService.isLoading$.next(false);
    this.cartState.isValidated$.next(true);
  }

  private submitStandardCart(dialogOutput: SubmitData, sendAsSharedPack: boolean) {
    this.cartState.isSubmitting$.next(true);

    // discount code if null - no discount will be applied - nor department nor normal discount
    const order: OrderModelPost = {
      uuid: uuidv4(),
      type: this.orderType,
      subject: dialogOutput.subject ?? undefined,
      package_title: dialogOutput.packageTitle ?? undefined,
      purchase_order_no: dialogOutput.purchaseOrderNo ?? undefined,
      delivery_date: dialogOutput.deliveryDate ? dialogOutput.deliveryDate : null,
      expires_at: dialogOutput.expiresAt ? dialogOutput.expiresAt : null,
      comment: dialogOutput.comment ?? undefined,
      discount_code:
        this.cartState.discountCode === null
          ? null
          : this.cartState.department.uuid
          ? this.cartState.discountCode ?? undefined
          : this.cartState.discountCode,
      send_as_shared_pack: sendAsSharedPack,
      has_estimate: true,
      download_limit:
        this.cartFormService.form.controls.downloadLimit.dirty || this.cartFormService.form.controls.unlimited.dirty
          ? this.cartFormService.form.controls.downloadLimit.value
          : undefined,
      department_field_uuid: dialogOutput.department_field_uuid ?? undefined,
    };

    if (dialogOutput?.emails) {
      order.delivery_emails = dialogOutput?.emails;
    }

    if (dialogOutput?.notificationEmails) {
      order.notification_emails = dialogOutput?.notificationEmails;
    }

    if (dialogOutput?.destinations?.length) {
      order.destinations = dialogOutput.destinations.map(({ uuid, configUuid, hasBurnInText, burnInTextValue }) => ({
        uuid,
        config_uuid: configUuid,
        burn_in_text_value: hasBurnInText ? burnInTextValue : undefined,
      }));
    }

    if (this.orderType === ORDER_TYPE.WORKFLOW) {
      order.jobs = this.cartFormService.workflowJobs;
    }

    this.apiService
      .submitOrder(order)
      .pipe(
        tap((order) => {
          this.submittedOrderUuid = order.data.uuid;
          this.#popToast.SUBMIT_SUCCESS();
          this.cartState.refreshCart();
          this.cartState.isSubmitting$.next(false);
          this.cartState.checkoutStep$.next(0);
          this.cartState.isOrderApproved$.next(true);
          this.cartState.discount$.next({ discountCode: null, discountStatus: DISCOUNT_STATUS.NONE });
          return of(true);
        }),
        catchError(() => {
          this.#popToast.SUBMIT_FAILURE();
          this.cartState.isSubmitting$.next(false);
          return of(false);
        }),
      )
      .subscribe();
  }

  leavingCartConfirmation() {
    return this.confirmation.open(LEAVING_CART_DIALOG_CONFIG);
  }
}
