import { inject, Injectable } from '@angular/core';
import { Filters, FilterValue, isValueAgg, PersistenceSearchQuery } from '@vdms-hq/api-contract';
import { FilterDefinitionModel, SortDirection } from '@vdms-hq/shared';
import { BreadCrumb, Folder } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take, withLatestFrom } from 'rxjs/operators';
import { LibrarySchemaService } from './library-schema.service';
import { LibraryAssetSearchService } from './library-asset-search-service';

type SegmentWithFilter = { segment: FilterDefinitionModel; filter: { value: FilterValue } };

@Injectable({
  providedIn: 'root',
})
export class LibrarySegmentsService {
  private schemeService = inject(LibrarySchemaService);
  private assetSearchService = inject(LibraryAssetSearchService);

  loading$ = this.assetSearchService.loading$;
  #sortInput$ = new BehaviorSubject<string | null>(null);
  #sortDirection$ = new BehaviorSubject<SortDirection | 'desc_results' | 'asc_results'>('asc');
  #definedSegmentsWithFilters$: Observable<SegmentWithFilter[]> = combineLatest([
    this.assetSearchService.currentParams$,
    this.schemeService.activeSchemaFields$,
  ]).pipe(
    map(([params, segments]) => {
      const pairs = [];

      segments.forEach((segment) => {
        pairs.push({
          segment: segment,
          filter: params.query.get(segment.id),
        });
      });

      return pairs;
    }),
  );

  #breadcrumbs$: Observable<BreadCrumb[]> = this.#definedSegmentsWithFilters$.pipe(
    map((pairs) => {
      const nextBreadCrumbs: BreadCrumb[] = pairs
        .filter((pair) => pair.filter !== null)
        .map((filter) => ({
          name: `${filter.segment.label} — ${
            ['null', '-1'].includes(filter.filter.value.toString().toLowerCase()) ? '[ empty ]' : filter.filter.value
          }`,
          clickable: true,
        }));

      if (nextBreadCrumbs[nextBreadCrumbs.length - 1]) {
        nextBreadCrumbs[nextBreadCrumbs.length - 1].clickable = false;
      }

      const breadcrumbs = [
        { name: 'Home', path: '/home' },
        {
          name: 'Browse Library',
          clickable: false,
        },
        ...nextBreadCrumbs,
      ];

      if (breadcrumbs.length > 2) {
        breadcrumbs[1].clickable = true;
      }

      return breadcrumbs;
    }),
  );
  lastBreadCrumb$ = this.#breadcrumbs$.pipe(
    map((breadcrumbs) => ({
      name: breadcrumbs[breadcrumbs.length - 1].name,
    })),
  );
  breadcrumbs$ = this.#breadcrumbs$;
  #currentDefinedSegment$: Observable<FilterDefinitionModel | undefined> = combineLatest([
    this.assetSearchService.currentParams$,
    this.schemeService.activeSchemaFields$,
  ]).pipe(map(([params, segments]) => segments.filter((item) => params.query.get(item.id)?.value === undefined)?.[0]));

  currentDefinedSegment$ = this.#currentDefinedSegment$;
  isLastSegment$ = this.#currentDefinedSegment$.pipe(map((segment) => !segment));
  currentSegmentValuesAsFolders$: Observable<Folder[]> = combineLatest([
    this.assetSearchService.aggregations$,
    this.#currentDefinedSegment$,
    this.#sortDirection$,
    this.#sortInput$,
  ]).pipe(
    map(([aggregations, activeSegment, sortDirection, inputValue]) => {
      if (!activeSegment) {
        return [];
      }

      const aggregation = aggregations?.find((item) => item.name === activeSegment.filters.aggregationKey);
      const valueAgg = isValueAgg(aggregation);

      if (valueAgg) {
        return valueAgg.data
          .map((data) => ({
            title: data.value,
            subtitle: `${data.count} results`,
          }))
          .filter((value) => {
            if (!inputValue) {
              return true;
            }
            return value?.title?.toString()?.toLowerCase().includes(inputValue?.toLowerCase());
          })
          .sort((a, b) => {
            if (!a || !b) {
              return 0;
            }
            const isEmptyA = ['null', '-1'].includes(a?.title?.toString().toLocaleLowerCase());
            const isEmptyB = ['null', '-1'].includes(b?.title?.toString().toLocaleLowerCase());

            switch (sortDirection) {
              case 'asc':
                if (isEmptyA) {
                  return -1;
                } else if (isEmptyB) {
                  return 1;
                } else {
                  return a?.title
                    ?.toString()
                    .toLocaleLowerCase()
                    .localeCompare(b?.title.toString().toLocaleLowerCase(), undefined, { numeric: true });
                }
              case 'desc':
                if (isEmptyA) {
                  return 1;
                } else if (isEmptyB) {
                  return -1;
                } else {
                  return b?.title
                    ?.toString()
                    .toLocaleLowerCase()
                    .localeCompare(a?.title.toString().toLocaleLowerCase(), undefined, { numeric: true });
                }
              case 'asc_results':
                return +a?.subtitle.split(' ')[0] > +b?.subtitle.split(' ')[0] ? 1 : -1;
              case 'desc_results':
                return +a?.subtitle.split(' ')[0] < +b?.subtitle.split(' ')[0] ? 1 : -1;
            }
          });
      }

      return [];
    }),
  );

  updateActiveSegmentValue(value: string) {
    this.currentDefinedSegment$
      .pipe(take(1), withLatestFrom(this.assetSearchService.currentParams$))
      .subscribe(([filterDef, prevParams]) => {
        const next = prevParams.clone();
        const filters: Filters = {
          ...prevParams.query.filters,
        };

        filters[filterDef.id] = {
          filterDef,
          value,
        };

        next.query = PersistenceSearchQuery.fromFilters('', filters);

        this.assetSearchService.applyParams(next);
      });
  }

  returnNewSegmentValue(value: string) {
    return this.currentDefinedSegment$.pipe(
      take(1),
      withLatestFrom(this.assetSearchService.currentParams$),
      map(([filterDef, prevParams]) => {
        const next = prevParams.clone();
        const filters: Filters = {
          ...prevParams.query.filters,
        };

        filters[filterDef.id] = {
          filterDef,
          value,
        };

        next.query = PersistenceSearchQuery.fromFilters('', filters);

        return next;
      }),
    );
  }

  sort(direction: SortDirection): void {
    this.#sortDirection$.next(direction);
  }

  sortByText(value): void {
    this.#sortInput$.next(value);
  }

  selectBreadCrumb(untilIndex: number) {
    this.#definedSegmentsWithFilters$
      .pipe(take(1), withLatestFrom(this.assetSearchService.currentParams$))
      .subscribe(([segments, prevParams]) => {
        const nextFilters: Filters = {};

        segments.forEach((segment, index) => {
          if (index >= untilIndex - 1) {
            return;
          }

          nextFilters[segment.segment.id] = {
            filterDef: segment.segment,
            value: segment.filter && segment.filter.value !== null ? segment.filter.value : '',
          };
        });

        const next = prevParams.clone();
        next.query = PersistenceSearchQuery.fromFilters('', nextFilters);
        this.assetSearchService.applyParams(next);
      });
  }
}
