<div class="slider-wrapper">
  <vdms-hq-ui-loader
    mode="over-parent"
    [center]="false"
    [centerPadding]="false"
    [backdrop]="true"
    *ngIf="(dataSource.isLoading$ | async) ?? false"
  >
  </vdms-hq-ui-loader>
  <swiper-container
    class="swiper"
    init="false"
    [config]="config"
    vdmsHqSwiper
    *ngIf="dataSource.allData$ | async; let slides"
  >
    <ng-container *ngIf="(dataSource.isLoading$ | async) === false">
      <swiper-slide *ngFor="let slide of slides" class="swiper-slide">
        <div class="slide">
          <img class="slide-image" [src]="slide.image" [alt]="slide.title" />
          <div class="slide-content-wrapper">
            <div class="slide-content">
              <div class="name">{{ slide | valueByPath : 'title' | async }}</div>
              <div class="description">{{ slide.description | truncate : 250 }}</div>
              <div class="date">{{ slide.date ? (slide.date | date : 'dd-MM-yyyy') : '' }}</div>
              <div class="actions">
                <template
                  [ngTemplateOutlet]="template"
                  [ngTemplateOutletContext]="{ id: slide.id, slide: slide }"
                ></template>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </ng-container>
  </swiper-container>
</div>
