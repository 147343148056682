import { Routes } from '@angular/router';
import { RightsContractsResultsComponent } from './pages/rights-contracts-results/rights-contracts-results.component';
import { SingleRightsContractComponent } from './pages/single-rights-contract/single-rights-contract.component';
import { SingleRightContractDetailsResolverService } from './logic/single-right-contract-details-resolver.service';

export enum RIGHTS_CONTRACTS_ROUTER_BASE {
  ROOT = 'rights-contracts',
  SINGLE_PACKAGE_TITLE = ':uuid',
}

export const rightsContractsRouting: Routes = [
  {
    path: '',
    component: RightsContractsResultsComponent,
  },
  {
    path: RIGHTS_CONTRACTS_ROUTER_BASE.SINGLE_PACKAGE_TITLE,
    component: SingleRightsContractComponent,
    resolve: {
      uuid: SingleRightContractDetailsResolverService,
    },
    providers: [SingleRightContractDetailsResolverService],
  },
  {
    path: '**',
    redirectTo: `/${RIGHTS_CONTRACTS_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
