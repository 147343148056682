<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="[{ name: 'Filename Conventions' }]">
  <ng-container actions>
    <div class="header--actions__wrapper">
      <vdms-hq-ui-button (click)="add()" color="primary">Add new</vdms-hq-ui-button>
    </div>
  </ng-container>
  <div class="content" [formGroup]="form">
    <ng-container formArrayName="conventions">
      <div
        class="content__row"
        *ngFor="let convention of conventionsFormArray.controls; let index = index"
        [formGroupName]="index"
      >
        <div class="content__row__controls">
          <vdms-hq-ui-form-input-text formControlName="name" [label]="'Name'"></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-form-input-text
            [withFooter]="true"
            formControlName="template"
            [label]="'Pattern'"
          ></vdms-hq-ui-form-input-text>
          <vdms-hq-ui-form-input-select
            formControlName="separator"
            [selectOptions]="separatorSelectOptions"
            [label]="'Separator'"
          ></vdms-hq-ui-form-input-select>
          <vdms-hq-ui-form-input-select
            [multiple]="true"
            formControlName="default_for"
            [selectOptions]="defaultForSelectOptions"
            [label]="'Default for'"
          ></vdms-hq-ui-form-input-select>
          <div class="content__row__controls__actions">
            <vdms-hq-ui-button [icon]="'delete'" (click)="remove(index)" color="warn"></vdms-hq-ui-button>
            <vdms-hq-ui-button
              (click)="save(index)"
              color="primary"
              [icon]="'save'"
              [disabled]="conventionsFormArray.controls[index].pristine || conventionsFormArray.controls[index].invalid"
            ></vdms-hq-ui-button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</vdms-hq-ui-layout-page-wrapper>
