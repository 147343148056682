import { Routes } from '@angular/router';
import { ErrorHandlingRoutes } from '@vdms-hq/error-handling';
import { AdminFieldsConfigService } from './logic/admin-fields-config.service';
import { PageResultsSettingsComponent } from './pages/page-settings/page-results-settings.component';

export const TABLES_AND_LIST_ADMIN_ROUTING: Routes = [
  {
    component: PageResultsSettingsComponent,
    path: ':configKey',
    resolve: {
      config: AdminFieldsConfigService,
    },
  },
  {
    path: '**',
    redirectTo: '/error/' + ErrorHandlingRoutes.NOT_FOUND,
  },
];
