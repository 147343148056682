import { Routes } from '@angular/router';
import { UploadAsperaComponent } from './aspera-upload/upload-aspera.component';

export enum ASSET_STORAGE_ROUTER_BASE {
  UPLOAD_ASPERA = 'upload-aspera',
}

export const assetStorageRoutes: Routes = [
  {
    path: ASSET_STORAGE_ROUTER_BASE.UPLOAD_ASPERA,
    component: UploadAsperaComponent,
  },
  {
    path: '**',
    redirectTo: ASSET_STORAGE_ROUTER_BASE.UPLOAD_ASPERA,
    pathMatch: 'full',
  },
];
