import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { E2eIdDirective, isPageableDataSource, PageableDataSource } from '@vdms-hq/shared';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'vdms-hq-ui-paginator[dataSource]',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatPaginatorModule, E2eIdDirective],
})
export class PaginatorComponent {
  isPageableDataSource = isPageableDataSource;
  @Input() dataSource!: Partial<PageableDataSource>;
  @Input() hidePageSize = false;
}
