import { Injectable } from '@angular/core';
import { ClientRef } from '@vdms-hq/firebase-contract';
import { isMenuSectionWithPermissions, PermissionAwareItem } from '@vdms-hq/shared';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActivatedClientService } from '../logic/services/activated-client.service';
import { Permission } from './permission';

export interface CustomFilterFn {
  (item: PermissionAwareItem): boolean;
}

export interface CustomMapFn {
  (item: PermissionAwareItem): PermissionAwareItem;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private readonly activatedClientService: ActivatedClientService) {}

  public verifyPermissions(
    permissions: Permission[] = [],
    userPermissions: Permission[] = [],
    operator: 'some' | 'every' = 'every',
  ): boolean {
    return permissions[operator]((permission: Permission) => userPermissions.includes(permission));
  }

  public verifyWithOwnedPermissions$(
    requiredPermissions: Permission[],
    comparisonOperator: 'some' | 'every' = 'every',
  ) {
    return this.activatedClientService.permissions$.pipe(
      map((userPermissions: Permission[]) =>
        this.verifyPermissions(requiredPermissions, userPermissions, comparisonOperator),
      ),
    );
  }

  /**
   * @param items array of objects with permissions property
   * @param options object with options
   * @param options.disable if true, will disable menu items if user doesn't have permissions instead of removing it
   * @param options.customMapFn custom function to map individual menu items
   * @param options.customFilterFn custom function to filter menu items in addition to permissions check
   */
  public filterItemsAgainstPermissions$<T extends PermissionAwareItem>(
    items: T[],
    options?: {
      disable?: boolean;
      customMapFn?: CustomMapFn;
      customFilterFn?: CustomFilterFn;
    },
  ): Observable<T[]> {
    const filterItems = (items: PermissionAwareItem[], clientId: ClientRef, permissions: Permission[]) => {
      return items
        .map(options?.customMapFn || ((item) => item))
        .filter(options?.customFilterFn || ((item) => item))
        .filter((item: PermissionAwareItem) => {
          if (isMenuSectionWithPermissions(item)) {
            item.menuItems = filterItems(item.menuItems, clientId, permissions);
          }

          return this.verifyPermissions(
            (item.permissions?.ids || []) as Permission[],
            permissions,
            item.permissions?.comparison || 'every',
          );
        })
        .filter((item: PermissionAwareItem) =>
          this.verifyPermissions((item.permissions?.required || []) as Permission[], permissions),
        );
    };

    const disableItems = (
      items: PermissionAwareItem[],
      clientId: ClientRef,
      permissions: Permission[],
    ): PermissionAwareItem[] => {
      return items.map((item) => {
        return {
          ...item,
          disabled: !this.verifyPermissions(
            (item.permissions?.ids || []) as Permission[],
            permissions,
            item.permissions?.comparison || 'every',
          ),
        };
      }) as unknown as PermissionAwareItem[];
    };

    const itemsMappingFn = options?.disable ? disableItems : filterItems;

    const mapConfig = (
      items: PermissionAwareItem[],
      clientId: ClientRef,
      permissions: Permission[],
    ): PermissionAwareItem[] => {
      return itemsMappingFn(items, clientId, permissions);
    };

    return this.activatedClientService.clientId$.pipe(
      switchMap((clientId) => {
        if (!clientId) {
          return of([]) as unknown as Observable<T[]>;
        }

        return this.activatedClientService.permissions$.pipe(
          map((permissions) => mapConfig(items, clientId, permissions) as T[]),
        );
      }),
    );
  }
}
