import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AsperaSelectorComponent, AsperaTransferBatch } from '@vdms-hq/storage';
import { AssetUploadService } from '../../logic/asset-upload.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { filter } from 'rxjs/operators';
import { take } from 'rxjs';

@Component({
  selector: 'vdms-hq-asset-placeholder-upload-dialog',
  standalone: true,
  imports: [
    CommonModule,
    AsperaSelectorComponent,
    TranslateModule,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    UILayoutModule,
    UISimpleDetailsListModule,
  ],
  templateUrl: './asset-placeholder-upload-dialog.component.html',
})
export class AssetPlaceholderUploadDialogComponent {
  assetUploadService = inject(AssetUploadService);
  dialogRef = inject(MatDialogRef<AssetPlaceholderUploadDialogComponent>);
  data: { assetId: string } = inject(MAT_DIALOG_DATA);

  close() {
    this.dialogRef.close();
  }

  startUpload(batch: AsperaTransferBatch) {
    batch.state$
      .pipe(
        filter((state) => ['done', 'failed', 'cancelled'].includes(state.status)),
        take(1),
      )
      .subscribe((state) => {
        if (state.status === 'done') {
          this.close();
        }
      });

    this.assetUploadService.startPlaceholderUpload(batch, this.data.assetId);
  }
}
