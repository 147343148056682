import { inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import { PaginationAPIModel as Pagination } from '../pagination.model';
import { ClientTeamsEndpoints, GetTeamInterface, PatchTeamInterface, PostTeamInterface } from './teams.model';

type ClientTeamsFiltersView = {
  keyword: string;
};

@Injectable({ providedIn: 'root' })
export class TeamsService {
  private readonly apiService = inject(ApiService);

  getTeams = (pagination?: Pagination, filters?: ClientTeamsFiltersView) => {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    if (filters) {
      params = filters.keyword ? params.append('keyword', filters.keyword) : params;
    }
    headers = headers.append('Cache-Control', 'max-age=0');

    return this.apiService
      .get<ApiPaginatedResponse<GetTeamInterface>>(`${ClientTeamsEndpoints.ROOT}`, { headers, params })
      .pipe(GetResponsePaginationData);
  };

  getTeam = (uuid: string) => {
    let headers = new HttpHeaders();
    headers = headers.append('Cache-Control', 'max-age=0');
    return this.apiService
      .get<ApiResponse<GetTeamInterface>>(`${ClientTeamsEndpoints.SINGLE}/${uuid}`, { headers })
      .pipe(GetResponseData);
  };

  createTeam = (body: PostTeamInterface) => {
    return this.apiService
      .post<PostTeamInterface, ApiResponse<GetTeamInterface>>(`${ClientTeamsEndpoints.SINGLE}`, body)
      .pipe(GetResponseData);
  };

  updateTeam = (uuid: string, body: PatchTeamInterface) => {
    return this.apiService
      .patch<PatchTeamInterface, ApiResponse<GetTeamInterface>>(`${ClientTeamsEndpoints.SINGLE}/${uuid}`, body)
      .pipe(GetResponseData);
  };

  deleteTeam = (uuid: string) => {
    return this.apiService.delete<null, undefined>(`${ClientTeamsEndpoints.SINGLE}/${uuid}`);
  };
}
