import { Injectable } from '@angular/core';
import { TranscriptionItem, TranscriptionService } from '@vdms-hq/api-contract';
import { PlayerService } from '@vdms-hq/player';
import { ConnectableDataSource, filterEmpty, filterUndefined, LoadableDataSource } from '@vdms-hq/shared';
import { Framerate, Timecode } from '@vdms-hq/timecode';
import { TimecodeRange } from '@vdms-hq/ui';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';

export interface ViewTranscriptionItem extends TimecodeRange {
  uuid: string;
  timecodeIn: Timecode | null;
  timecodeOut: Timecode | null;
  offset: Timecode | null;
  content: string;
}

export interface TranscriptionConfig {
  assetId: string;
  framerate: Framerate;
}

@Injectable({ providedIn: 'root' })
export class TranscriptionDataSourceService
  implements ConnectableDataSource<ViewTranscriptionItem>, LoadableDataSource
{
  isLoading$ = new BehaviorSubject(true);
  emptyResults$ = of(false);

  #config$ = new BehaviorSubject<TranscriptionConfig | null>(null);
  assetId$ = this.#config$.asObservable().pipe(
    filterEmpty(),
    map((item) => item.assetId),
  );
  framerate$ = this.#config$.asObservable().pipe(
    filterEmpty(),
    map((item) => item.framerate),
  );

  #allTranscriptions$ = this.assetId$.pipe(
    switchMap((assetId) =>
      this.transcriptionService.listForAsset(assetId).pipe(
        shareReplay(1),
        catchError(() => {
          this.isLoading$.next(false);
          this.emptyResults$ = of(true);
          return of([]);
        }),
      ),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  defaultTranscriptionUuid$ = this.#allTranscriptions$.pipe(
    map((transcription) => transcription.filter((transcription) => transcription.status === 'complete')[0]?.uuid),
    tap((data) => {
      if (data === undefined) {
        this.emptyResults$ = of(true);
      }
    }),
    filterUndefined(),
  );

  allData$ = this.defaultTranscriptionUuid$.pipe(
    filterEmpty(),
    switchMap((transcriptionUuid) => this.transcriptionService.transcriptionItems(transcriptionUuid)),
    tap(() => this.isLoading$.next(false)),
    withLatestFrom(this.framerate$, this.playerService.offset$, (items, framerate, offset) =>
      items.map((item) => this.#transform(item, framerate, offset)),
    ),
    shareReplay(1),
  );
  connection$ = this.allData$;

  #transform = (item: TranscriptionItem, framerate: Framerate, offset: Timecode | null): ViewTranscriptionItem => ({
    ...item,
    timecodeIn: Timecode.fromSeconds(item.timecodeIn, framerate, false),
    timecodeOut: item.timecodeOut ? Timecode.fromSeconds(item.timecodeOut, framerate, false) : null,
    offset,
  });

  configure(config: TranscriptionConfig) {
    if (this.#config$.value?.assetId === config.assetId) {
      return;
    }
    this.#config$.next(config);
  }

  constructor(
    private readonly transcriptionService: TranscriptionService,
    private readonly playerService: PlayerService,
  ) {}
}
