import { AfterViewInit, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MathPipesModule } from 'ng-pipes';
import { UIButtonModule, UIEmptyResultsModule, UIPreviewModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { PlayerMasterComponent } from '../player-master-component/player-master-component';
import { PlayerService } from '../../logic/player.service';
import { Timecode } from '@vdms-hq/timecode';
import { PlayerConfiguration } from '../../logic/player.interface';
import { AdvancedPlayerService } from '../../logic/advanced-player.service';
import { SimplePlayerService } from '../../logic/simple-player.service';
import { AudioPlayerService } from '../../logic/audio-player.service';
import { SimplePlayerComponent } from '../simple-player/simple-player.component';
import { AdvancedPlayerComponent } from '../advanced-player/advanced-player.component';
import { AudioPlayerComponent } from '../audio-player/audio-player.component';

@Component({
  selector: 'vdms-hq-player[config]',
  templateUrl: '../player-master-component/player-master.component.html',
  styleUrls: ['../player-master-component/player-master.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIEmptyResultsModule,
    UIButtonModule,
    UIPreviewModule,
    TranslateModule,
    MathPipesModule,
    SimplePlayerComponent,
    AdvancedPlayerComponent,
    AudioPlayerComponent,
  ],
  providers: [PlayerService, AdvancedPlayerService, SimplePlayerService, AudioPlayerService],
})
export class PlayerComponent extends PlayerMasterComponent implements AfterViewInit {
  override playerService = inject(PlayerService);

  @Output() playerServiceInstance = new EventEmitter<PlayerService>();
  @Output() override changeOffset = new EventEmitter<Timecode>();
  @Input() override config!: PlayerConfiguration;

  ngAfterViewInit() {
    this.playerServiceInstance.emit(this.playerService);
  }
}
