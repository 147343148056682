import {
  TransformedTemplate,
  SeparatorType,
  NotableFieldType,
  FilenameConventionExtraData,
  ALL_SEPARATORS,
  FieldTemplatePart,
  ALL_NOTABLE_FIELDS,
  StringProcessorType,
  ProcessorTemplate,
  ALL_STRING_PROCESSORS,
  StringProcessorsParamCountMap,
  ProcessorParamType,
  FilenameConvention,
} from '@vdms-hq/api-contract';

/**
 * @description Utils for filename convention, may be useful - remove if not
 */
export class FilenameConventionUtils {
  public static transformTemplate(filenameConvention: FilenameConvention): TransformedTemplate {
    type ExtractedPart = {
      index: number;
      rawPartString: string;
      type: 'TEXT' | 'FIELD';
    };
    const extractParts = (template: string) => {
      const result = [] as ExtractedPart[];
      let index = 0;
      let insideNotableFieldString = false;
      let insidePossibleRegex = false;
      let rawPartString = '';
      for (const char of template) {
        if (insideNotableFieldString) {
          switch (char) {
            case '/':
              insidePossibleRegex = !insidePossibleRegex;
              break;
            case '{':
              if (insidePossibleRegex) {
                rawPartString += char;
                break;
              }
              throw new Error('Double {');
            case '}':
              if (insidePossibleRegex) {
                rawPartString += char;
                break;
              }
              result.push({
                index,
                rawPartString,
                type: 'FIELD',
              });
              rawPartString = '';
              insideNotableFieldString = false;
              index++;
              break;
            default:
              rawPartString += char;
          }
        } else {
          switch (char) {
            case '{':
              if (rawPartString.length) {
                result.push({
                  index,
                  rawPartString,
                  type: 'TEXT',
                });
                rawPartString = '';
                index++;
              }
              insideNotableFieldString = true;
              break;
            case '}':
              throw new Error('Double }');
            default:
              rawPartString += char;
          }
        }
      }
      if (insidePossibleRegex) throw new Error('Missing /');
      if (insideNotableFieldString) throw new Error('Missing }');
      return result;
    };

    type ExtractedPartWithProcessors = ExtractedPart & {
      partString: string;
      processors: {
        index: number;
        rawProcessor: StringProcessorType;
      }[];
    };
    const extractProcessors = (parts: ExtractedPartWithProcessors[]) => {
      const regex = /^([a-zA-Z]*)(\[(.+)])?$/;
      for (const part of parts) {
        if (part.type === 'TEXT') continue;
        const match = part.rawPartString.match(regex);
        if (!match?.length) throw new Error(`Regex processor fail '${part.rawPartString}'`);
        part.partString = match[1];
        part.processors = [] as ExtractedPartWithProcessors['processors'];
        if (match[3]) {
          part.processors = match[3]
            .split(/,(?![^(]*\))/)
            .map((rawProcessor, index) => ({ index, rawProcessor: rawProcessor as StringProcessorType }));
        }
      }
      return parts;
    };
    type ExtractedPartWithProcessorsAndParams = ExtractedPartWithProcessors & {
      processors: {
        index: number;
        rawProcessor: string;
        processorString: string;
        params: string[];
      }[];
    };
    const extractParams = (parts: ExtractedPartWithProcessorsAndParams[]) => {
      const regex = /^([a-zA-Z]*)(\((.+)\))?$/;
      for (const part of parts) {
        if (part.type === 'TEXT') continue;
        if (!part.processors.length) continue;
        for (const processor of part.processors) {
          const match = processor.rawProcessor.match(regex);
          if (!match?.length) throw new Error(`Regex param fail '${processor.rawProcessor}'`);
          processor.processorString = match[1];
          processor.params = [];
          if (match[3]) {
            processor.params = match[3].split(/,(?![^{}()[\]/]*[})\]/])/);
          }
        }
      }
      return parts;
    };
    const validateAndMap = (
      filenameConvention: any,
      rawParts: ExtractedPartWithProcessorsAndParams[],
    ): TransformedTemplate => {
      const result = {
        rawTemplate: filenameConvention.template,
        separator: filenameConvention.separator,
        parts: [],
      } as TransformedTemplate;
      if (!ALL_SEPARATORS.includes(filenameConvention.separator))
        throw new Error(`Unknown separator '${filenameConvention.separator}'`);
      for (const rawPart of rawParts) {
        if (rawPart.type === 'TEXT') {
          result.parts.push({ index: rawPart.index, text: rawPart.rawPartString });
        } else if (rawPart.type === 'FIELD') {
          const newPart = {
            index: rawPart.index,
            notableField: rawPart.partString as NotableFieldType,
            processors: [],
          } as FieldTemplatePart;
          if (!ALL_NOTABLE_FIELDS.includes(rawPart.partString as NotableFieldType))
            throw new Error(`Unknown notable field '${rawPart.partString}'`);
          for (const rawProcessor of rawPart.processors) {
            const newProcessor = {
              index: rawProcessor.index,
              processor: rawProcessor.processorString as StringProcessorType,
              params: rawProcessor.params,
            } as ProcessorTemplate;
            if (!ALL_STRING_PROCESSORS.includes(rawProcessor.processorString as StringProcessorType))
              throw new Error(`Unknown processor '${rawProcessor.processorString}'`);

            const numberOfParams =
              StringProcessorsParamCountMap[rawProcessor.processorString as StringProcessorType]?.length || 0;
            if (rawProcessor.params.length !== numberOfParams) {
              throw new Error(
                `Wrong param length - passed: ${rawProcessor.params.length}, required: ${numberOfParams}`,
              );
            }

            if (StringProcessorsParamCountMap[rawProcessor.processorString as StringProcessorType]?.length) {
              for (const [indexStr, rawParam] of Object.entries(rawProcessor.params)) {
                const index = parseInt(indexStr, 10);
                const param = StringProcessorsParamCountMap[rawProcessor.processorString as StringProcessorType];

                if (!param) {
                  throw new Error(
                    `Processor '${rawProcessor.processorString}' does not have param with index ${index}`,
                  );
                }

                const paramType = param[index] as ProcessorParamType;

                switch (paramType) {
                  case 'number':
                    if (!/^-?\d+(\.\d+)?$/.test(rawParam)) {
                      throw new Error(`Wrong param '${rawParam}' for '${rawProcessor.processorString}'`);
                    }
                    break;
                  case 'regex':
                    try {
                      new RegExp(rawParam);
                    } catch (_) {
                      throw new Error(
                        `Param '${rawParam}' for '${rawProcessor.processorString}' is not a valid regular expression`,
                      );
                    }
                    break;
                }
              }
            }
            newPart.processors.push(newProcessor);
          }
          result.parts.push(newPart);
        } else {
          throw new Error('Inside error');
        }
      }
      return result;
    };
    try {
      const step1 = extractParts(filenameConvention.template);
      const step2 = extractProcessors(step1 as ExtractedPartWithProcessors[]);
      const step3 = extractParams(step2 as ExtractedPartWithProcessorsAndParams[]);
      return validateAndMap(filenameConvention, step3);
    } catch (error) {
      throw new Error(`Wrong template - ${error}`);
    }
  }

  public static getSeparationChar(separator: SeparatorType) {
    switch (separator) {
      case 'space':
        return ' ';
      case 'hyphen':
        return '-';
      case 'underscore':
        return '_';
      case 'none':
        return '';
    }
  }

  public static separateExtension(filenameWithExt: string) {
    const parts = filenameWithExt.split('.');
    const filename = parts.slice(0, -1).join('.') || filenameWithExt;
    const extension = parts.length > 1 ? parts.pop() : null;
    return { filename, extension };
  }

  public static getNotableFieldValue(
    notableField: NotableFieldType,
    asset: any,
    extraData: FilenameConventionExtraData,
  ): string {
    const getterMap: {
      [key in NotableFieldType]: () => string;
    } = {
      episodeName: () => asset.metadata.episodeName,
      episodeNumber: () => asset.metadata.episodeNumber && String(asset.metadata.episodeNumber),
      seriesName: () => asset.metadata.seriesName,
      seriesNumber: () => asset.metadata.seriesNumber && String(asset.metadata.seriesNumber),
      programmeNumber: () => asset.metadata.programmeNumber,
      facilityOfOrigin: () =>
        asset.metadata.facilityOfOriginField.key && String(asset.metadata.facilityOfOriginField.key),
      language: () => asset.metadata.originalSpokenLanguageIso.ISO_639_2,
      elements: () => asset.metadata.elements,
      segments: () => asset.metadata.seamlessSegmented,
      genre: () => asset.metadata.genre,
      productionCompany: () => asset.metadata.productionCompany,
      contentClass: () => asset.metadata.contentClass,
      contentType: () => asset.metadata.contentType,
      theme: () => asset.metadata.theme,
      variation: () => asset.metadata.variation,
      category: () => asset.metadata.category,
      seasonTitle: () => asset.metadata.seasonTitle,
      releaseYear: () => asset.metadata.releaseYear,
      resolution: () => asset.metadata.vidaResolution,
      codec: () => asset.hybrikData.codec,

      orderPackageTitle: () => extraData.orderPackageTitle,
      orderNo: () => extraData.orderNo,

      audioLayout: () => asset.metadata.audioTracks[0].trackLayout,
      audioLanguage: () => asset.metadata.audioTracks[0].trackLanguageIso.ISO_639_2,
    };
    return getterMap[notableField]() || '';
  }
}
