<form [formGroup]="form" (ngSubmit)="submit()">
  <vdms-hq-ui-dialog-wrapper>
    <h1 title>{{ 'common.global.edit' | translate }} - {{ data.item.label | translate }}</h1>

    <div content class="dialog-content">
      <div class="group">
        <span class="group-title">General</span>

        <div class="field-info">
          <div>Id: {{ data.item.id }}</div>
          <div>Modified: {{ data.item.isModified ? 'Yes' : 'No' }}</div>
        </div>

        <vdms-hq-ui-form-input-text
          formControlName="label"
          placeholder="Series name"
          label="Label"
          [hint]="'common.global.label_hint' | translate"
        ></vdms-hq-ui-form-input-text>

        <vdms-hq-ui-form-input-select
          [selectOptions]="availableValueFormats"
          formControlName="format"
          placeholder="as-is"
          label="Display format"
          hint="Original value: {{ orgDefinition.format }}"
        ></vdms-hq-ui-form-input-select>

        <vdms-hq-autocomplete
          formControlName="sourceListKey"
          [autocompleteList]="typeSelectOptions"
          [placeholder]="'common.global.list_key' | translate"
          [label]="'common.global.list_key' | translate"
          hint="Original value: {{ orgDefinition.sourceListKey ?? 'N/A' }}"
        ></vdms-hq-autocomplete>
      </div>
      <div class="group" formGroupName="input" *ngIf="orgDefinition.input; let input">
        <span class="group-title">Input</span>

        <div class="field-info">
          <div *ngIf="input.objectPath">Object path: {{ input.objectPath }}</div>
          <div *ngIf="input.mask">Mask: {{ input.mask }}</div>
        </div>

        <vdms-hq-ui-form-input-toggle formControlName="readonly" label="Readonly"></vdms-hq-ui-form-input-toggle>

        <vdms-hq-ui-form-input-select
          [selectOptions]="availableTypes"
          formControlName="type"
          label="Type"
          hint="Original type: {{ orgDefinition.input.type ?? 'N/A' }}"
        >
        </vdms-hq-ui-form-input-select>
      </div>
      <ng-container *ngIf="orgDefinition.results2; let results">
        <span class="group-title">Results</span>
        <div class="field-info">
          <div *ngIf="results.objectPath">Object path: {{ results.objectPath ?? 'N/A' }}</div>
          <div *ngIf="results.sortObjectPath">Sort object path: {{ results.sortObjectPath ?? 'N/A' }}</div>
        </div>
      </ng-container>

      <div class="group" formGroupName="filters" *ngIf="orgDefinition.filters; let filters">
        <span class="group-title">Filter</span>

        <div class="field-info">
          <div *ngIf="filters.objectPath">Object path: {{ filters.objectPath ?? 'N/A' }}</div>
          <div *ngIf="filters.aggregationKey">Aggregation key: {{ filters.aggregationKey ?? 'N/A' }}</div>
          <div *ngIf="filters.validFormat">Format: {{ filters.validFormat ?? 'N/A' }}</div>
        </div>

        <vdms-hq-ui-form-input-select
          [selectOptions]="availableFilterTypes"
          formControlName="type"
          label="Type"
          hint="Original type: {{ orgDefinition.input?.type ?? 'N/A' }}"
        >
        </vdms-hq-ui-form-input-select>
      </div>
    </div>

    <div footer class="dialog-footer">
      <vdms-hq-ui-button color="secondary" (click)="close()">
        {{ 'common.global.cancel' | translate }}
      </vdms-hq-ui-button>
      <vdms-hq-ui-button [isSubmit]="true" [loading]="loading" color="primary">
        {{ 'common.global.edit' | translate }}
      </vdms-hq-ui-button>
    </div>
  </vdms-hq-ui-dialog-wrapper>
</form>
