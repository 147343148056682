<div class="multi-data-container relative">
  <vdms-hq-ui-data-presentation-header
    *ngIf="dataSource.currentContract$ | async as currentContract"
    [breadcrumbs]="[
      { name: 'Home', path: '/' },
      { name: 'common.rights_contracts.table.title', path: '../' }
    ]"
    [actions]="headerActions$ | async"
    (action)="customActionHandler($event)"
    [title]="currentContract?.name"
    [counterIndicator]="(dataSource.total$ | async) ?? 0"
    [listConfig]="currentContract.metadata ?? []"
  ></vdms-hq-ui-data-presentation-header>

  <vdms-hq-ui-multiple-data-presentation
    *ngIf="licensePackageConfig$ | async; let configuration"
    [configuration]="configuration"
    [dataSource]="dataSource"
    (action)="customActionHandler($event)"
    (changeDefaultView)="setPreferredTableView($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="dataSource.filters"
      [fieldsConfig]="filtersConfig$ | async"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
