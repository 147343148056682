import { ClientRef, Permission } from '@vdms-hq/activated-client';
import { COLLECTIONS_ROUTER_BASE } from '@vdms-hq/collections';
import { LICENSE_PACKAGES_ROUTER_BASE } from '@vdms-hq/license-packages';
import { ORDERS_ROUTER_BASE } from '@vdms-hq/orders';
import { RIGHTS_CONTRACTS_ROUTER_BASE } from '@vdms-hq/rights-contracts';
import { RIGHTS_PARTNERS_ROUTER_BASE } from '@vdms-hq/rights-partners';
import { MenuSectionWithPermissions, PermissionAwareItem } from '@vdms-hq/shared';
import { UISidenavMenuItem, UISidenavSection } from '@vdms-hq/ui';
import { SupportConfig } from '@vdms-hq/firebase-contract';
import { supportDefault } from '@vdms-hq/activated-client-admin';

const patheUuid: ClientRef = 'f0214365-8f35-4d0d-937f-9f97ddf3bec3';

interface UISidenavSectionWithPermissions extends UISidenavSection, MenuSectionWithPermissions {
  menuItems: (UISidenavMenuItem & PermissionAwareItem & { enabledFor?: ClientRef[] | string[] })[];
}

export type UISidenavConfigWithPermissions = UISidenavSectionWithPermissions[];

export interface SideNavSupportCfg {
  vidaSupportButton: SupportConfig;
  thirdPartySupport: SupportConfig;
}

export const sidenavConfig = (supportCfg?: SideNavSupportCfg): UISidenavConfigWithPermissions => [
  {
    id: 'general',
    label: 'pages.home.menu.general',
    permissions: {
      ids: [
        Permission.BROWSE_ASSETS,
        Permission.BROWSE_OWN_ASSETS,
        Permission.BROWSE_CHARTS,
        Permission.ASSET_UPLOAD,
        Permission.BROWSE_ASSETS_LIBRARY,
        Permission.BROWSE_COLLECTIONS,
        Permission.BROWSE_ALL_ORDERS,
        Permission.BROWSE_OWN_ORDERS,
        Permission.BROWSE_SHARED_ORDERS,
        Permission.BROWSE_ASSETS_STOREFRONT,
        Permission.APPROVE_ORDERS,
        Permission.BROWSE_ORDERS_TO_APPROVE,
        Permission.BROWSE_MEDIA_PULSE_ORDERS,
        Permission.BROWSE_SHARED_PACKS,
        Permission.BROWSE_ASSETS_ON_MAP,
        Permission.BROWSE_PREVIEW_REQUESTS,
        Permission.CREATE_REPORTS,
        Permission.USER_ADMIN_BROWSE,
      ],
      comparison: 'some',
    },
    menuItems: [
      {
        id: 'dashboard',
        route: '/home',
        permissions: {
          ids: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_STOREFRONT, Permission.BROWSE_OWN_ASSETS],
          comparison: 'some',
        },
        icon: 'home',
        label: 'pages.home.menu.home',
      },
      {
        id: 'aspera',
        permissions: { ids: [Permission.ASSET_UPLOAD] },
        route: '/upload-aspera',
        icon: 'cloud_queue',
        label: 'pages.home.menu.assets_upload',
      },
      {
        id: 'assets',
        permissions: {
          ids: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_STOREFRONT, Permission.BROWSE_OWN_ASSETS],
          comparison: 'some',
        },
        route: '/browse/all',
        icon: 'movie',
        label: 'pages.home.menu.assets',
        walkthroughTrigger: 'menuAssets',
      },
      {
        id: 'deleted-assets',
        permissions: {
          ids: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_STOREFRONT, Permission.BROWSE_OWN_ASSETS],
          comparison: 'some',
          required: [Permission.BROWSE_DELETED_ASSETS],
        },
        route: '/browse/deleted',
        icon: 'delete_sweep',
        label: 'pages.home.menu.deleted_assets',
        walkthroughTrigger: 'menuPurgedAssets',
      },
      {
        id: 'quarantined-assets',
        permissions: {
          ids: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_STOREFRONT, Permission.BROWSE_OWN_ASSETS],
          comparison: 'some',
          required: [Permission.BROWSE_QUARANTINED_ASSETS],
        },
        route: '/browse/quarantined',
        icon: 'health_and_safety',
        label: 'pages.home.menu.quarantined_assets',
        // todo add walkthrough
        // walkthroughTrigger: 'menuPurgedAssets',
      },

      {
        id: 'browse-library',
        walkthroughTrigger: 'menuLibrary',
        permissions: { ids: [Permission.BROWSE_ASSETS, Permission.BROWSE_ASSETS_LIBRARY], comparison: 'every' },
        route: '/browse-library',
        icon: 'account_tree',
        label: 'pages.home.menu.assets_library',
      },
      {
        id: 'collections',
        permissions: { ids: [Permission.BROWSE_COLLECTIONS] },
        route: `${COLLECTIONS_ROUTER_BASE.COLLECTIONS}`,
        icon: 'playlist_add',
        label: 'pages.home.menu.lists',
      },
      {
        id: 'orders',
        permissions: {
          ids: [Permission.BROWSE_ALL_ORDERS, Permission.BROWSE_OWN_ORDERS, Permission.BROWSE_SHARED_ORDERS],
          comparison: 'some',
        },
        route: ORDERS_ROUTER_BASE.ORDERS_ALL,
        icon: 'menu_book',
        label: 'common.orders.title',
      },
      {
        id: 'orders_approval',
        permissions: { ids: [Permission.APPROVE_ORDERS, Permission.BROWSE_ORDERS_TO_APPROVE], comparison: 'some' },
        route: `${ORDERS_ROUTER_BASE.ORDERS_APPROVAL}`,
        icon: 'fact_check',
        label: 'common.orders.title_approval',
      },
      {
        id: 'media_pulse_orders',
        permissions: { ids: [Permission.BROWSE_MEDIA_PULSE_ORDERS] },
        route: ORDERS_ROUTER_BASE.ORDERS_MEDIA_PULSE,
        icon: 'chrome_reader_mode',
        label: 'common.orders.media_pulse_title',
      },
      {
        id: 'shared_packs',
        permissions: { ids: [Permission.BROWSE_SHARED_PACKS] },
        route: '/shared-packs',
        icon: 'cloud_download',
        label: 'common.orders.shared_packs.title',
      },
      {
        id: 'maps',
        permissions: { ids: [Permission.BROWSE_ASSETS_ON_MAP] },
        route: '/maps',
        icon: 'map',
        label: 'pages.home.menu.maps',
      },
      {
        id: 'pathe_import',
        enabledFor: [patheUuid],
        permissions: { ids: [Permission.BROWSE_ASSETS] },
        route: '/pathe-import',
        icon: 'attach_email',
        label: 'pages.home.menu.pathe_import',
      },
      {
        id: 'preview_requests',
        enabledFor: ['salesforce'],
        permissions: { ids: [Permission.BROWSE_PREVIEW_REQUESTS] },
        route: '/preview-request',
        icon: 'request_page',
        label: 'pages.home.menu.preview_requests',
      },
      {
        id: 'billing_reports',
        permissions: { ids: [Permission.CREATE_REPORTS] },
        route: '/billing-reports',
        icon: 'account_balance_wallet',
        label: 'pages.home.menu.billing-report',
      },
    ],
  },
  {
    id: 'rights_management',
    label: 'pages.home.menu.rights_management',
    permissions: {
      ids: [Permission.BROWSE_RIGHTS_PARTNERS, Permission.BROWSE_RIGHTS_CONTRACTS, Permission.BROWSE_LICENSED_PACKAGES],
      comparison: 'some',
    },
    menuItems: [
      {
        id: 'rights_partners',
        permissions: { ids: [Permission.BROWSE_RIGHTS_PARTNERS] },
        route: `/${RIGHTS_PARTNERS_ROUTER_BASE.ROOT}`,
        icon: 'stars',
        label: 'pages.home.menu.rights_partners',
      },
      {
        id: 'rights_contracts',
        permissions: { ids: [Permission.BROWSE_RIGHTS_CONTRACTS] },
        route: `/${RIGHTS_CONTRACTS_ROUTER_BASE.ROOT}`,
        icon: 'copyright',
        label: 'pages.home.menu.rights_contracts',
      },
      {
        id: 'licensed_packages',
        permissions: { ids: [Permission.BROWSE_LICENSED_PACKAGES] },
        route: `/${LICENSE_PACKAGES_ROUTER_BASE.ROOT}`,
        icon: 'folder_special',
        label: 'pages.home.menu.licensed_packages',
      },
    ],
  },
  {
    id: 'workflow',
    label: 'pages.home.menu.workflow',
    permissions: { ids: [Permission.BROWSE_ASSETS_ARCHIVE], comparison: 'some' },
    menuItems: [
      {
        id: 'assets_archive',
        permissions: { ids: [Permission.BROWSE_ASSETS_ARCHIVE] },
        route: '/browse/archive',
        queryParams: { query: 'stage:1' },
        icon: 'video_library',
        label: 'pages.home.menu.archive',
      },
    ],
  },
  {
    id: 'settings_upper',
    label: 'pages.home.menu.settings_upper',
    permissions: {
      ids: [
        Permission.BROWSE_DELIVERY_DESTINATIONS,
        Permission.BROWSE_USERS,
        Permission.BROWSE_SUPPLIERS,
        Permission.BROWSE_GROUPS,
        Permission.BROWSE_TEAMS,
        Permission.BROWSE_POLICIES,
        Permission.BROWSE_DASHBOARDS,
        Permission.IMPORT_CSV,
        Permission.BROWSE_STATS,
        Permission.BROWSE_RATINGS,
        Permission.BROWSE_FIELDS,
        Permission.EDIT_FIELDS,
        Permission.USER_ADMIN_BROWSE,
      ],
      comparison: 'some',
    },
    menuItems: [
      {
        id: 'delivery_destinations',
        permissions: { ids: [Permission.BROWSE_DELIVERY_DESTINATIONS] },
        route: '/delivery-destinations',
        icon: 'local_shipping',
        label: 'pages.home.menu.delivery_destinations',
      },
      {
        id: 'users',
        permissions: { ids: [Permission.BROWSE_USERS] },
        route: '/users',
        icon: 'person',
        label: 'pages.home.menu.users',
      },
      {
        id: 'client_users',
        permissions: { ids: [Permission.USER_ADMIN_BROWSE] },
        route: '/client-users',
        icon: 'person',
        label: 'pages.home.menu.client_users',
      },
      {
        id: 'client_teams',
        permissions: { ids: [Permission.BROWSE_TEAMS] },
        route: '/client-teams',
        icon: 'group_work',
        label: 'pages.home.menu.client_teams',
      },
      {
        id: 'suppliers',
        permissions: { ids: [Permission.BROWSE_SUPPLIERS] },
        route: '/suppliers',
        icon: 'people',
        label: 'pages.home.menu.suppliers',
      },
      {
        id: 'clients',
        permissions: { ids: [Permission.BROWSE_GROUPS] },
        route: '/clients',
        icon: 'groups',
        label: 'pages.home.menu.clients',
      },
      {
        id: 'policies',
        permissions: { ids: [Permission.BROWSE_POLICIES] },
        route: '/policies',
        icon: 'policy',
        label: 'pages.home.menu.policies',
      },
      {
        id: 'dashboards',
        permissions: { ids: [Permission.BROWSE_DASHBOARDS] },
        route: '/dashboards',
        icon: 'dashboard',
        label: 'pages.home.menu.dashboards',
      },
      {
        id: 'import_csv',
        permissions: { ids: [Permission.IMPORT_CSV] },
        route: '/import-csv',
        icon: 'import_export',
        label: 'pages.home.menu.import',
      },
      {
        id: 'storage-stats',
        permissions: { ids: [Permission.BROWSE_STATS] },
        route: '/storage-dashboard',
        icon: 'query_stats',
        label: 'pages.home.menu.stats',
      },
      {
        id: 'egress-stats',
        permissions: { ids: [Permission.BROWSE_STATS] },
        route: '/egress-dashboard',
        icon: 'timeline',
        label: 'pages.home.menu.egress-stats',
      },
      {
        id: 'ratings',
        permissions: { ids: [Permission.BROWSE_RATINGS] },
        route: '/ratings',
        icon: 'trending_up',
        label: 'pages.home.menu.ratings',
      },
      {
        id: 'systems',
        permissions: { ids: [Permission.BROWSE_RATINGS] },
        route: '/systems',
        icon: 'dvr',
        label: 'pages.home.menu.systems',
      },
      {
        id: 'fields',
        permissions: { ids: [Permission.BROWSE_FIELDS, Permission.EDIT_FIELDS], comparison: 'every' },
        route: '/fields-editor',
        icon: 'select_all',
        label: 'pages.home.menu.fields',
      },
      {
        id: 'library_localization',
        permissions: { ids: [Permission.BROWSE_CATALOG_ITEMS] },
        route: '/library/localization',
        icon: 'translate',
        label: 'pages.home.menu.library_localization',
      },
    ],
  },
  {
    id: 'app_support_upper',
    label: 'pages.home.menu.app_support_upper',
    menuItems: [
      {
        id: 'support',
        permissions: { ids: [Permission.CREATE_SUPPORT_REQUESTS] },
        href: supportCfg?.vidaSupportButton?.url ?? supportDefault.url,
        icon: supportCfg?.vidaSupportButton?.icon ?? supportDefault.icon,
        label: supportCfg?.vidaSupportButton?.label ?? supportDefault.label,
        enabledFor: ['vidaSupport'],
      },
      {
        id: 'support_external',
        permissions: { ids: [Permission.CLIENT_EXTERNAL_SUPPORT] },
        href: supportCfg?.thirdPartySupport?.url,
        icon: supportCfg?.thirdPartySupport?.icon,
        label: supportCfg?.thirdPartySupport?.label,
        confirmation: supportCfg?.thirdPartySupport?.confirmationText,
        enabledFor: ['thirdPartySupport'],
      },
      {
        id: 'release_notes',
        route: '/release-notes',
        icon: 'new_releases',
        label: 'pages.home.menu.release_notes',
      },
    ],
  },
];
