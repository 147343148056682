import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEmpty',
  standalone: true,
})
export class IsEmptyPipe implements PipeTransform {
  transform(value: string | number | string[] | number[] | undefined | unknown): boolean {
    return value == null || (Array.isArray(value) && value.length === 0);
  }
}
