import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ParamMap,
  Params,
  Resolve,
  ResolveStart,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  AssetSearchService,
  FilterDateRange,
  FilterRange,
  Filters,
  FilterTimecodeRange,
  FilterValue,
  PageOptions,
  PersistenceSearchParams,
  PersistenceSearchQuery,
  SortOptions,
} from '@vdms-hq/api-contract';
import { AuthService } from '@vdms-hq/auth';
import { ClientAccessChecker } from '@vdms-hq/clients';
import { fieldDefinitions, getFieldDefById } from '@vdms-hq/fields';
import {
  FieldDefinitionModel,
  FilterDefinitionModel,
  filterEmpty,
  FilterType,
  ResourceModel,
  ResultDefinitionModel,
} from '@vdms-hq/shared';
import { Timecode } from '@vdms-hq/timecode';
import { omit } from 'lodash';
import moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { parse, SearchParserOptions, stringify } from 'search-query-parser';
import { SearchResultsConfigFieldsService } from './search-results-config-fields.service';

type ParsedRouteValue = string | { from?: string; to?: string };

@Injectable({
  providedIn: 'root',
})
export class BrowseRouterService implements Resolve<PersistenceSearchParams> {
  private BASE_SEARCH_PATH = '/browse';

  private assetSearchService = inject(AssetSearchService);
  private router = inject(Router);
  private searchResultsConfigFieldsService = inject(SearchResultsConfigFieldsService);
  private location = inject(Location);
  private authService = inject(AuthService);
  private activatedClientService = inject(ActivatedClientService);
  private clientAccessChecker = inject(ClientAccessChecker);

  #listeners?: Subscription;

  readonly #keywords = fieldDefinitions
    .filter((field) => field.resource.includes(ResourceModel.ASSET_BROWSE))
    .filter((field) => field.filters?.validFormat === 'keyword')
    .map((item) => item.id);

  readonly #ranges = fieldDefinitions.filter((field) => field.filters?.validFormat === 'range').map((item) => item.id);

  #parseOptions: SearchParserOptions = {
    keywords: this.#keywords,
    ranges: this.#ranges,
    offsets: false,
    tokenize: false,
  };
  #visitedBefore = false;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersistenceSearchParams> {
    return this.#fromRoute(route.queryParamMap, state.url).pipe(
      switchMap((persistenceQueryParams) => {
        if (this.#visitedBefore) {
          return of(persistenceQueryParams);
        }
        this.#visitedBefore = true;
        return this.clientAccessChecker.checkAccess(persistenceQueryParams).pipe(
          filter((hasAccess) => hasAccess),
          map(() => persistenceQueryParams),
        );
      }),
      tap((persistenceQueryParams) => {
        this.assetSearchService.applyParams(persistenceQueryParams);
      }),
    );
  }

  registerListeners = () => {
    if (this.#listeners) {
      return;
    }
    this.#listeners = this.assetSearchService.currentParams$
      .pipe(
        withLatestFrom(
          this.router.events.pipe(
            filter((e) => e instanceof ResolveStart),
            map((e) => (e as ResolveStart).url),
          ),
        ),
        switchMap(([persistence, targetUrl]) => this.#toRoute(persistence).pipe(withLatestFrom(of(targetUrl)))),
      )
      .subscribe(([queryParams, targetUrl]) => {
        const parsedUrl = this.router.parseUrl(targetUrl);
        parsedUrl.queryParams = {};
        parsedUrl.fragment = null;
        const sliced = parsedUrl.toString().split('/').slice(0, 2).join('/');
        const isBrowseUrl = sliced === this.BASE_SEARCH_PATH;

        if (isBrowseUrl) {
          const parsedTargetUrl = this.router.parseUrl(targetUrl);
          parsedTargetUrl.queryParams = {};
          parsedTargetUrl.fragment = null;
          const urlTree = this.router.createUrlTree([parsedTargetUrl.toString()], {
            queryParams,
          });

          return this.location.go(urlTree.toString());
        } else {
          return this.router.navigate([this.BASE_SEARCH_PATH], {
            queryParams,
          });
        }
      });
  };

  #toRoute(persistence: PersistenceSearchParams): Observable<Params> {
    return this.searchResultsConfigFieldsService.filters$.pipe(
      withLatestFrom(this.activatedClientService.clientIdDefinite$),
      map(([filters, selectedClient]) => {
        const values: Record<string, ParsedRouteValue> = {};

        Object.entries(persistence.query.keyValues).forEach(([key, value]) => {
          const filterDef = filters.find((def) => def.id === key);
          if (filterDef) {
            values[key] = this.#valueToRoute(value, filterDef);
          }
        });

        if (persistence.query.text) {
          values['text'] = persistence.query.text;
        }

        const query = stringify(values, this.#parseOptions);

        return {
          perPage: persistence.perPage,
          page: persistence.page,
          sortBy: persistence.sortBy?.id,
          sortDirection: persistence.sortDirection,
          query: query ? query.replace(/'/g, '%27').replace(/"/g, '%22') : undefined,
          clientId: persistence['clientId'] || selectedClient,
        };
      }),
    );
  }

  #fromRoute(paramsMap: ParamMap, url: string): Observable<PersistenceSearchParams> {
    return this.searchResultsConfigFieldsService.filters$.pipe(
      withLatestFrom(this.activatedClientService.clientIdDefinite$),
      switchMap(([allDefinitions, selectedClient]) =>
        this.#getPageOptions(paramsMap).pipe(
          map((pageOptions) => ({
            pageOptions,
            allDefinitions,
            selectedClient,
          })),
        ),
      ),
      map(({ pageOptions, allDefinitions, selectedClient }) => {
        let hiddenFilters: Filters = {};
        if (url.includes('browse/quarantined')) {
          this.BASE_SEARCH_PATH = '/browse/quarantined';
          hiddenFilters = {
            isQuarantined: { filterDef: getFieldDefById('isQuarantined') as FilterDefinitionModel, value: 1 },
          };
        } else if (url.includes('browse/deleted')) {
          this.BASE_SEARCH_PATH = '/browse/deleted';
          hiddenFilters = {
            isDeleted: { filterDef: getFieldDefById('isDeleted') as FilterDefinitionModel, value: 1 },
          };
        } else {
          this.BASE_SEARCH_PATH = '/browse';
          hiddenFilters = {
            isQuarantined: { filterDef: getFieldDefById('isQuarantined') as FilterDefinitionModel, value: 0 },
          };
        }

        const sortOptions = this.#getSortOptions(paramsMap, allDefinitions);
        const parsedQueryParams = parse(paramsMap.get('query') ?? '', this.#parseOptions);
        const queryUrl = (typeof parsedQueryParams === 'string' ? parsedQueryParams : paramsMap.get('query') ?? '')
          ?.replace(/(%27)/g, "'")
          .replace(/(%22)/g, '"');

        let params = parse(queryUrl, this.#parseOptions);
        if (typeof params !== 'string') {
          Object.entries(params).forEach(([key, value]: [string, string]) => {
            if (Array.isArray(value)) {
              params[key] = value.map((v) => {
                if (typeof v === 'string') {
                  return v.replace(/(%2C)/g, ',');
                }
              });
            }
            if (typeof value === 'string') {
              params[key] = value.replace(/(%2C)/g, ',');
            }
          });
        }

        if (typeof parsedQueryParams === 'string') {
          try {
            params = JSON.parse(queryUrl);
          } catch (e) {
            // JSON.parse crash when queryUrl is plain string.
            params = queryUrl;
          }
        }

        let queryParams: PersistenceSearchQuery;

        if (typeof params === 'string' && params !== '') {
          queryParams = PersistenceSearchQuery.fromFilters(params, {}, hiddenFilters);
        } else if (params) {
          const rawQueryParams: Record<string, string> = omit(params, 'exclude');
          const filters: Filters = {};

          Object.entries(rawQueryParams).forEach(([key, value]: [string, string]) => {
            const filterDef = allDefinitions.find((def) => def.id === key);
            if (filterDef && filterDef.filters) {
              filters[key] = {
                filterDef: filterDef as FilterDefinitionModel,
                value: this.#valueFromRoute(value, filterDef),
              };
            }
          });

          queryParams = PersistenceSearchQuery.fromFilters(rawQueryParams['text'], filters, hiddenFilters);
        } else {
          queryParams = PersistenceSearchQuery.fromFilters(undefined, undefined, hiddenFilters);
        }

        return new PersistenceSearchParams(
          queryParams,
          pageOptions,
          sortOptions,
          undefined,
          false,
          paramsMap.get('clientId') || selectedClient,
        );
      }),
    );
  }

  #valueToRoute(value: FilterValue, definition: FieldDefinitionModel): ParsedRouteValue {
    switch (definition.filters?.type) {
      case FilterType.BITRATE_RANGE:
      case FilterType.SIZE_RANGE:
        value = value as FilterRange;
        return {
          from: value?.['from'] ? String(value['from']) : undefined,
          to: value?.['to'] ? String(value['to']) : undefined,
        };
      case FilterType.DATEPICKER_RANGE:
        value = value as FilterDateRange;
        return {
          from: value?.['from'] ? String(value['from']?.unix()) : undefined,
          to: value?.['to'] ? String(value['to']?.unix()) : undefined,
        };
      case FilterType.TIMECODE_RANGE:
        value = value as FilterTimecodeRange;
        return {
          from: value?.['from'] ? value['from']?.toString() : undefined,
          to: value?.['to'] ? value['to']?.toString() : undefined,
        };
    }

    if (Array.isArray(value)) {
      value = value.map((v) => {
        if (typeof v === 'string') {
          return v.replace(/,/g, '%2C');
        }
      });
    }
    const newValue = Array.isArray(value) ? value.join(',') : String(value);

    if (!newValue) {
      return 'null';
    } else {
      return newValue;
    }
  }

  #valueFromRoute(value: ParsedRouteValue, definition: FieldDefinitionModel): FilterValue {
    switch (definition.filters?.type) {
      case FilterType.NUMBER:
        return Number(value);
      case FilterType.SIZE_RANGE:
      case FilterType.BITRATE_RANGE:
        return {
          from: this.#toNumber(value['from']) ?? undefined,
          to: this.#toNumber(value['to']) ?? undefined,
        };
      case FilterType.DATEPICKER_RANGE:
        return {
          from: value?.['from'] ? moment.unix(value['from']) : undefined,
          to: value?.['to'] ? moment.unix(value['to']) : undefined,
        };
      case FilterType.TIMECODE_RANGE:
        return {
          from: value?.['from'] ? Timecode.fromTimecode(value['from'], undefined, false) : undefined,
          to: value?.['to'] ? Timecode.fromTimecode(value['to'], undefined, false) : undefined,
        };
      case FilterType.CHECKBOX_LIST:
        return Array.isArray(value) ? value : ([value] as string[]);
      case FilterType.SELECTOR:
      case FilterType.CHIPS:
      case FilterType.SELECTOR_GROUP:
        if (value === 'null') {
          value = '';
        }
        return (Array.isArray(value) ? value : [value]) as string[];
      case FilterType.MASTER_TEXT:
      case FilterType.TEXT:
      case FilterType.TEXT_AUTOCOMPLETE:
        return value as string;
    }
  }

  #toNumber(param?: string | number | null): number | null {
    if (param === undefined || param === null) {
      return null;
    }

    if (isNaN(Number(param))) {
      return null;
    }

    return Number(param);
  }

  #getPageOptions(paramsMap: ParamMap): Observable<PageOptions> {
    return this.authService.userAttributes$.pipe(
      filterEmpty(),
      take(1),
      map((userAttributes) => {
        const pageOptions: PageOptions = {
          perPage: userAttributes.vida?.preferredPageSize ?? PersistenceSearchParams.defaultPerPage,
          page: PersistenceSearchParams.defaultPage,
        };

        const perPage = this.#toNumber(paramsMap.get('perPage'));
        const page = this.#toNumber(paramsMap.get('page'));

        if (page !== undefined && page !== null) {
          pageOptions.page = Math.max(0, page);
        }
        if (perPage !== undefined && perPage !== null) {
          pageOptions.perPage = Math.max(0, Math.min(perPage, 300));
        }

        return pageOptions;
      }),
    );
  }

  #getSortOptions(paramsMap: ParamMap, resultsDefinitions: FieldDefinitionModel[]): SortOptions | undefined {
    const sortBy = (paramsMap.get('sortBy') ?? '').trim();
    const sortDirection = (paramsMap.get('sortDirection') ?? '').trim();

    if (!sortBy) {
      return;
    }

    const definition = resultsDefinitions.find((def) => def.id === sortBy);
    if (!definition || !definition?.results2?.sortable || !definition?.results2) {
      return;
    }
    return {
      field: definition as ResultDefinitionModel,
      direction: sortDirection.toLowerCase() === 'asc' ? 'asc' : 'desc',
    };
  }
}
