import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable, takeUntil } from 'rxjs';
import { Filters } from '@vdms-hq/api-contract';
import { FilterDefinitionModel, FilterType, ResourceModel } from '@vdms-hq/shared';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService, SelectorSourceType } from '@vdms-hq/selectors';
import { SingleCollectionAssetsDataSource } from '../datasources/single-collection-assets-ds.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { take, tap } from 'rxjs/operators';
import moment from 'moment-timezone';
import { getFieldDefById } from '@vdms-hq/fields';

export interface AssetFiltersForm {
  [key: string]: any;

  text?: string;
  txDate?: {
    from?: moment.Moment;
    to?: moment.Moment;
  };
  facilityOfOrigin?: string;
}

@Injectable({ providedIn: 'root' })
export class CollectionAssetsFilterService {
  appliedFilters$ = new BehaviorSubject<Filters>({});

  config?: FilterDefinitionModel[];

  constructor(
    public collectionAssetDataSource: SingleCollectionAssetsDataSource,
    private translationService: TranslateService,
    private dataProvider: DataProviderService,
  ) {}

  form = new UntypedFormGroup({
    text: new UntypedFormControl(),
    txDate: new UntypedFormControl(),
    facilityOfOrigin: new UntypedFormControl(),
  });

  listenUntil = (subject: Observable<void>) => {
    this.form.valueChanges
      .pipe(
        debounceTime(400),
        takeUntil(subject),
        tap((filters) => {
          this.collectionAssetDataSource.applyFilters(filters);
        }),
      )
      .subscribe(() => {
        this.#setAppliedFilters();
      });
  };

  #setAppliedFilters() {
    const filtersToApply: Filters = {};

    this.config?.forEach((filterDef) => {
      const filterDefId = filterDef.id as keyof AssetFiltersForm;
      const value = this.form.value[filterDefId];

      if (value) {
        filtersToApply[filterDef.id] = {
          filterDef,
          value,
        };
      }
    });

    this.appliedFilters$.next(filtersToApply);
  }

  initConfig() {
    if (this.config) {
      return;
    }

    this.dataProvider
      .listForSelectors(SelectorSourceType.FACILITY_OF_ORIGIN)
      .pipe(take(1))
      .subscribe((facilityOriginOptions) => {
        this.config = [
          {
            id: 'text',
            label: 'Keywords',
            filters: {
              objectPath: 'text',
              validFormat: 'keyword',
              type: FilterType.MASTER_TEXT,
            },
          },
          getFieldDefById('txDate'),
          {
            ...getFieldDefById('facilityOfOrigin'),
            selectOptions: facilityOriginOptions,
          },
        ].map((config) => ({
          ...config,
          label: this.translationService.instant(config.label),
        })) as FilterDefinitionModel[];
      });
  }
}
