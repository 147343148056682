import { inject, Injectable } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import {
  LICENSED_PACKAGE_STATUS,
  LicensedPackageFiltersView,
  LicensedPackageListItem,
  LicensePackagesService,
  PaginationAPIModel as Pagination,
} from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { HttpErrorResponse } from '@angular/common/http';

export interface LicensePackagesAddData {
  key: string;
  label: string;
  number_of_assets: number;
  status: LICENSED_PACKAGE_STATUS;
}

export type LicensedPackageAddFiltersView = {
  keyword: FormControl<string>;
  sort: FormControl<string | null>;
};

@Injectable({ providedIn: 'root' })
export class RightsContractsLicensePackagesDsService
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<LicensedPackageAddFiltersView>, LicensedPackageListItem>
{
  private licensePackagesService = inject(LicensePackagesService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  refresh$ = new BehaviorSubject<number>(Date.now());
  isLoading$ = new BehaviorSubject<boolean>(true);

  filters = new FormGroup({
    keyword: new FormControl<string>('', { nonNullable: true }),
    sort: new FormControl<string | null>('licensedPackage.createdAt_desc'),
  });

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.filters.valueChanges.pipe(
      startWith(this.filters.value),
      debounceTime(300),
      switchMap(() => {
        const formValue = this.filters.value;
        const filters = <LicensedPackageFiltersView>{};

        if (formValue?.keyword) {
          filters.keyword = formValue.keyword;
        }
        if (formValue?.sort) {
          filters.sort = formValue.sort.split('_')[0];
          filters.direction = formValue.sort.split('_')[1];
        }
        this.changePageIndex$.next(0);
        return of(filters);
      }),
    ),
    this.activatedClientService.clientIdDefinite$,
  ]).pipe(
    switchMap(([, page, perPage, filters]) => {
      const headers = Pagination.create({
        page,
        perPage,
      });
      return this.licensePackagesService.getMany(headers, filters);
    }),
    shareReplay(1),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<LicensePackagesAddData[]> = this.responseData$.pipe(
    tap(() => this.isLoading$.next(true)),
    catchError((error) => {
      this.#errorHandler(error);
      throw error;
    }),
    map((response) =>
      response.data.map((item) => {
        return {
          key: item.uuid,
          label: item.name,
          status: item.status,
          number_of_assets: item.number_of_assets,
        };
      }),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<LicensedPackageAddFiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh(force: boolean) {
    if (this.filters.dirty || this.changePageIndex$.value !== 0 || force) {
      this.filters.controls['keyword'].setValue('');
      this.changePageIndex$.next(0);
    }
  }

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'titlePackages', message: 'Error fetching title packages' });
    this.isLoading$.next(false);
    this.emptyResults$ = of(true);
    console.error(err);
    return EMPTY;
  }
}
