import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Injector, OnInit } from '@angular/core';
import { FormControlValueAccessorComponent } from '@vdms-hq/ui';
import { FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldsConfigService } from '@vdms-hq/fields';
import { FormBuilderService } from '@vdms-hq/dynamic-form';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

type OutsideValue = string | undefined;

type CustomValue = string | number | Date | CustomValue[];
type Sibling = {
  key: string;
  value: CustomValue;
};

type InsideValue = {
  siblings: Sibling[];
};

@Component({
  selector: 'vdms-hq-selectors-select-options-edit-extra',
  templateUrl: './select-options-edit-extra.component.html',
  styleUrls: ['./select-options-edit-extra.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectOptionsEditExtraComponent),
    },
  ],
})
export class SelectOptionsEditExtraComponent
  extends FormControlValueAccessorComponent<OutsideValue, InsideValue>
  implements OnInit
{
  innerFormControl = new FormGroup({
    siblings: new FormArray([SelectOptionsEditExtraComponent.createCustomParent()]),
  });

  allFieldsSelectOptions$ = this.fields.inputDefinitions$.pipe(
    tap((fields) => {
      this.formBuilder.set(fields);
      this.formBuilder.unlock();
    }),
    map((fields) => fields.filter((field) => !field.input.readonly)),
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );

  static createCustomParent(value?: Partial<Sibling>) {
    return new FormGroup({
      key: new FormControl<Sibling['key'] | ''>(value?.key ?? ''),
      value: new FormControl<Sibling['value'] | ''>(value?.value ?? ''),
    });
  }

  constructor(
    private fields: FieldsConfigService,
    injector: Injector,
    changeDetectorRef: ChangeDetectorRef,
    private formBuilder: FormBuilderService,
  ) {
    super(injector, changeDetectorRef);
  }

  get siblings() {
    return this.innerFormControl.controls.siblings;
  }

  ngOnInit() {
    super.ngOnInit();
    this.formBuilder.unlock();
  }

  addSibling() {
    this.siblings.push(SelectOptionsEditExtraComponent.createCustomParent());
  }

  removeSibling(index: number) {
    this.siblings.removeAt(index);
  }

  transformInnerToOuter(value: InsideValue): OutsideValue {
    const filtered = {
      siblings: value.siblings.filter(({ key, value }) => !!key && !!value),
    };

    return JSON.stringify(filtered);
  }

  transformOuterToInner(value: OutsideValue): InsideValue {
    if (!value) {
      return {
        siblings: [],
      };
    }

    return JSON.parse(value);
  }

  writeValue(value: OutsideValue): void {
    if (!value) return;
    this.removeSibling(0);

    this.outerValue = value;
    const inner = this.transformOuterToInner(value);
    (inner.siblings ?? []).forEach((item) => {
      this.siblings.push(SelectOptionsEditExtraComponent.createCustomParent(item));
    });
    this.innerFormControl.patchValue(inner);
  }
}
