import { Component, inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssetAsperaPost } from '@vdms-hq/api-contract';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SelectorsModule, SelectorSourceType } from '@vdms-hq/selectors';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'vdms-hq-storage-embargo-selector-dialog',
  templateUrl: './embargo-selector-dialog.component.html',
  styleUrls: ['./embargo-selector-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    TranslateModule,
    UIDialogWrapperModule,
    ReactiveFormsModule,
    UIFormModule,
    SelectorsModule,
  ],
})
export class EmbargoSelectorDialogComponent implements OnInit {
  public data = inject<{ files: AssetAsperaPost }>(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef<EmbargoSelectorDialogComponent>);

  form = new FormGroup({
    files: new FormArray([]),
  });
  readonly SelectorSourceType = SelectorSourceType;

  get files(): FormArray<FormGroup> {
    return this.form.controls['files'] as unknown as FormArray<FormGroup>;
  }

  ngOnInit(): void {
    this.data?.files?.files.forEach((file) =>
      this.files.push(
        new FormGroup({
          originalFilename: new FormControl(file.originalFilename),
          embargoed_to: new FormControl(null, Validators.required),
        }),
      ),
    );
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    const payload = this.data.files.files;
    this.files.controls.forEach((control) => {
      const index = payload.findIndex((file) => file.originalFilename === control.value['originalFilename']);
      payload[index].embargoed_to = control.value['embargoed_to'];
    });
    this.dialogRef.close(payload);
  }
}
