<vdms-hq-ui-loader *ngIf="(state$ | async) === 'loading'"></vdms-hq-ui-loader>

<div class="error">
  <vdms-hq-ui-empty-results icon="error" message="This took too long" *ngIf="(state$ | async) === 'tooLongWait'">
    We didn't receive a response from YouTube. This may be due to a slow connection or an issue with YouTube. Please
    ensure you have the correct url.
  </vdms-hq-ui-empty-results>

  <vdms-hq-ui-empty-results icon="error" message="Error connecting to YouTube" *ngIf="(state$ | async) === 'error'">
    An error occurred while trying to connect YouTube with vida. You will be redirected to the home page soon.
  </vdms-hq-ui-empty-results>
</div>
