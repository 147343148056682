import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import {
  FlatMediaPulseOrder,
  FlatOrder,
  OrderAssetFilters,
  OrderEndpoints,
  OrderModel,
  OrderModelPost,
  OrderModelResponse,
  OrderRoot,
  OrdersExportMetadataPost,
  OrdersFilters,
  OrderConfirmationPdfResponse,
  OrderConfirmationPdfRequest,
  ManyOrderConfirmationPdfResponse,
  PostAsperaDownloadStatus,
} from './order.model';
import { HttpHeaders } from '@angular/common/http';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import {
  OrderAssetModel,
  OrderDownloadPayload,
  OrderPatchItemModel,
  RetryDeliveryDestination,
  RetryDeliveryDestinationJob,
} from './ordered-asset.model';
import { GetResponseData } from '../../operators/get-response-data.operator';
import { DataSelector, ResultDefinitionModel } from '@vdms-hq/shared';
import { AsperaDownloadableFile } from '../aspera/aspera.model';
import { PaginationAPIModel as Pagination, PaginationAPIProps as PaginationProps } from '../pagination.model';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(private readonly apiService: ApiService) {}

  submitOrder = (order: OrderModelPost): Observable<OrderModelResponse> =>
    this.apiService.post<OrderModelPost, OrderModelResponse>(`${OrderRoot.ROOT}/${OrderEndpoints.CHECKOUT}`, order);

  getOrder(uuid: string) {
    return this.apiService.get<ApiResponse<OrderModel>>(`${OrderRoot.ROOT}/` + uuid).pipe(GetResponseData);
  }

  reDeliveryEmails(orderUuid: string) {
    return this.apiService
      .get<ApiResponse<string[]>>(`${OrderRoot.ROOT}/${orderUuid}/redelivery-emails`)
      .pipe(GetResponseData);
  }

  reDeliverClips(orderUuid: string, emails: string[]) {
    return this.apiService
      .post<
        {
          emails: string[];
        },
        ApiResponse<OrderModel>
      >(`${OrderRoot.ROOT}/${orderUuid}/redeliver-clips`, { emails })
      .pipe(GetResponseData);
  }

  approveOrder(uuid: string): Observable<OrderModel> {
    return this.apiService
      .patch<unknown, ApiResponse<OrderModel>>(`${OrderRoot.ROOT}/${uuid}`, {
        status: 'approved',
      })
      .pipe(GetResponseData);
  }

  updateAssets(uuid: string, assets: OrderPatchItemModel[]) {
    return this.apiService.patch(`${OrderRoot.ROOT}/${uuid}/assets`, { assets });
  }

  salesForceUnlockRequest(uuid: string) {
    return this.apiService.patch(`${OrderRoot.ROOT}/${uuid}`, { sf_unlock: true });
  }

  retriggerClipping(
    uuid: string,
    payload: {
      items: string[];
    },
  ) {
    return this.apiService.post(`${OrderRoot.ROOT}/${uuid}/retrigger-clipping`, payload);
  }

  getOrders(filters: OrdersFilters, pagination?: Pagination): Observable<ApiPaginatedResponse<FlatOrder>> {
    let headers = new HttpHeaders();

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    return this.apiService.get<ApiPaginatedResponse<FlatOrder>>(OrderRoot.ROOT_LIST, { headers });
  }

  export(exportFieldDefs: ResultDefinitionModel[], filters: OrdersFilters, pagination?: Pagination) {
    let headers: HttpHeaders = new HttpHeaders({ accept: 'text/csv', 'content-type': 'text/csv' });
    headers = pagination ? pagination?.applyToHeaders(headers) : headers;
    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    const fields = exportFieldDefs.map((column) => ({
      value: column.results2.exportObjectPath as string,
      label: column.label,
    }));

    return this.apiService
      .post<OrdersExportMetadataPost, ApiResponse<string>>(`${OrderRoot.ROOT_LIST}/export`, { fields }, headers)
      .pipe(GetResponseData);
  }

  getOrdersMediaPulse(
    filters: OrdersFilters,
    pagination?: Pagination,
  ): Observable<ApiPaginatedResponse<FlatMediaPulseOrder>> {
    let headers = new HttpHeaders();

    headers = pagination ? pagination?.applyToHeaders(headers) : headers;

    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    return this.apiService.get<ApiPaginatedResponse<FlatMediaPulseOrder>>(OrderRoot.ROOT_MEDIA_PULSE, { headers });
  }

  public getAssets(
    orderId: string,
    pagination?: PaginationProps,
    filters?: Partial<OrderAssetFilters>,
  ): Observable<ApiPaginatedResponse<OrderAssetModel>> {
    let headers = Pagination.create(pagination).applyToHeaders(new HttpHeaders()).append('Cache-Control', 'max-age=0');

    Object.entries(filters || {})
      .filter(([, value]) => value !== null && value !== undefined)
      .forEach(([key, value]) => (headers = headers.append(key, value)));

    const url = `${OrderRoot.ROOT}/${orderId}/assets`;

    return this.apiService.get<ApiPaginatedResponse<OrderAssetModel>>(url, { headers });
  }

  downloadAssets<T = AsperaDownloadableFile>(orderId: string, payload: OrderDownloadPayload) {
    const url = `${OrderRoot.ROOT}/${orderId}/download`;
    return this.apiService.post<OrderDownloadPayload, ApiResponse<T>>(url, payload);
  }

  downloadWithAspera(token: string): Observable<ApiResponse<AsperaDownloadableFile>> {
    return this.apiService.get<ApiResponse<AsperaDownloadableFile>>(`${OrderRoot.ROOT}/aspera-download/${token}`);
  }

  downloadClearance(url: string) {
    window.open(url);
  }

  retryDeliveryDestination(uuid: string, payload: RetryDeliveryDestination): Observable<RetryDeliveryDestinationJob[]> {
    return this.apiService.post(`${OrderRoot.ROOT}/${uuid}/retry-dd-jobs`, payload);
  }

  getDeliveryConfirmationPdf = (orderUuid: string) => {
    return this.apiService
      .post<undefined, ApiResponse<OrderConfirmationPdfResponse>>(
        `${OrderRoot.ROOT}/${orderUuid}/${OrderEndpoints.DELIVERY_CONFIRMATION}`,
        undefined,
      )
      .pipe(GetResponseData);
  };

  getManyDeliveryConfirmationPdf = (orderUuids: OrderConfirmationPdfRequest) => {
    return this.apiService
      .post<OrderConfirmationPdfRequest, ApiResponse<ManyOrderConfirmationPdfResponse>>(
        `${OrderRoot.ROOT}/${OrderEndpoints.MANY_DELIVERY_CONFIRMATION}`,
        orderUuids,
      )
      .pipe(GetResponseData);
  };

  sendOrderDownloadStatus = (downloadStatus: PostAsperaDownloadStatus) => {
    return this.apiService
      .post<PostAsperaDownloadStatus, ApiResponse<unknown>>(
        `${OrderRoot.ROOT}/${OrderEndpoints.DOWNLOAD_STATUS}`,
        downloadStatus,
      )
      .pipe(GetResponseData);
  };
}
