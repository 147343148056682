<ng-container *ngIf="hideUsersList; else showUsersList">
  <h3 [style.color]="(userEmail$ | async) !== shareCreate.value ? '#fff' : '#BD2B3C'">
    {{ (userEmail$ | async) !== shareCreate.value ? ('common.users.share' | translate) : "Can't share with yourself" }}
  </h3>
  <div class="search-action">
    <vdms-hq-ui-form-input-text
      [formControl]="shareCreate"
      [placeholder]="'common.users.add_hint' | translate"
      [label]="'common.users.add_placeholder' | translate"
    >
    </vdms-hq-ui-form-input-text>
    <ng-container *ngIf="!previewOnly">
      <ng-container *ngIf="noUserFound; else addUser">
        <vdms-hq-ui-button
          *requiresPermissions="[Permission.CREATE_USERS]"
          (click)="onNewCreate()"
          color="primary"
          [loading]="userSearching"
          [disabled]="!shareCreate.valid"
          >{{ 'common.users.create' | translate }}
        </vdms-hq-ui-button>
      </ng-container>
      <ng-template #addUser>
        <vdms-hq-ui-button
          (click)="addToList()"
          icon="add"
          color="primary"
          [loading]="userSearching"
          [disabled]="(userEmail$ | async) === shareCreate.value && !shareCreate.valid"
          >{{ 'common.users.add_user' | translate }}
        </vdms-hq-ui-button>
      </ng-template>
    </ng-container>
  </div>
  <mat-divider></mat-divider>
  <h3 class="mt-2">{{ 'common.users.authorized_list' | translate }}</h3>
  <vdms-hq-ui-form-input-text
    [formControl]="usersSearch"
    [placeholder]="'common.users.search_hint' | translate"
    [label]="'common.users.search_placeholder' | translate"
    [fallbackSuffixIcon]="'search'"
  >
  </vdms-hq-ui-form-input-text>
  <div class="users-list">
    <vdms-hq-ui-empty-results
      *ngIf="isFilteredEmpty$ | async"
      [type]="'compact'"
      [message]="'No users found'"
    ></vdms-hq-ui-empty-results>
    <mat-chip-list class="material-custom-chip-input">
      <mat-chip
        *ngFor="let user of filteredOptions$ | async"
        [selectable]="false"
        [removable]="true"
        (removed)="removeChip(user)"
        class="users-chip"
      >
        {{ user.label }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
</ng-container>

<ng-template #showUsersList>
  <vdms-hq-ui-data-selector
    [searchDebounceEnabled]="true"
    [dataSource]="dataSource"
    [showSelectAll]="false"
    [hidePageSize]="true"
    [isShareSelector]="true"
    [previewOnly]="previewOnly"
    [userEmail]="(userEmail$ | async) ?? ''"
  >
    <ng-container *ngIf="!previewOnly" addNew>
      <vdms-hq-ui-button
        *requiresPermissions="[Permission.CREATE_USERS]"
        (click)="onNewCreate()"
        color="primary"
        [disabled]="(userEmail$ | async) === shareCreate.value"
        >{{ 'common.users.create' | translate }}
      </vdms-hq-ui-button>
    </ng-container>
  </vdms-hq-ui-data-selector>
</ng-template>
