<div class="selectable-view" [class.one-column]="!listViewShow.value">
  <div class="selectable-view-list" [class.search-view]="!listViewShow.value">
    <ng-container *ngIf="!listViewShow.value">
      <div class="flex flex-row justify-between align-items-center flex-gap-2 margin-block-1">
        <vdms-hq-ui-form-section-title
          [title]="config.searchView.label"
          [size]="'medium'"
          [topMargin]="0.5"
          [bottomMargin]="0.5"
        >
        </vdms-hq-ui-form-section-title>
        <vdms-hq-form-input-buttons-toggle
          [formControl]="listViewShow"
          (valueChange)="toggleSearchView()"
          [rightOptionLabel]="'List View'"
          [leftOptionLabel]="'Search View'"
          [hidePadding]="true"
        ></vdms-hq-form-input-buttons-toggle>
      </div>
      <div [formGroup]="dataSource.getFormGroup()">
        <ng-container>
          <mat-form-field appearance="outline" class="material-custom-field hide-footer">
            <input
              type="text"
              matInput
              [placeholder]="config.searchView.placeholder"
              [formControlName]="'keyword'"
              [matAutocomplete]="auto"
              class="mat-form-field"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <ng-container *ngFor="let tile of dataSource.connection$ | async">
                <ng-container *ngIf="config.tileConfig | disableIfRow : tile; let disabledRowState">
                  <mat-option
                    [accessKey]="tile.key"
                    (onSelectionChange)="selectedChange(tile)"
                    class="selectable-list-autocomplete-option"
                  >
                    <div class="autocomplete-option" [class.selected]="selected.includes(tile.key?.toString())">
                      <mat-icon [class.blocked]="disabledRowState.disabled">{{
                        disabledRowState.disabled ? 'block' : config.tileConfig.icon
                      }}</mat-icon>
                      <span
                        *ngIf="tile | objectValueByPath : config.tileConfig.label; let title"
                        class="autocomplete-option__title"
                        #titleContainer
                        [matTooltip]="title"
                        [matTooltipDisabled]="titleContainer.offsetWidth >= titleContainer.scrollWidth"
                        [matTooltipClass]="'selectable-title-tooltip'"
                        >{{ tile | objectValueByPath : config.tileConfig.label }}</span
                      >
                      <div class="autocomplete-option__metadata">
                        <ng-container *ngFor="let metadata of config.tileConfig.metadata">
                          <div *ngIf="!(metadata | metadataHidden : tile)" [class.w-full]="metadata.fullLine">
                            <span class="autocomplete-option__label" *ngIf="metadata.label"
                              >{{ metadata.label | translate }}:</span
                            >
                            <vdms-hq-ui-value-renderer
                              [value]="tile | objectValueByPath : metadata.valuePath"
                              [format]="metadata.viewFormat"
                            ></vdms-hq-ui-value-renderer>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="selectableListTemplateAddOns"></ng-container>
    </ng-container>
    <vdms-hq-ui-form-section-title
      [title]="config.selectedList.title + (selected.length ? ' (' + selected.length + ')' : '')"
      [size]="'medium'"
      [topMargin]="1"
    ></vdms-hq-ui-form-section-title>
    <vdms-hq-ui-form-input-text
      (keyup)="applyFilter($event)"
      [placeholder]="'common.global.filter' | translate"
      [withFooter]="true"
    ></vdms-hq-ui-form-input-text>
    <div class="selectable-view-list-container">
      <div class="selectable-view-list-container-item" *ngFor="let item of allData$ | async">
        <div class="selectable-view-list-container-item-title-status">
          <mat-icon *ngIf="config.tileConfig.icon">{{ config.tileConfig.icon }}</mat-icon
          ><span
            [class.truncated]="item.isNew || item.toRemove"
            [class.search-view]="listViewShow.value"
            #itemNameContainer
            [matTooltip]="item.name"
            [matTooltipDisabled]="itemNameContainer.offsetWidth >= itemNameContainer.scrollWidth"
            [matTooltipClass]="'selectable-title-tooltip'"
            >{{ item.name }}</span
          >
          <div class="pill done" *ngIf="item.isNew">New Added</div>
          <div class="pill failed" *ngIf="item.toRemove">To be removed</div>
        </div>
        <mat-icon (click)="removeItem(item)" class="item-remove">delete</mat-icon>
      </div>
      <vdms-hq-ui-empty-results
        class="data__results-empty"
        *ngIf="emptyResults$ | async"
        [message]="selected.length !== 0 ? 'No results found for provided filter' : 'Nothing added'"
        [icon]="config.tileConfig.icon"
      ></vdms-hq-ui-empty-results>
    </div>
  </div>
  <div class="selectable-view-datasource relative" *ngIf="listViewShow.value">
    <div class="flex flex-row justify-between align-items-center">
      <vdms-hq-ui-form-section-title
        *ngIf="config.datasourceTitle.length"
        [title]="config.datasourceTitle"
        [size]="'medium'"
      ></vdms-hq-ui-form-section-title>
      <vdms-hq-form-input-buttons-toggle
        [formControl]="listViewShow"
        (valueChange)="toggleSearchView()"
        [rightOptionLabel]="'List View'"
        [leftOptionLabel]="'Search View'"
        [hidePadding]="true"
      ></vdms-hq-form-input-buttons-toggle>
    </div>
    <vdms-hq-dynamic-form-group-renderer
      [form]="filters"
      [fieldsConfig]="config.fieldsConfig"
      [hasLoader]="hasLoader"
      [fullWidth]="true"
      [style.margin-top]="0"
    ></vdms-hq-dynamic-form-group-renderer>
    <ng-container *ngIf="dataSource.emptyResults$ && (dataSource.emptyResults$ | async) === false">
      <vdms-hq-ui-add-dialog-selectable-tiles
        [config]="config.tileConfig"
        [items]="dataSource.connection$ | async"
        [selected]="selected"
        [searchView]="true"
        (selectedChange)="selectedChange($event)"
      ></vdms-hq-ui-add-dialog-selectable-tiles>
      <mat-paginator
        [pageSizeOptions]="dataSource.pageSizeOptions"
        [length]="dataSource.total$ | async"
        [pageIndex]="dataSource.pageIndex$ | async"
        [pageSize]="dataSource.pageSize$ | async"
        (page)="dataSource.pageChange($event)"
        [showFirstLastButtons]="true"
        class="mt-auto"
      ></mat-paginator>
    </ng-container>
    <ng-container *ngTemplateOutlet="selectableListTemplateAddOns"></ng-container>
  </div>
</div>

<ng-template #selectableListTemplateAddOns>
  <vdms-hq-ui-loader
    *ngIf="(dataSource.isLoading$ | async) && listViewShow.value"
    [backdrop]="true"
    mode="over-parent"
    [text]="config.loadingText"
  ></vdms-hq-ui-loader>
  <ng-container *ngIf="dataSource.emptyResults$ && (dataSource.emptyResults$ | async)">
    <vdms-hq-ui-empty-results
      class="data__results-empty"
      [message]="config.emptyMessage"
      [type]="'compact'"
      [icon]="'insights'"
    ></vdms-hq-ui-empty-results>
    <vdms-hq-ui-button
      *ngIf="hasEmptyActionButton"
      class="w-full justify-center"
      (click)="emptyActionButtonClicked()"
      color="primary"
      >{{ emptyActionButtonText | translate }}
    </vdms-hq-ui-button>
  </ng-container>
</ng-template>
