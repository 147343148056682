<vdms-hq-ui-layout-page-wrapper [breadcrumbs]="breadcrumb$ | async">
  <vdms-hq-ui-loader [backdrop]="true" mode="over-parent" [center]="false" *ngIf="isLoading$ | async">
  </vdms-hq-ui-loader>

  <mat-tab-group animationDuration="0ms" (selectedIndexChange)="onTabChange($event)" [selectedIndex]="currentIndex">
    <mat-tab [label]="config.label" *ngFor="let config of tabs$ | async; let i = index">
      <div class="content" *ngIf="formSettings$ | async; let settings">
        <div class="header">
          <vdms-hq-ui-info-bar
            *ngIf="settings.isInheritedFromDefault"
            [message]="'This configuration is inherited from Default'"
            [type]="InfoBarType.WARNING"
          ></vdms-hq-ui-info-bar>

          <vdms-hq-ui-button (click)="revertToDefault()" color="secondary" *ngIf="settings.canRevertToDefault">
            {{ 'common.global.revert_defaults' | translate }}
          </vdms-hq-ui-button>
        </div>

        <form [formGroup]="form">
          <vdms-hq-ui-config-drag-drop
            [formControl]="form.controls.dragDrop"
            [all]="(allFields$ | async) ?? []"
            [viewName]="config.viewName"
            [allowDeleting]="settings.canRemoveFromAvailable"
          ></vdms-hq-ui-config-drag-drop>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-ui-actions-footer>
  <vdms-hq-ui-button left color="secondary" (click)="reset()" [disabled]="form.pristine">
    {{ 'pages.customized_checkout.cancel' | translate }}
  </vdms-hq-ui-button>
  <vdms-hq-ui-button
    right
    [disabled]="form.pristine"
    color="primary"
    (click)="save()"
    [loading]="(isSaving$ | async) ?? false"
  >
    {{ 'common.global.save' | translate }}
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>
