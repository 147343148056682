import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpHeaders } from '@angular/common/http';
import { GetResponseData, GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiPaginatedResponse, ApiResponse } from '../api.model';
import {
  ContractCreate,
  ContractGet,
  ContractListItem,
  ContractUpdate,
  RightContractsEndpoints,
  RightContractsEndpointsOptions,
  RightsContractsExportMetadataPost,
  RightsContractsFiltersView,
  RightsContractsLog,
} from './rights-contracts.model';
import { PaginationAPIModel as Pagination } from '../pagination.model';
import { DataSelector, ResultDefinitionModel } from '@vdms-hq/shared';

@Injectable({ providedIn: 'root' })
export class RightsContractsService {
  constructor(private readonly apiService: ApiService) {}

  getMany = (pagination?: Pagination, filters?: RightsContractsFiltersView) => {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    if (filters) {
      headers = filters.keyword ? headers.append('filter', filters.keyword) : headers;
      headers = filters.partners ? headers.append('partner-id', filters.partners) : headers;
    }
    headers = headers.append('Cache-Control', 'max-age=0');

    return this.apiService
      .get<ApiPaginatedResponse<ContractListItem>>(`${RightContractsEndpoints.ROOT_LIST}`, { headers })
      .pipe(GetResponsePaginationData);
  };

  export = (
    exportFieldDefs: ResultDefinitionModel[],
    pagination?: Pagination,
    filters?: RightsContractsFiltersView,
  ) => {
    let headers = new HttpHeaders();
    headers = pagination ? pagination.applyToHeaders(headers) : headers;
    if (filters) {
      headers = filters.keyword ? headers.append('filter', filters.keyword) : headers;
      headers = filters.partners ? headers.append('partner-id', filters.partners) : headers;
    }
    headers = headers.append('Cache-Control', 'max-age=0');

    const fields =
      exportFieldDefs?.map((column) => ({
        value: column.results2.exportObjectPath as string,
        label: column.label,
      })) ?? [];

    return this.apiService
      .post<RightsContractsExportMetadataPost, ApiResponse<string>>(
        `${RightContractsEndpoints.ROOT_LIST}/${RightContractsEndpointsOptions.EXPORT}`,
        { fields },
        headers,
      )
      .pipe(GetResponseData);
  };

  getLogs = (uuid: string) => {
    return this.apiService
      .get<ApiResponse<RightsContractsLog[]>>(
        `${RightContractsEndpoints.ROOT}/${uuid}/${RightContractsEndpointsOptions.LOGS}`,
      )
      .pipe(GetResponseData);
  };

  getOne = (uuid: string) => {
    return this.apiService
      .get<ApiResponse<ContractGet>>(`${RightContractsEndpoints.ROOT}/${uuid}`, {
        headers: new HttpHeaders({
          'cache-control': 'max-age=0',
        }),
      })
      .pipe(GetResponseData);
  };

  create = (contract: ContractCreate) => {
    return this.apiService
      .post<ContractCreate, ApiResponse<ContractGet>>(`${RightContractsEndpoints.ROOT}`, contract)
      .pipe(GetResponseData);
  };

  patch = (uuid: string, contract: ContractUpdate) => {
    return this.apiService
      .patch<ContractUpdate, ApiResponse<ContractGet>>(`${RightContractsEndpoints.ROOT}/${uuid}`, contract)
      .pipe(GetResponseData);
  };

  remove = (uuid: string) => {
    return this.apiService.delete<null, undefined>(`${RightContractsEndpoints.ROOT}/${uuid}`);
  };

  setReadyToRelease = (uuid: string) => {
    return this.apiService
      .put<any, ApiResponse<ContractGet>>(`${RightContractsEndpoints.ROOT}/${uuid}/set-ready-to-release`, {})
      .pipe(GetResponseData);
  };
}
