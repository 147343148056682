import { Routes } from '@angular/router';
import { ResultsComponent } from './pages/results/results.component';
import { BrowseRouterService } from './logic/services/browse-router.service';
import { SearchResultsConfigFieldsService } from './logic/services/search-results-config-fields.service';

export const assetSearchRoutes: Routes = [
  {
    path: 'all',
    component: ResultsComponent,
    resolve: {
      params: BrowseRouterService,
      scope: SearchResultsConfigFieldsService,
    },
  },
  {
    path: ':configKey',
    component: ResultsComponent,
    resolve: {
      params: BrowseRouterService,
      scope: SearchResultsConfigFieldsService,
    },
  },
  {
    path: '**',
    redirectTo: 'all',
  },
];
