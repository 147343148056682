import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { AssetResults2Component, AssetResultsModule } from '@vdms-hq/asset-results';
import { E2eIdDirective, SharedModule as LibSharedModule } from '@vdms-hq/shared';
import {
  DataPresentationHeaderComponent,
  PaginatorComponent,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
  UILayoutModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { CartResultsActionsComponent } from './components/cart-results-actions/cart-results-actions.component';
import { DestinationSelectorComponent } from './components/destination-selector/destination-selector.component';
import { OrderDialogComponent } from './components/order-dialog/order-dialog.component';
import { CartComponent } from './pages/cart/cart.component';

const routes: Routes = [
  {
    path: '',
    component: CartComponent,
  },
];

@NgModule({
  declarations: [CartComponent, CartResultsActionsComponent, OrderDialogComponent, DestinationSelectorComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    UILayoutModule,
    UILoaderModule,
    PaginatorComponent,
    UIButtonModule,
    UIDialogWrapperModule,
    UIFormModule,
    AssetResultsModule,
    LibSharedModule,
    ActivatedClientModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    AssetResults2Component,
    DataPresentationHeaderComponent,
    E2eIdDirective,
  ],
  exports: [RouterModule, CartComponent, DestinationSelectorComponent],
})
export class CartModule {}
