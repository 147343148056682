import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscountsService, GetDiscountCodeInterface } from '@vdms-hq/api-contract';
import { BehaviorSubject, map } from 'rxjs';
import { DestroyComponent } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DiscountsDsService } from '../../logic/discounts-ds.service';
import { DepartmentService } from '../../logic/department.service';

@Component({
  selector: 'vdms-hq-discount-create-edit-dialog',
  standalone: true,
  imports: [
    CommonModule,
    UIDialogWrapperModule,
    TranslateModule,
    UIButtonModule,
    ReactiveFormsModule,
    FormSectionComponent,
    UIFormModule,
  ],
  templateUrl: './discount-create-edit-dialog.component.html',
  styleUrls: ['./discount-create-edit-dialog.component.scss'],
})
export class DiscountCreateEditDialogComponent extends DestroyComponent implements OnInit {
  private discountsService = inject(DiscountsService);
  private discountsDS = inject(DiscountsDsService);
  private toastService = inject(ToastService);
  private departmentService = inject(DepartmentService);
  public dialogRef = inject(MatDialogRef<DiscountCreateEditDialogComponent>);
  public data = inject(MAT_DIALOG_DATA) as { item: GetDiscountCodeInterface | undefined };

  departmentsOptions$ = this.departmentService.departmentOptions$.pipe(
    map((options) =>
      options.map((option) => ({
        ...option,
        label:
          option.allowed && !this.data.item?.department_field_uuids.includes(option.key as string)
            ? option.label + ' (Discount already enabled)'
            : option.label,
        disabled: option.allowed && !this.data.item?.department_field_uuids.includes(option.key as string),
      })),
    ),
  );
  title = '';
  saveButton = 'pages.clients.discounts.dialogs.create_edit.create_btn';

  isLoading$ = new BehaviorSubject(false);
  editMode$ = new BehaviorSubject<'edit' | 'create'>('create');

  discountForm = new FormGroup({
    uuid: new FormControl<string>('', { nonNullable: true }),
    name: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    code: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    is_active: new FormControl<boolean>(true, { nonNullable: true }),
    multiplier: new FormGroup({
      egress_price_per_gb: new FormControl<number>(1, { nonNullable: true }),
      restoration_price_per_gb: new FormControl<number>(1, { nonNullable: true }),
      asset_processing_fee_price_per_asset: new FormControl<number>(1, { nonNullable: true }),
      transcode: new FormGroup({
        transcode_sd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        transcode_hd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        transcode_uhd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        transcode_5k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        transcode_6k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        transcode_8k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
      }),
      proxy_transcode: new FormGroup({
        proxy_transcode_sd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        proxy_transcode_hd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        proxy_transcode_uhd_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        proxy_transcode_5k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        proxy_transcode_6k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        proxy_transcode_8k_price_per_min: new FormControl<number>(1, { nonNullable: true }),
      }),
      video_analysis: new FormGroup({
        openai_transcription_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        openai_synopsis_generation_price_per_accepted_synopsis: new FormControl<number>(1, { nonNullable: true }),
        openai_translate_transcription_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        openai_generate_ad_breaks_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_celebrity_recognition_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_object_recognition_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_content_moderation_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_face_detection_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_video_segment_detection_price_per_min: new FormControl<number>(1, { nonNullable: true }),
        aws_text_detection_price_per_min: new FormControl<number>(1, { nonNullable: true }),
      }),
    }),
    department_field_uuids: new FormControl<string[]>([], { nonNullable: true }),
  });

  popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create-discount',
        message: 'pages.clients.discounts.dialogs.create_edit.notifications.create_success',
      }),
    CREATE_ERROR: () =>
      this.toastService.error({
        id: 'create-discount',
        message: 'pages.clients.discounts.dialogs.create_edit.notifications.create_failed',
      }),
    EDIT_SUCCESS: () =>
      this.toastService.success({
        id: 'edit-discount',
        message: 'pages.clients.discounts.dialogs.create_edit.notifications.edit_success',
      }),
    EDIT_ERROR: () =>
      this.toastService.error({
        id: 'edit-discount',
        message: 'pages.clients.discounts.dialogs.create_edit.notifications.edit_failed',
      }),
  };

  ngOnInit(): void {
    if (this.data.item) {
      this.editMode$.next('edit');
      this.title = 'pages.clients.discounts.dialogs.create_edit.edit_title';
      this.saveButton = 'pages.clients.discounts.dialogs.create_edit.edit_btn';
      this.discountForm.patchValue(this.data.item);
    } else {
      this.title = 'pages.clients.discounts.dialogs.create_edit.create_title';
      this.saveButton = 'pages.clients.discounts.dialogs.create_edit.create_btn';
    }
  }

  get transcode() {
    return Object.keys(this.discountForm.get('multiplier.transcode')?.getRawValue());
  }

  get proxy_transcode() {
    return Object.keys(this.discountForm.get('multiplier.proxy_transcode')?.getRawValue());
  }

  get video_analysis() {
    return Object.keys(this.discountForm.get('multiplier.video_analysis')?.getRawValue());
  }

  create() {
    this.isLoading$.next(true);
    this.discountsService
      .create(this.discountForm.getRawValue())
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => this.handleComplete(),
        error: (error) => this.handleError(error),
      });
  }

  edit() {
    if (!this.data.item) return;
    this.isLoading$.next(true);
    this.discountsService
      .update(this.data.item.uuid, this.discountForm.getRawValue())
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: () => this.handleComplete(),
        error: (error) => this.handleError(error),
      });
  }

  handleComplete() {
    if (this.editMode$.value === 'edit') {
      this.popToast.EDIT_SUCCESS();
    } else {
      this.popToast.CREATE_SUCCESS();
    }
    this.discountsDS.refresh();
    this.departmentService.refresh();
    this.isLoading$.next(false);
    this.dialogRef.close();
  }

  handleError(error: any) {
    if (this.editMode$.value === 'edit') {
      this.popToast.EDIT_ERROR();
    } else {
      this.popToast.CREATE_ERROR();
    }
    this.isLoading$.next(false);
    throw error;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
