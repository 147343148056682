import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@vdms-hq/shared';
import { FieldsConfigService } from '@vdms-hq/fields';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ClientContentStructure } from '@vdms-hq/firebase-contract';

@Pipe({
  name: 'availableList',
  standalone: true,
})
export class AvailableListPipe implements PipeTransform {
  constructor(private activatedClientConfigService: FieldsConfigService) {}

  transform(structure: ClientContentStructure): Observable<SelectOption[]> {
    return this.activatedClientConfigService.filterAggregationsDefinitionsAsOptions$.pipe(
      take(1),
      map((all) => all.filter((item) => !structure?.fields?.includes(String(item.key)))),
    );
  }
}
