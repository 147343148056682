import { AssetType } from '../asset';
import { CredentialTypeEnum } from '../credentials/credentials.model';

export type DestinationFlatModel = DestinationModel;

export enum DestinationStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
}

export enum DestinationAccessMethodEnum {
  VIDA = 'vida',
  AWS = 'aws',
  ASPERA_HSTS = 'aspera_hsts',
  ASPERA_AOC = 'aspera_aoc',
  ASPERA_YOUTUBE = 'aspera_youtube',
  FTPS = 'ftps',
  YOUTUBE = 'youtube',
}

export type DestinationConfigStatus = 'not_initialized' | 'submitted' | 'completed' | 'failed' | 'draft';

type DestinationParams = {
  uuid: string;
  status: DestinationStatus;
  name: string;
  type: 'file' | 'title';
  method: DestinationAccessMethodEnum;
  email: {
    delivery: string[];
    notification: string[];
  };
  configs: DestinationConfigModel[];
  publish: {
    type: CredentialTypeEnum;
    credentialUuid: string;
    configData: {
      region?: string;
      channel?: string;
      bucketName?: string;
      prefix?: string;
      earPassphrase?: string;
      usedEarPassphrase?: boolean;
      path?: string;
    };
  } | null;
};

export type DestinationConfigModel = {
  uuid: string;
  name: string;
  rules: DestinationRule[];
  status?: DestinationConfigStatus;
};

export type DestinationRule = SimpleDestinationRule | VideoDestinationRule;

export type SimpleDestinationRule = {
  assetType: Exclude<AssetType, 'video'>;
  contentClass: string[];
  publish:
    | {
        type: CredentialTypeEnum;
        credentialUuid: string;
        configData: {
          channel?: string;
          region?: string;
          bucketName?: string;
          prefix?: string;
          path?: string;
        };
      }
    | undefined;
};

export type VideoDestinationRule = {
  assetType: 'video';
  contentClass: string[];
  resolutions: string[];
  videoCodecs: string[];
  transcode: {
    type: TranscodeForDeliveryDestinationType;
    overrideProfile: any;
    burnInText?: boolean;
    audioTracks: VideoDestinationAudioTrackModel[];
    enabled?: boolean;
  };
  publish: {
    type: CredentialTypeEnum;
    enabled?: boolean;
    credentialUuid: string;
    configData: {
      channel?: string;
      region?: string;
      bucketName?: string;
      prefix?: string;
      earPassphrase?: string;
      path?: string;
    };
  };
};

export type VideoDestinationAudioTrackModel = {
  languageUuid?: string;
  contentClass: string;
  layout: string;
};

export type CreateDestinationParams = DestinationParams;

export type UpdateDestinationParams = Partial<DestinationParams>;

export type DestinationModel = DestinationParams & {
  uuid: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateDestinationModel = Partial<DestinationParams>;
export type TranscodeForDeliveryDestinationType = 'youtube-vimeo' | 'itunes' | 'hls';
