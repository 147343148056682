<div class="upload-container" [class.full-width]="fullWidth" [ngClass]="size ?? ''">
  <div
    [attr.data-component-id]="id"
    class="upload-dropzone"
    [class.upload-dropzone--active]="withDropzone"
    [class.upload-dropzone--is-over]="(asperaUploadService.dragOver$ | async) === id"
  ></div>

  <vdms-hq-ui-loader
    *ngIf="(asperaUploadService.connected$ | async) === false"
    mode="over-parent"
    [backdrop]="true"
  ></vdms-hq-ui-loader>

  <div class="upload-placeholder">
    <div class="batches" *ngIf="progressVisibility === ProgressVisibilityEnum.ALL">
      <vdms-hq-storage-transferable-batch
        [@enterAnimation]
        [batch]="item"
        *ngFor="let item of transfersForComponent$ | async; let index = index"
        (deleteElement)="cancelTransfer(item)"
        (closeElement)="asperaUploadService.removeFromTransfers(item)"
      >
      </vdms-hq-storage-transferable-batch>
    </div>

    <img [src]="imageUrl" alt="Aspera" class="upload-dropzone" />
    <ng-content></ng-content>
    <vdms-hq-ui-button
      color="primary"
      class="upload-dropzone"
      (click)="selectFiles()"
      [disabled]="!multiple && (transfersForComponent$ | async)?.length === 1"
      [size]="buttonSize"
    >
      {{ (multiple ? 'common.aspera.upload_button.plural' : 'common.aspera.upload_button') | translate }}
    </vdms-hq-ui-button>

    <span *ngIf="customText?.length; else defaultText" [innerText]="customText"></span>

    <ng-template #defaultText>
      <span *ngIf="withDropzone">{{
        (multiple ? 'common.aspera.or_drop.plural' : 'common.aspera.or_drop') | translate
      }}</span>
    </ng-template>
  </div>
</div>
