import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneType } from '@vdms-hq/ui';
import { UserAttributes } from '@vdms-hq/auth';
import { UserModel } from '@vdms-hq/api-contract';
import { MultiViewType } from '@vdms-hq/firebase-contract';

@Injectable()
export class SettingsFormService {
  #form = new FormGroup({
    profileSettings: new FormGroup({
      profileDetails: new FormGroup({
        email: new FormControl<string>('', { nonNullable: true }),
        displayName: new FormControl<string | null>(null, Validators.required),
        phone: new FormGroup({
          type: new FormControl<PhoneType>(PhoneType.OFFICE, { nonNullable: true }),
          number: new FormControl<string | null>(null),
        }),
      }),
      companyAddress: new FormGroup({
        address1: new FormControl<string | null>(null),
        postalCode: new FormControl<string | null>(null),
        country: new FormControl<string | null>(null),
      }),
      department: new FormControl<string | null>(null),
    }),
    systemSettings: new FormGroup({
      dateFormat: new FormControl<string | null>(null),
      timeFormat: new FormControl<string | null>(null),
      attributes: new FormGroup({
        preferredTimezone: new FormControl<string | null>(null),
        vida: new FormGroup({
          preferredAssetView: new FormControl<'table' | 'thumb'>('table', { nonNullable: true }),
          preferredSelectableView: new FormControl<'search' | 'list'>('search', { nonNullable: true }),
          preferredDefaultTableView: new FormControl<MultiViewType>('table', { nonNullable: true }),
          preferredPageSize: new FormControl<number>(24),
          finishedTour: new FormControl(false, { nonNullable: true }),
          openAssetPreviewInPopup: new FormControl(false, { nonNullable: false }),
          /**
           * @notice keys here must match the lib/settings/TABLE_TYPE enum
           */
          preferredTableView: new FormGroup({
            assetView: new FormControl<MultiViewType | null>(null),
            collectionsView: new FormControl<MultiViewType | null>(null),
            ordersView: new FormControl<MultiViewType | null>(null),
            downloadsView: new FormControl<MultiViewType | null>(null),
            licensedPackagesView: new FormControl<MultiViewType | null>(null),
            licenseesPartnersView: new FormControl<MultiViewType | null>(null),
            contractsView: new FormControl<MultiViewType | null>(null),
          }),
        }),
      }),
    }),
  });
  get isValid(): boolean {
    return this.#form.valid;
  }

  touch = () => this.#form.markAllAsTouched();

  reset = () => this.#form.reset();

  get isPristine(): boolean {
    return this.#form.pristine;
  }

  markAsPristine(): void {
    this.#form.markAsPristine();
  }

  disable(): void {
    this.#form.disable({ emitEvent: false });
  }

  enable(): void {
    this.#form.enable({ emitEvent: false });
  }

  disableEmail(): void {
    this.#form.get('profileSettings.profileDetails.email')?.disable();
  }

  get value() {
    return this.#form.getRawValue();
  }

  get systemSettingsControl() {
    return this.#form.get('systemSettings') as FormGroup;
  }

  get profileSettingsControl() {
    return this.#form.get('profileSettings') as FormGroup;
  }

  get earlyBirdsControl() {
    return this.#form.get('earlyBirds');
  }

  setForm(displayName: string, attributes: UserAttributes, user: UserModel, view: MultiViewType) {
    const phoneType = user?.mobile_number ? PhoneType.MOBILE : PhoneType.OFFICE;
    const phoneNumber = (user?.mobile_number ? user?.mobile_number : user?.office_phone_number) || '';

    const splitedAddress = (user?.office_address || '%2C%2C').split('%2C');
    const address = splitedAddress[0];
    const postalCode = splitedAddress[1];
    const country = splitedAddress[2];

    const department = user?.department_field?.uuid || '';

    this.#form.patchValue({
      profileSettings: {
        profileDetails: {
          displayName: displayName,
          email: user.email,
          phone: {
            type: phoneType,
            number: phoneNumber,
          },
        },
        companyAddress: {
          address1: address || null,
          country: country || null,
          postalCode: postalCode || null,
        },
        department,
      },
      systemSettings: {
        timeFormat: user?.time_format,
        attributes: {
          ...attributes,
          preferredTimezone: attributes.preferredTimezone !== 'local' ? 'client' : 'local',
          vida: {
            ...attributes.vida,
            preferredAssetView: attributes.vida.preferredAssetView || 'table',
            preferredDefaultTableView: attributes.vida.preferredDefaultTableView || view,
            preferredPageSize: attributes.vida.preferredPageSize || 12,
            openAssetPreviewInPopup: attributes.vida.openAssetPreviewInPopup,
          },
        },
        dateFormat: user?.date_format,
      },
    });

    this.#form.markAsPristine();
  }
}
