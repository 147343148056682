import { Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormSectionComponent, UIButtonModule, UIDialogWrapperModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {
  CredentialTypeEnum,
  DestinationAccessMethodEnum,
  DestinationApiService,
  DestinationStatus,
} from '@vdms-hq/api-contract';
import { BehaviorSubject, catchError, throwError, withLatestFrom } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { DELIVERY_DESTINATIONS_ROUTING } from '../../delivery-destinations-routing';
import { Permission } from '@vdms-hq/firebase-contract';
import {
  bucketNameValidator,
  DestroyComponent,
  E2eIdDirective,
  emailsArrayValidator,
  enumValuesToSelectOptions,
  prefixValidator,
} from '@vdms-hq/shared';
import { PermissionService } from '@vdms-hq/activated-client';
import { AwsRegionsOptions } from '../../logic/delivery-destination-options';
import { DeliveryDestinationCredentialsService } from '../../logic/delivery-destination-credentials.service';

@Component({
  selector: 'vdms-hq-destination-add-dialog',
  templateUrl: './destination-add-dialog.component.html',
  imports: [
    CommonModule,
    MatDialogModule,
    UIDialogWrapperModule,
    FormSectionComponent,
    UIFormModule,
    UIButtonModule,
    TranslateModule,
    UILayoutModule,
    E2eIdDirective,
  ],
  standalone: true,
})
export class DestinationAddDialogComponent extends DestroyComponent implements OnInit {
  protected readonly CredentialTypeEnum = CredentialTypeEnum;
  protected readonly AwsRegionsOptions = AwsRegionsOptions;
  protected readonly DestinationAccessMethodEnum = DestinationAccessMethodEnum;

  private deliverService: DestinationApiService = inject(DestinationApiService);
  private toastService: ToastService = inject(ToastService);
  private router: Router = inject(Router);
  private permissionService: PermissionService = inject(PermissionService);
  dialogRef: MatDialogRef<DestinationAddDialogComponent> = inject(MatDialogRef<DestinationAddDialogComponent>);
  destinationCredentialsService: DeliveryDestinationCredentialsService = inject(DeliveryDestinationCredentialsService);

  #popToast = {
    CREATE_SUCCESS: () =>
      this.toastService.success({
        id: 'create_field_success',
        message: 'common.delivery_destinations.create.success',
      }),
    CREATE_FAILURE: (reason: string = 'Not specified') =>
      this.toastService.error({
        id: 'create_field_failure',
        message: 'common.delivery_destinations.create.failure',
        interpolatedParams: { reason },
      }),
  };

  loading$ = new BehaviorSubject(false);
  statusOptions = enumValuesToSelectOptions(DestinationStatus, 'common.delivery_destinations.status.', true);
  methodOptions = enumValuesToSelectOptions(DestinationAccessMethodEnum, 'common.delivery_destinations.method.', true);

  form = new FormGroup({
    name: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    method: new FormControl<string>({ disabled: false, value: 'vida' }),
    type: new FormControl<string>({ disabled: true, value: 'file' }),
    status: new FormControl<DestinationStatus>(
      { disabled: true, value: DestinationStatus.DRAFT },
      {
        validators: Validators.required,
        nonNullable: true,
      },
    ),
    emailDelivery: new FormControl<string[]>([], {
      validators: [Validators.required, emailsArrayValidator(true)],
      nonNullable: true,
    }),
    deliveryEmails: new FormControl<string[]>([], {
      validators: [emailsArrayValidator(true)],
      nonNullable: true,
    }),
    defaultConfig: new FormControl<string>('Config 1', {
      validators: Validators.required,
      nonNullable: true,
    }),
    publish: new FormGroup({
      type: new FormControl<CredentialTypeEnum | null>(null),
      enabled: new FormControl<boolean>(true, { nonNullable: true }),
      credentialUuid: new FormControl<string | null>(null),
      configData: new FormGroup({
        region: new FormControl<string | null | undefined>(null),
        bucketName: new FormControl<string | null | undefined>(null),
        prefix: new FormControl<string | undefined>(''),
        earPassphrase: new FormControl<string | null | undefined>(null),
        path: new FormControl<string | null | undefined>(null),
      }),
    }),
  });

  get method() {
    return this.form.controls.method.value;
  }

  ngOnInit() {
    this.form.controls.method.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((method) => {
      this.form.controls.publish.patchValue({
        credentialUuid: null,
        configData: {
          region: null,
          bucketName: null,
          prefix: '',
          earPassphrase: null,
          path: null,
        },
      });

      switch (method) {
        case DestinationAccessMethodEnum.AWS:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.region.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.bucketName.setValidators([
            Validators.required,
            bucketNameValidator(),
          ]);
          this.form.controls.publish.controls.configData.controls.prefix.setValidators(prefixValidator());
          break;
        case DestinationAccessMethodEnum.ASPERA_YOUTUBE:
        case DestinationAccessMethodEnum.ASPERA_HSTS:
        case DestinationAccessMethodEnum.ASPERA_AOC:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.prefix.setValidators(prefixValidator());
          break;
        case DestinationAccessMethodEnum.FTPS:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          break;
        case DestinationAccessMethodEnum.YOUTUBE:
          this.form.controls.publish.controls.credentialUuid.setValidators(Validators.required);
          break;
        case DestinationAccessMethodEnum.VIDA:
          this.form.controls.publish.controls.credentialUuid.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.region.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.bucketName.removeValidators(Validators.required);
          this.form.controls.publish.controls.configData.controls.prefix.removeValidators(prefixValidator());
          break;
      }
    });
  }

  abort() {
    this.dialogRef.close();
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const uuid = uuidv4();
    const uuidConfig = uuidv4();

    this.loading$.next(true);

    this.deliverService
      .create({
        uuid,
        status: DestinationStatus.DRAFT,
        name: this.form.controls.name.value,
        type: 'file',
        method: this.form.controls.method.value as DestinationAccessMethodEnum,
        email: {
          notification: this.form.controls.deliveryEmails.value,
          delivery: this.form.controls.emailDelivery.value,
        },
        configs: [
          {
            uuid: uuidConfig,
            name: this.form.controls.defaultConfig.value,
            rules: [],
          },
        ],
        publish:
          this.method === DestinationAccessMethodEnum.VIDA
            ? null
            : {
                type: this.method?.toUpperCase() as CredentialTypeEnum,
                credentialUuid: this.form.controls.publish.controls.credentialUuid.value ?? '',
                configData: {
                  region: this.form.controls.publish.controls.configData.controls.region.value ?? undefined,
                  bucketName: this.form.controls.publish.controls.configData.controls.bucketName.value ?? undefined,
                  prefix: this.form.controls.publish.controls.configData.controls.prefix.value ?? undefined,
                  earPassphrase:
                    this.form.controls.publish.controls.configData.controls.earPassphrase.value ?? undefined,
                  usedEarPassphrase: !!this.form.controls.publish.controls.configData.controls.earPassphrase.value,
                  path: this.form.controls.publish.controls.configData.controls.path.value ?? undefined,
                },
              },
      })
      .pipe(
        withLatestFrom(this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_DELIVERY_DESTINATIONS])),
        catchError((err) => {
          this.#popToast.CREATE_FAILURE();

          this.loading$.next(false);
          return throwError(err);
        }),
      )
      .subscribe(([_, canEditPermission]) => {
        this.loading$.next(true);
        this.#popToast.CREATE_SUCCESS();
        canEditPermission && this.router.navigate(['/', DELIVERY_DESTINATIONS_ROUTING.ROOT, uuid]);
        this.dialogRef.close();
      });
  }
}
