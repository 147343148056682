<div class="multi-data-container">
  <vdms-hq-ui-data-presentation-header [title]="title | translate" [counterIndicator]="dataSource.total$ | async">
  </vdms-hq-ui-data-presentation-header>
  <vdms-hq-ui-multiple-data-presentation
    *ngIf="viewConfiguration$ | async; let configuration"
    [dataSource]="dataSource"
    [configuration]="configuration"
    (action)="actionHandler($event)"
  >
    <vdms-hq-dynamic-form-group-renderer
      filters
      [form]="filtersForm.filters"
      [fieldsConfig]="filtersConfig"
      [hasLoader]="true"
    ></vdms-hq-dynamic-form-group-renderer>
  </vdms-hq-ui-multiple-data-presentation>
</div>
