import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material-experimental/mdc-slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatTreeModule } from '@angular/material/tree';
import { SectionComponent } from './view-components/section/section.component';
import { TilesComponent } from './view-components/tiles/tiles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SectionSideBySideComponent } from './view-components/section-side-by-side/section-side-by-side.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { TimecodeService } from './logic/calculators/timecode.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FilteringPipe } from './pipes/filtering.pipe';
import { SelectedFileComponent } from './view-components/selected-file/selected-file.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TileComponent } from './view-components/tile/tile.component';
import { BdcWalkModule } from 'bdc-walkthrough';
import { SecondsAsTimeCodePipe } from './pipes/seconds-as-timecode.pipe';
import { ClientSpecificThemePipe } from './pipes/client-specific-label.pipe';
import { UIButtonModule, UIDialogWrapperModule, UIPipesModule } from '@vdms-hq/ui';
import { AuthPipesModule } from '@vdms-hq/auth';

export const DATE_TIME_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const materialModules = [
  MatCheckboxModule,
  MatCheckboxModule,
  MatButtonModule,
  MatInputModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatRadioModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSidenavModule,
  MatToolbarModule,
  MatListModule,
  MatGridListModule,
  MatCardModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatTreeModule,
  MatRippleModule,
];
const commonModules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  InfiniteScrollModule,
  DragDropModule,
  UIPipesModule,
  AuthPipesModule,
];

const viewComponents = [
  SectionComponent,
  TilesComponent,
  SectionSideBySideComponent,
  SelectedFileComponent,
  TileComponent,
];

const pipes = [SlugifyPipe, FilteringPipe, SecondsAsTimeCodePipe];

@NgModule({
  declarations: [...viewComponents, ...pipes, ClientSpecificThemePipe],
  imports: [
    CommonModule,
    ...materialModules,
    ...commonModules,
    TranslateModule.forChild(),
    NgxMaskModule.forChild(),
    BdcWalkModule,
    MomentDateModule,
    UIButtonModule,
    UIDialogWrapperModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_TIME_FORMAT },
    TimecodeService,
    ...pipes,
  ],
  exports: [
    ...viewComponents,
    ...materialModules,
    ...commonModules,
    ...pipes,
    TranslateModule,
    NgxMaskModule,
    BdcWalkModule,
    ClientSpecificThemePipe,
  ],
})
export class SharedModule {}
