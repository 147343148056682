import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueFormat',
  standalone: true,
})
export class ValueFormatPipe implements PipeTransform {
  transform(value: string | number | string[] | number[] | undefined | unknown) {
    if (Array.isArray(value)) {
      return value.join(',\r\n');
    }

    return value;
  }
}
