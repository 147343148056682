import { Injectable } from '@angular/core';
import { OrderService, SortOptions } from '@vdms-hq/api-contract';
import { BehaviorSubject, combineLatest, EMPTY, Observable, switchMap, takeWhile } from 'rxjs';
import { ToastService } from '@vdms-hq/toast';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import {
  FieldConfigId,
  FieldsScopeKey,
  PageableDataSource,
  SelectableDataSource,
  SelectionManager,
} from '@vdms-hq/shared';
import { OrderResolverService } from './order-resolver.service';
import { AssetFiltersForm, OrderAssetViewModel2 } from './models';
import { HttpErrorResponse } from '@angular/common/http';
import { DataProviderService } from '@vdms-hq/selectors';
import { StorageUrlService } from '@vdms-hq/storage';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { RouterParamsPagination } from '@vdms-hq/view-settings';

@Injectable({ providedIn: 'root' })
export class CurrentOrderAssetsDataSource
  extends RouterParamsPagination
  implements
    TableAdvancedDataSource<OrderAssetViewModel2>,
    PageableDataSource,
    SelectableDataSource<OrderAssetViewModel2>
{
  static readonly defaultPerPage = 12;
  static readonly defaultPage = 0;

  sortBy$ = new BehaviorSubject<FieldConfigId>('');
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('asc');
  filters$ = new BehaviorSubject<Partial<AssetFiltersForm>>({});

  isLoading$ = new BehaviorSubject<boolean>(true);
  refresh$ = new BehaviorSubject<boolean>(false);
  scope: FieldsScopeKey = 'orders';

  allData$ = combineLatest([
    this.orderParamResolver.currentIdDefinite$.pipe(tap(() => this.selection.clear())),
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.refresh$.pipe(tap(() => this.selection.clear())),
  ]).pipe(
    takeWhile(([, , , sortBy]) => !!sortBy),
    tap(() => this.isLoading$.next(true)),
    switchMap(([id, pageIndex, pageSize, sortBy, direction, filters, refresh]) => {
      return this.orderService
        .getAssets(
          id,
          {
            page: pageIndex,
            perPage: pageSize,
            orderBy: sortBy,
            orderDir: direction.toUpperCase(),
          },
          {
            'facility-of-origin': filters.facilityOfOrigin,
            type: filters.assetMasterType,
            text: filters.text,
            'tx-date-from': filters.txDate?.from?.toISOString(),
            'tx-date-to': filters.txDate?.to?.toISOString(),
          },
        )
        .pipe(catchError((err: HttpErrorResponse) => this.#errorHandler(err)));
    }),
    tap((response) => this.total$.next(response.total)),
    map((response) =>
      (response.data ?? []).map((item) =>
        OrderAssetViewModel2.fromOrderItem(item, {
          dataProvider: this.dataProvider,
          storageUrlService: this.storageUrlService,
          activatedClientService: this.activatedClientService,
        }),
      ),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;
  total$ = new BehaviorSubject<number>(0);

  selection: SelectionManager<OrderAssetViewModel2> = new SelectionManager(
    this,
    (item) => item.orderUuid ?? item.asset.filename,
  );

  constructor(
    private toastService: ToastService,
    private orderService: OrderService,
    private orderParamResolver: OrderResolverService,
    private dataProvider: DataProviderService,
    private storageUrlService: StorageUrlService,
    private activatedClientService: ActivatedClientService,
  ) {
    super();
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
  }

  #errorHandler(err: HttpErrorResponse): Observable<never> {
    if (err.status !== 400) {
      this.toastService.error({ id: 'sharedPackAssets', message: 'common.orders.errors.list' });
    }
    this.isLoading$.next(false);
    return EMPTY;
  }

  applyFilters(filters: AssetFiltersForm) {
    this.filters$.next(filters);
  }
}
