import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIEmptyResultsModule, UILoaderModule } from '@vdms-hq/ui';
import { BehaviorSubject, combineLatest, map, startWith, take, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vdms-hq/toast';
import { CREDENTIALS_ROUTES } from '../../credentials.routes';
import { CredentialsApiService } from '@vdms-hq/api-contract';
import { CREDENTIALS_TOKEN, CredentialsConfig } from '../../configure';

@Component({
  selector: 'vdms-hq-youtube-auth',
  standalone: true,
  imports: [CommonModule, UILoaderModule, UIEmptyResultsModule],
  templateUrl: './youtube-auth.component.html',
  providers: [],
  styleUrls: ['./youtube-auth.component.scss'],
})
export class YoutubeAuthComponent implements OnInit {
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private credentialsApiService = inject(CredentialsApiService);
  private toastService = inject(ToastService);
  private credentialsConfig = inject<CredentialsConfig>(CREDENTIALS_TOKEN);

  tooLongWait$ = timer(20000).pipe(
    map(() => true),
    startWith(false),
  );
  error$ = new BehaviorSubject(false);

  state$ = combineLatest([this.tooLongWait$, this.error$]).pipe(
    map(([tooLongWait, error]) => {
      if (error) {
        return 'error';
      }

      if (tooLongWait) {
        return 'tooLongWait';
      }
      return 'loading';
    }),
  );

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
      const code = queryParamMap.get('code');

      if (code) {
        const clientId = localStorage.getItem('clientId');

        if (!clientId) {
          this.#setError('Some parameters are missing, ensure you have the correct url');
          return;
        }

        this.credentialsApiService
          .createYoutube({
            code,
            clientId,
          })
          .subscribe({
            next: () => {
              localStorage.removeItem('clientId');

              this.#setSuccess();
            },
            error: () => {
              this.#setError('Invalid response from server');
            },
          });

        return;
      }

      const clientId = queryParamMap.get('clientId');

      if (!clientId) {
        this.#setError('Some parameters are missing, ensure you have the correct url');
        return;
      }

      localStorage.setItem('clientId', clientId);

      this.#redirectToOauthPopup();
    });
  }

  #redirectToOauthPopup() {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth');
    url.searchParams.append('client_id', this.credentialsConfig.oAuthGoogleClientId);
    url.searchParams.append('access_type', 'offline');
    url.searchParams.append('redirect_uri', window.location.origin + '/' + CREDENTIALS_ROUTES.ROOT_YOUTUBE_AUTH);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append(
      'scope',
      'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube.readonly',
    );

    window.location.assign(url.toString());
  }

  #setError(reason: string) {
    this.toastService.error({
      id: 'youtube-auth',
      message: reason,
    });

    this.error$.next(true);

    timer(7000)
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['/']);
      });
  }

  #setSuccess() {
    this.toastService.success({
      id: 'youtube-auth',
      message: 'Youtube account has been successfully linked with vida.studio',
    });
    this.router.navigate(['/']);
  }
}
