import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { ClientFieldConfigModel, Permission } from '@vdms-hq/firebase-contract';
import {
  FieldDefinitionModel,
  FilterDefinitionModel,
  FilterType,
  InputDefinitionModel,
  isVisibleInForm,
  isVisibleSearch,
  mergeDeep,
  SelectOption,
} from '@vdms-hq/shared';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { fieldDefinitions } from './fields/field-definitions';

/**
 * @deprecated going to replace with FieldsFetcherService
 */
@Injectable({
  providedIn: 'root',
})
export class FieldsConfigService {
  protected activatedClient = inject(ActivatedClientService);
  protected translateService = inject(TranslateService);
  protected permissionService = inject(PermissionService);

  protected allFieldDefinitions$: Observable<FieldDefinitionModel[]> = this.activatedClient.fieldsValueChanges$.pipe(
    map<ClientFieldConfigModel[], FieldDefinitionModel[]>((clientFields) =>
      fieldDefinitions.map((fieldDefinition) => {
        const overriddenConfig = clientFields.find((clientField) => clientField.id === fieldDefinition.id)?.override;
        if (overriddenConfig) {
          return {
            ...fieldDefinition,
            input:
              fieldDefinition.input && overriddenConfig.input
                ? mergeDeep(fieldDefinition.input, {
                    ...overriddenConfig.input,
                  })
                : fieldDefinition.input,
            results: fieldDefinition.results2,
            filters:
              fieldDefinition.filters && overriddenConfig.filters
                ? mergeDeep(fieldDefinition.filters, {
                    ...overriddenConfig.filters,
                  })
                : fieldDefinition.filters,
            label: overriddenConfig.label ?? fieldDefinition.label,
            format: overriddenConfig.format ?? fieldDefinition.format,
            sourceListKey: overriddenConfig.sourceListKey ?? fieldDefinition.sourceListKey,
            isModified: true,
          };
        }
        return fieldDefinition;
      }),
    ),
    switchMap((items) =>
      this.translateService.get(items.map((item) => item.label)).pipe(
        map((translated) =>
          items.map((item) => ({
            ...item,
            label: translated[item.label],
          })),
        ),
      ),
    ),
    switchMap((defs) => this.activatedClient.permissions$.pipe(map((permissions) => ({ defs, permissions })))),
    map(({ defs, permissions }): FieldDefinitionModel[] => {
      return defs
        .map((item): FieldDefinitionModel => {
          if (item?.input?.writePermission) {
            item.input.readonly = !this.permissionService.verifyPermissions(
              item?.input?.writePermission?.permissions as Permission[],
              permissions,
              item.input.writePermission.comparator,
            );
            return item;
          }
          if (item?.input?.readPermission) {
            const hasReadPermission = this.permissionService.verifyPermissions(
              item.input.readPermission.permissions as Permission[],
              permissions,
              item.input.readPermission.comparator,
            );
            return hasReadPermission ? item : ({} as FieldDefinitionModel);
          }
          return item;
        })
        .filter((item) => !!item.id);
    }),
    take(1),
    shareReplay(1),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  #allAssetFieldDefinitions$ = this.allFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => def.resource.toString().startsWith('ASSET_'))),
    shareReplay(1),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  inputDefinitions$ = this.#allAssetFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => isVisibleInForm(def)) as InputDefinitionModel[]),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  #filterDefinitions$ = this.#allAssetFieldDefinitions$.pipe(
    map((defs) => defs.filter((def) => isVisibleSearch(def)) as FilterDefinitionModel[]),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterAggregationsDefinitions$ = this.#filterDefinitions$.pipe(
    map((defs) =>
      defs.filter(
        (defs) =>
          !!defs.filters.aggregationKey &&
          [
            FilterType.SELECTOR,
            FilterType.TEXT_AUTOCOMPLETE,
            FilterType.SELECTOR_GROUP,
            FilterType.CHECKBOX_LIST,
          ].includes(defs.filters.type),
      ),
    ),
  );

  /**
   * @deprecated, will be replaced with AssetFieldsConfigService, do not use directly this method in new code
   */
  filterAggregationsDefinitionsAsOptions$: Observable<SelectOption[]> = this.filterAggregationsDefinitions$.pipe(
    map((items) =>
      items.map((item) => ({
        key: item.id,
        label: `${item.label} (${item.id})`,
      })),
    ),
  );
}
