import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActionContextLess,
  DataAction as Action,
  DataPresentationHeaderComponent,
  DefaultMultiViewType,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIButtonModule,
  UIConfirmationDialogService,
  UILayoutModule,
} from '@vdms-hq/ui';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import {
  DestroyComponent,
  FieldsScopeKey,
  FilterType,
  ResourceModel,
  ResultDefinitionModel,
  ValueFormat,
} from '@vdms-hq/shared';
import { ContractListItem, PaginationAPIModel as Pagination, RightsContractsService } from '@vdms-hq/api-contract';
import { RightsContractsResultsMultiDs } from '../../logic/rights-contracts-results-multi-ds.service';
import { RightsContractsActionsService } from '../../logic/rights-contracts-actions.service';
import { RightsPartnersAddDsService } from '@vdms-hq/rights-partners';
import { combineLatest, Observable, switchMap, take, withLatestFrom } from 'rxjs';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, ActivatedClientService, PermissionService } from '@vdms-hq/activated-client';
import { filter, map } from 'rxjs/operators';
import { TABLE_TYPE, ViewSettingsService } from '@vdms-hq/view-settings';
import { ToastService } from '@vdms-hq/toast';
import { RIGHTS_CONTRACTS_ROUTER_BASE } from '../../rights-contracts-routing.module';
import { Router } from '@angular/router';
import { ColumnsConfigDialogComponent, FieldsFetcherService } from '@vdms-hq/fields';
import { MatDialog } from '@angular/material/dialog';
import { RightsContractsViewModel } from '../../logic/rights-contracts-view.model';

@Component({
  selector: 'vdms-hq-rights-contracts-results',
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    DynamicFiltersModule,
    TranslateModule,
    UILayoutModule,
    MultipleDataPresentationComponent,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
  ],
  templateUrl: './rights-contracts-results.component.html',
  styles: [],
})
export class RightsContractsResultsComponent extends DestroyComponent {
  private rightsContractsActionsService: RightsContractsActionsService = inject(RightsContractsActionsService);
  public rightsContractsResultsMultiDs: RightsContractsResultsMultiDs = inject(RightsContractsResultsMultiDs);
  private rightsPartnersAddDsService: RightsPartnersAddDsService = inject(RightsPartnersAddDsService);
  private viewSettingsService: ViewSettingsService = inject(ViewSettingsService);
  private permissionsService: PermissionService = inject(PermissionService);
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);
  private confirmationDialog: UIConfirmationDialogService = inject(UIConfirmationDialogService);
  private rightsContractsService: RightsContractsService = inject(RightsContractsService);
  private toastService: ToastService = inject(ToastService);
  private fieldsConfig = inject(FieldsFetcherService);
  private router: Router = inject(Router);
  private matDialog = inject(MatDialog);
  private scope: FieldsScopeKey = 'other-rights-contracts';

  protected readonly Permission = Permission;
  headerActions$: Observable<ActionContextLess[]> = this.activatedClientService.permissions$.pipe(
    this.takeUntilDestroyed(),
    map((permissions) => [
      {
        key: 'export',
        label: 'pages.assets_list.export',
        icon: 'forward_to_inbox',
      },
      {
        key: 'create',
        label: 'common.rights_contracts.table.actions.create',
        hiddenIf: () => !permissions.includes(Permission.CREATE_RIGHTS_CONTRACTS),
      },
    ]),
  );

  filtersConfig$: Observable<DynamicFilterInput[]> = combineLatest([
    this.rightsPartnersAddDsService.allData$,
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([partners]) => {
      return <DynamicFilterInput[]>[
        {
          id: 'keyword',
          label: 'common.rights_contracts.table.filter_placeholder',
          resource: [ResourceModel.RIGHTS_CONTRACT],
          format: ValueFormat.AS_IS,
          filters: {
            objectPath: 'name',
            validFormat: 'keyword',
            type: FilterType.MASTER_TEXT,
          },
        },
        {
          id: 'partners',
          label: 'common.license_package.table.partners_placeholder',
          resource: [ResourceModel.ASSET_LICENSED_PACKAGE],
          format: ValueFormat.SELECTOR,
          filters: {
            objectPath: 'partners_uuid',
            validFormat: 'keyword',
            type: FilterType.SELECTOR,
          },
          selectOptions: partners,
          enableClear: true,
        },
      ];
    }),
  );

  #actions: Observable<Action<RightsContractsViewModel>[]> = combineLatest([
    this.permissionsService.verifyWithOwnedPermissions$([Permission.EDIT_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.DELETE_RIGHTS_CONTRACTS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_RIGHTS_CONTRACTS_LOGS]),
    this.permissionsService.verifyWithOwnedPermissions$([Permission.BROWSE_RIGHTS_CONTRACTS]),
  ]).pipe(
    this.takeUntilDestroyed(),
    map(([canEdit, canDelete, canShowLogs, canBrowse]) => {
      const actions: Action<RightsContractsViewModel>[] = [];

      if (canEdit) {
        actions.push({
          key: 'edit',
          label: 'common.rights_contracts.table.actions.edit',
          icon: 'edit',
        });
      }
      if (canDelete) {
        actions.push({
          key: 'delete',
          label: 'common.rights_contracts.table.actions.delete',
          icon: 'delete',
        });
      }
      if (canShowLogs) {
        actions.push({
          key: 'logs',
          label: 'common.rights_contracts.table.actions.show_logs',
          icon: 'web_stories',
        });
      }
      if (canBrowse) {
        actions.push({
          key: 'preview',
          icon: 'visibility',
          label: 'common.rights_contracts.table.actions.preview',
          onDoubleClick: true,
        });
      }

      return actions;
    }),
  );

  rightsContractsConfig$: Observable<MultipleViewConfiguration<RightsContractsViewModel>> = combineLatest([
    this.#actions,
    this.viewSettingsService.defaultViewFor$(TABLE_TYPE.CONTRACT),
    this.fieldsConfig.getConfiguration$(this.scope),
  ]).pipe(
    map(([actions, defaultView, config]) => {
      // todo move to config
      const additionalDef = config.list.system.find((def) => def.id === 'contractPartnerName') as ResultDefinitionModel;

      return {
        multiView: {
          defaultView: defaultView,
          emptyResults: {
            message: 'Could not find any Rights Contracts',
            icon: 'copyright',
          },
        },
        tableAdvanced: {
          actions: actions,
          layout: {
            headers: true,
          },
          columns: config.table.getColumns(),
          columnsEnabled: config.table.getVisibleKeys(),
          settingsAction: {
            id: 'settings',
            label: 'common.global.edit_columns',
            icon: 'settings',
            color: 'transparent',
          },
        },
        gridAdvanced: {
          actions: actions,
          content: {
            titlePath: 'name',
            additionalInfo: additionalDef
              ? {
                  label: additionalDef.label,
                  valuePath: additionalDef.results2.objectPath,
                  viewFormat: additionalDef.results2.viewFormat,
                }
              : undefined,
            metadata: config.list.getMetadata(),
          },
        },
      };
    }),
  );

  setPreferredTableView($event: DefaultMultiViewType) {
    this.viewSettingsService.saveDefaultViewFor(TABLE_TYPE.CONTRACT, $event);
  }

  customActionHandler(event: { key: string; item?: ContractListItem }): boolean {
    switch (event.key) {
      case 'create':
        this.rightsContractsActionsService.createDialog();
        return true;
      case 'settings':
        this.matDialog.open(ColumnsConfigDialogComponent, { data: { scope: this.scope } });
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.editDialog(event.item.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.deleteDialog(event.item.uuid, event.item.name);
        return true;
      case 'logs':
        if (!event.item) {
          return false;
        }
        this.rightsContractsActionsService.logsDialog(event.item.uuid);
        return true;
      case 'preview':
        if (!event.item) {
          return false;
        }
        this.router.navigate([RIGHTS_CONTRACTS_ROUTER_BASE.ROOT, event.item.uuid]).then();
        return true;
      case 'export':
        this.confirmationDialog
          .open({
            title: 'Please confirm export action',
            message: 'If you confirm, we’ll send you a CSV with applied filters via email shortly.',
          })
          .pipe(
            take(1),
            filter((confirmed) => confirmed),
            withLatestFrom(
              this.rightsContractsResultsMultiDs.values$,
              this.rightsContractsResultsMultiDs.pageIndex$,
              this.rightsContractsResultsMultiDs.pageSize$,
              this.rightsContractsResultsMultiDs.sortBy$,
              this.rightsContractsResultsMultiDs.sortDirection$,
              this.fieldsConfig.getConfiguration$(this.scope),
            ),
            switchMap(([, filters, pageIndex, pageSize, sortBy, sortDirection, fields]) => {
              const paginationObject = Pagination.create({
                page: pageIndex,
                perPage: pageSize,
                orderBy: sortBy,
                orderDir: sortDirection,
              });

              return this.rightsContractsService.export(fields.table.getForExport(), paginationObject, filters);
            }),
          )
          .subscribe({
            next: () =>
              this.toastService.success({
                id: 'export',
                message: 'notifications.export.done',
              }),
            error: () => {
              this.toastService.error({
                id: 'export',
                message: 'notifications.export.failed',
              });
            },
          });
        return false;
    }
    return false;
  }
}
