import { Injectable } from '@angular/core';
import { CollectionModelGet, FlatCollectionViewModel } from '@vdms-hq/api-contract';
import { SingleCollectionService } from '../services/single-collection.service';
import { LocalDataSource, PageableDataSource, PageEvent } from '@vdms-hq/shared';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class SingleCollectionSubDataSource
  extends LocalDataSource<CollectionModelGet | FlatCollectionViewModel>
  implements PageableDataSource
{
  pageSizeOptions$ = this.singleCollectionService.subCollectionsOnlyView$.pipe(
    tap((isSubCollectionsOnlyView) => {
      if (!isSubCollectionsOnlyView) {
        this.pageSize$.next(Number(localStorage.getItem('subcollection-page-size') ?? 2));
      }
    }),
    map((isSubCollectionsOnlyView) => (isSubCollectionsOnlyView ? this.pageSizeOptions : [2, 4, 8])),
  );

  constructor(private singleCollectionService: SingleCollectionService) {
    super(singleCollectionService.subAllData$);
  }

  override pageChange($event: PageEvent) {
    localStorage.setItem('subcollection-page-size', String($event.pageSize));
    super.pageChange($event);
  }
}
