export type AssetType = 'video' | 'audio' | 'image' | 'subtitles' | 'archive' | 'document' | 'other';

export enum AssetTypeEnum {
  VIDEO = 'video',
  AUDIO = 'audio',
  IMAGE = 'image',
  SUBTITLES = 'subtitles',
  ARCHIVE = 'archive',
  DOCUMENT = 'document',
  OTHER = 'other',
}
