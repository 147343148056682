export const QUARANTINE_ALL_ASSET_TYPES = [
  'video',
  'audio',
  'image',
  'subtitle',
  'archive',
  'document',
  'other',
  'font',
] as const;
export type QuarantineAssetType = (typeof QUARANTINE_ALL_ASSET_TYPES)[number];

export type QuarantineConfigRules = {
  [index in QuarantineAssetType]: boolean;
};

export interface QuarantineConfig {
  uuid?: string;
  supplier?: {
    uuid: string;
    name: string;
  };
  suppliers_rules: {
    name: string;
    rules: string[];
  }[];
  group_uuid: string;
  rules: QuarantineConfigRules;
}

export interface QuarantinePostConfig {
  uuid?: string;
  supplier_uuid?: string;
  group_uuid: string;
  rules: QuarantineConfigRules;
}
