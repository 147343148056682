import { inject, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import {
  CLIENT_USER_ENDPOINT,
  GetUserDetailsForUserAdminType,
  PatchChangeUserPoliciesType,
  PostUserToUserType,
  ClientUserFilters,
  CLIENT_USER_POLICIES_ENDPOINT,
} from './client-user-admin.model';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { GetResponsePaginationData } from '../../operators/get-response-data.operator';
import { ApiPaginatedResponse } from '../api.model';
import { PaginationAPIModel } from '../pagination.model';

@Injectable({ providedIn: 'root' })
export class ClientUserAdminService {
  #api = inject(ApiService);

  getAllUsersForGroup(
    pagination?: PaginationAPIModel,
    filters?: Partial<ClientUserFilters>,
  ): Observable<{ data: GetUserDetailsForUserAdminType[]; total: number }> {
    let headers = new HttpHeaders();

    headers = pagination ? pagination.applyToHeaders(headers) : headers;

    headers = filters?.policy ? headers.append('policy', filters.policy) : headers;
    headers = filters?.app ? headers.append('app', filters.app) : headers;
    headers = filters?.text ? headers.append('text', filters.text) : headers;
    headers = filters?.keyword ? headers.append('text', filters.keyword) : headers;

    return this.#api
      .get<ApiPaginatedResponse<GetUserDetailsForUserAdminType>>(CLIENT_USER_ENDPOINT, { headers })
      .pipe(GetResponsePaginationData);
  }

  getUserForGroup(uuid: string): Observable<GetUserDetailsForUserAdminType> {
    return this.#api.get(`${CLIENT_USER_ENDPOINT}/${uuid}`);
  }

  createUserForGroup(payload: PostUserToUserType) {
    return this.#api.post(CLIENT_USER_ENDPOINT, payload);
  }

  deleteUserFromGroup(uuid: string): Observable<void> {
    return this.#api.delete(`${CLIENT_USER_ENDPOINT}/${uuid}`);
  }

  updateUsersForGroup(payload: PatchChangeUserPoliciesType): Observable<GetUserDetailsForUserAdminType[]> {
    return this.#api.patch(CLIENT_USER_POLICIES_ENDPOINT, payload);
  }
}
