import { Component, OnInit, inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIButtonModule, UIFormModule, UILayoutModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { take, EMPTY } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  POSSIBLE_CASINGS,
  FilenameConvention,
  SeparatorType,
  Separator,
  GetFilenameConvention,
  AssetTypeEnum,
} from '@vdms-hq/api-contract';
import { SelectOption, Loadable, Destroyable } from '@vdms-hq/shared';
import { ToastService } from '@vdms-hq/toast';
import { MetadataRecognitionService } from '../../logic/metadata-recognition.service';
import { FilenameConventionUtils } from '../../logic/filename-convention-utils';
import { catchError } from 'rxjs/operators';

type FormGroupType<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

type FilenameConventionFormGroup = FormGroupType<Omit<GetFilenameConvention, 'uuid'>> & {
  uuid: FormControl<GetFilenameConvention['uuid'] | null | undefined>;
};

@Component({
  selector: 'vdms-hq-filename-conventions',
  templateUrl: './filename-conventions.component.html',
  styleUrls: ['./filename-conventions.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UILayoutModule, TranslateModule, UIButtonModule, UIFormModule],
  providers: [],
})
export class FilenameConventionsComponent extends Loadable(Destroyable()) implements OnInit {
  private metadataRecognitionService = inject(MetadataRecognitionService);
  private toastService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);

  conventions$ = this.metadataRecognitionService.filenameConventions$;
  loading = false;
  form = new FormGroup({
    conventions: new FormArray<FormGroup>([]),
  });
  separatorSelectOptions: SelectOption<SeparatorType>[] = [
    { key: Separator.SPACE, label: 'space' },
    { key: Separator.UNDERSCORE, label: 'underscore' },
    { key: Separator.HYPHEN, label: 'hyphen' },
    { key: Separator.NONE, label: 'none' },
  ];
  defaultForSelectOptions: SelectOption[] = Object.entries(AssetTypeEnum).map(([key, value]) => ({
    key: value,
    label: value,
  }));

  casingSelectOptions: SelectOption[] = POSSIBLE_CASINGS.map((casing: string) => ({ key: casing, label: casing }));

  get conventionsFormArray() {
    return this.form.controls.conventions;
  }

  ngOnInit() {
    this.conventions$.pipe(take(1)).subscribe((filenameConventions) => {
      filenameConventions.forEach((convention) => this.add(convention));
      this.form.markAsPristine();
      this.form.markAsUntouched();
    });

    // tests, remove later
    const template = '{seriesName[padZero(2)]}-some text-{episodeNumber}_{episodeName[titleCase]}';

    const transformedTemplate = FilenameConventionUtils.transformTemplate({
      template,
      separator: 'underscore',
    } as FilenameConvention);
  }

  add(convention?: GetFilenameConvention) {
    this.conventionsFormArray.push(
      new FormGroup<FilenameConventionFormGroup>({
        uuid: new FormControl<GetFilenameConvention['uuid'] | null | undefined>(convention?.uuid),
        name: new FormControl(convention?.name ?? '', { validators: [Validators.required], nonNullable: true }),
        template: new FormControl(convention?.template ?? '', { validators: [Validators.required], nonNullable: true }),
        separator: new FormControl(convention?.separator ?? 'none', {
          validators: [Validators.required],
          nonNullable: true,
        }),
        default_for: new FormControl(convention?.default_for ?? [], {
          nonNullable: true,
        }),
      }),
    );

    this.cdr.detectChanges();
  }

  remove(index: number) {
    const uuid: string = this.conventionsFormArray.at(index).value.uuid;

    if (!uuid) {
      this.conventionsFormArray.removeAt(index);
      return;
    }

    this.metadataRecognitionService
      .delete(uuid)
      .pipe(
        take(1),
        catchError((error) => {
          this.toastService.error({ id: 'metadata_recognition', message: 'notifications.delete.error' });
          return error;
        }),
      )
      .subscribe(() => {
        this.conventionsFormArray.removeAt(index);
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.delete.done' });
      });
  }

  save(index: number = 0) {
    if (this.conventionsFormArray.invalid || this.loading) {
      return;
    }

    const payload = this.conventionsFormArray.at(index).value;
    let request$;

    if (payload.uuid) {
      request$ = this.#update(payload);
    } else {
      request$ = this.#create(payload);
    }

    request$
      .pipe(
        take(1),
        catchError((error) => {
          this.form.controls.conventions.at(index).setErrors({ server: error.error.data });
          this.toastService.error({ id: 'metadata_recognition', message: error.error.data });
          return EMPTY;
        }),
      )
      .subscribe(() => {
        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.toastService.success({ id: 'metadata_recognition', message: 'notifications.update.done' });
      });
  }

  #create(payload: GetFilenameConvention) {
    return this.metadataRecognitionService.create(payload).pipe(take(1));
  }

  #update(payload: GetFilenameConvention) {
    return this.metadataRecognitionService.update(payload.uuid, payload).pipe(take(1));
  }
}
