import { Injectable } from '@angular/core';
import { LoadableDataSource, LocalDataSource, PageableDataSource, SortableDataSource } from '@vdms-hq/shared';
import { RightsContractsLog } from '@vdms-hq/api-contract';
import { LogsFetcher } from './logs.fetcher';
import { ViewSettingsService } from '@vdms-hq/view-settings';
import { BehaviorSubject, take } from 'rxjs';
import { LogsModel } from '@vdms-hq/logs-modal';
import { ContractsLogsChangesetModel } from './contracts-logs-changset.model';

@Injectable({ providedIn: 'root' })
export class LogDataSource
  extends LocalDataSource<RightsContractsLog>
  implements SortableDataSource, PageableDataSource, LoadableDataSource
{
  isLoading$ = this.logsFetcher.loading$;
  emptyResults$ = this.logsFetcher.emptyResults$;
  selectedItem$ = new BehaviorSubject<LogsModel<ContractsLogsChangesetModel> | null>(null);

  constructor(private logsFetcher: LogsFetcher, private viewSettingsService: ViewSettingsService) {
    super(logsFetcher.logs$);
    this.viewSettingsService.perPageUserSettings$.pipe(take(1)).subscribe((perPage) => {
      this.pageSize$.next(perPage);
    });
  }
}
