import { Routes } from '@angular/router';
import { DefinedFieldsListComponent } from './pages/defined-fields-list/defined-fields-list.component';

export enum FIELDS_ADMIN_ROUTER_BASE {
  ROOT = 'fields',
}

export const FIELDS_ADMIN_ROUTING: Routes = [
  {
    path: '',
    component: DefinedFieldsListComponent,
  },
  {
    path: '**',
    redirectTo: `/${FIELDS_ADMIN_ROUTER_BASE.ROOT}`,
    pathMatch: 'full',
  },
];
