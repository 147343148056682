import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Destroyable, E2eIdDirective, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { Permission } from '@vdms-hq/firebase-contract';
import { ActivatedClientModule, ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import {
  ActionContextLess,
  UIButtonModule,
  DataAction as Action,
  MultipleViewConfiguration,
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
} from '@vdms-hq/ui';
import { Observable, map, takeUntil } from 'rxjs';
import { GetTeamInterface } from '@vdms-hq/api-contract';
import { ClientTeamsListView, ClientTeamsListViewModel } from '../../logic/client-teams.model';
import { ClientTeamsListDataSource } from '../../logic/client-teams-list-ds.service';
import { ClientTeamsActionsService } from '../../logic/client-teams-actions.service';

@Component({
  selector: 'vdms-hq-client-teams-list',
  standalone: true,
  imports: [
    CommonModule,
    UIButtonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    DataPresentationHeaderComponent,
    MultipleDataPresentationComponent,
    E2eIdDirective,
  ],
  templateUrl: './client-teams-list.component.html',
})
export class ClientTeamsListComponent extends Destroyable(WithPermissions()) {
  private activatedClientService: ActivatedClientService = inject(ActivatedClientService);
  private teamsActionsService = inject(ClientTeamsActionsService);
  public dataSource = inject(ClientTeamsListDataSource);

  headerActions$: Observable<ActionContextLess[]> = this.activatedClientService.permissions$.pipe(
    takeUntil(this.isDestroyed$),
    map((permissions) => [
      {
        key: 'create',
        label: 'common.client-teams.table.create',
        e2eId: 'create-team-button',
        hiddenIf: () => !permissions.includes(Permission.CREATE_TEAMS),
      },
    ]),
  );

  #actions: Observable<Action<GetTeamInterface>[]> = this.activatedClientService.permissions$.pipe(
    takeUntil(this.isDestroyed$),
    map((permissions) => [
      {
        key: 'preview',
        label: 'common.client-teams.table.preview',
        icon: 'preview',
        e2eId: 'preview-team-button',
        hiddenIf: () => permissions.includes(Permission.EDIT_TEAMS),
      },
      {
        key: 'edit',
        label: 'common.client-teams.table.edit',
        icon: 'edit',
        e2eId: 'edit-team-button',
        hiddenIf: () => !permissions.includes(Permission.EDIT_TEAMS),
      },
      {
        key: 'delete',
        label: 'common.client-teams.table.delete',
        icon: 'delete',
        e2eId: 'delete-team-button',
        hiddenIf: () => !permissions.includes(Permission.DELETE_TEAMS),
      },
    ]),
  );

  viewConfiguration$: Observable<MultipleViewConfiguration<ClientTeamsListView>> = this.#actions.pipe(
    map((actions) => ({
      multiView: {
        defaultView: 'tableAdvanced',
        emptyResults: { message: 'common.client-teams.table.empty', icon: 'group_work' },
      },
      tableAdvanced: {
        actions,
        columnsEnabled: ['name', 'collectionsShared', 'usersCount', 'createdBy', 'actions'],
        columns: [
          {
            id: 'name',
            label: 'common.client-teams.table.name',
            valuePath: 'name',
            sortable: false,
          },
          {
            id: 'collectionsShared',
            label: 'common.client-teams.table.collections',
            valuePath: 'sharedCollectionsNames',
            viewFormat: {
              maxVisibleValues: 3,
            },
            foldValues: true,
            sortable: false,
          },
          {
            id: 'usersCount',
            label: 'common.client-teams.table.users',
            valuePath: 'usersCount',
            viewFormat: {
              modifiers: {
                asNumberWithZero: true,
              },
            },
            sortable: false,
          },
          {
            id: 'createdBy',
            label: 'common.client-teams.table.created',
            valuePath: 'createdByName',
            sortable: false,
          },
          {
            id: 'actions',
            type: 'actions',
          },
        ],
      },
    })),
  );

  readonly filtersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'common.client-teams.table.filter',
      resource: [ResourceModel.CLIENT_TEAM],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'name',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-teams'],
    },
  ];

  customActionHandler(event: { key: string; item?: ClientTeamsListViewModel }): boolean {
    switch (event.key) {
      case 'create':
        this.teamsActionsService.createEditTeamAction();
        return true;
      case 'edit':
        if (!event.item) {
          return false;
        }
        this.teamsActionsService.createEditTeamAction(event.item.uuid);
        return true;
      case 'delete':
        if (!event.item) {
          return false;
        }
        this.teamsActionsService.deleteTeamAction(event.item.uuid, event.item.name);
        return true;
      case 'preview':
        if (!event.item) {
          return false;
        }
        this.teamsActionsService.createEditTeamAction(event.item.uuid, true);
        return true;
    }
    return false;
  }
}
