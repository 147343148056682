import { FieldsScopeKey } from '@vdms-hq/shared';
import { AnySidebarItem } from '@vdms-hq/ui';
import { FIELDS_BASE_PATHS } from './base-paths';
import { AuthorizedClient } from '@vdms-hq/activated-client';

type ConfigurableScopes = Exclude<FieldsScopeKey, '_admin-all-fields' | '_any'>;

export type ResultsAdminConfig = {
  key: ConfigurableScopes;
  name: string;
  hasTable: boolean;
  hasList: boolean;
  hasFilters: boolean;
};
export type FieldsAdminConfig = ResultsAdminConfig[];

export const resultsAdminConfig: FieldsAdminConfig = [
  {
    key: 'default',
    name: 'common.admin.sidebar.tables_and_lists.assets_default',
    hasFilters: false,
    hasList: true,
    hasTable: true,
  },
  {
    key: 'metadata-snapshots',
    name: 'common.admin.sidebar.tables_and_lists.metadata_snapshots',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'metadata-recognition',
    name: 'common.admin.sidebar.tables_and_lists.metadata_recognition',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'deleted',
    name: 'common.admin.sidebar.tables_and_lists.assets_deleted',
    hasList: true,
    hasTable: true,
    hasFilters: true,
  },
  {
    key: 'quarantined',
    name: 'common.admin.sidebar.tables_and_lists.assets_quarantined',
    hasList: true,
    hasTable: true,
    hasFilters: true,
  },
  {
    key: 'browse',
    name: 'common.admin.sidebar.tables_and_lists.assets_browse',
    hasList: true,
    hasTable: true,
    hasFilters: true,
  },
  {
    key: 'cart',
    name: 'common.admin.sidebar.tables_and_lists.assets_cart',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'collections',
    name: 'common.admin.sidebar.tables_and_lists.assets_collections',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'shared-packs',
    name: 'common.admin.sidebar.tables_and_lists.assets_shared_packs',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'orders',
    name: 'common.admin.sidebar.tables_and_lists.assets_orders',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'upload-jobs',
    name: 'common.admin.sidebar.tables_and_lists.assets_upload_jobs',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'content-corner',
    name: 'common.admin.sidebar.tables_and_lists.assets_connect2',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'licensed-package',
    name: 'common.admin.sidebar.tables_and_lists.assets_licensed_package',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'preview-request',
    name: 'common.admin.sidebar.tables_and_lists.assets_preview_request',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'purged-assets',
    name: 'common.admin.sidebar.tables_and_lists.assets_purged',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'browse-library',
    name: 'common.admin.sidebar.tables_and_lists.assets_browse_library',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'map',
    name: 'common.admin.sidebar.tables_and_lists.assets_map',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-rights-partners',
    name: 'common.admin.sidebar.tables_and_lists.rights_partners',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-rights-contracts',
    name: 'common.admin.sidebar.tables_and_lists.rights_contracts',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'other-licensed-packages',
    name: 'common.admin.sidebar.tables_and_lists.licensed_packages',
    hasTable: true,
    hasList: true,
    hasFilters: false,
  },
  {
    key: 'other-licensed-packages-connect2',
    name: 'common.admin.sidebar.tables_and_lists.licensed_packages_connect2',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-collections',
    name: 'common.admin.sidebar.tables_and_lists.collections',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-suppliers',
    name: 'common.admin.sidebar.tables_and_lists.suppliers',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-users',
    name: 'common.admin.sidebar.tables_and_lists.users',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-teams',
    name: 'common.admin.sidebar.tables_and_lists.teams',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-orders',
    name: 'common.admin.sidebar.tables_and_lists.orders',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-orders-approval',
    name: 'common.admin.sidebar.tables_and_lists.orders_approval',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-systems',
    name: 'common.admin.sidebar.tables_and_lists.systems',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-field-options',
    name: 'common.admin.sidebar.tables_and_lists.field_options',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-ratings',
    name: 'common.admin.sidebar.tables_and_lists.ratings',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-preview-requests',
    name: 'common.admin.sidebar.tables_and_lists.preview_requests',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-policies',
    name: 'common.admin.sidebar.tables_and_lists.policies',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-logs',
    name: 'common.admin.sidebar.tables_and_lists.logs',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-delivery-destinations',
    name: 'common.admin.sidebar.tables_and_lists.delivery_destinations',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-discounts',
    name: 'common.admin.sidebar.tables_and_lists.discounts',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-dashboards',
    name: 'common.admin.sidebar.tables_and_lists.dashboard',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-clients',
    name: 'common.admin.sidebar.tables_and_lists.clients',
    hasTable: false,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-shared-packs',
    name: 'common.admin.sidebar.tables_and_lists.shared_packs',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-media-pulse-orders',
    name: 'common.admin.sidebar.tables_and_lists.mediapulse_orders',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
  {
    key: 'other-delivery-pack',
    name: 'common.admin.sidebar.tables_and_lists.delivery_pack',
    hasTable: true,
    hasList: false,
    hasFilters: false,
  },
];

const getSidebarFor = (scope: ConfigurableScopes): AnySidebarItem | null => {
  const configItem = resultsAdminConfig.find((item) => item.key === scope);
  if (!configItem) {
    return null;
  }

  return {
    name: configItem.name,
    routerLink: `/admin/${FIELDS_BASE_PATHS.PAGE_RESULTS_SETTINGS}/${scope}`,
  };
};

export const resultsSettingsNav = (client?: AuthorizedClient) =>
  [
    getSidebarFor('default'),
    getSidebarFor('browse'),
    getSidebarFor('browse-library'),
    getSidebarFor('deleted'),
    getSidebarFor('quarantined'),
    'separator',
    getSidebarFor('cart'),
    getSidebarFor('other-orders'),
    getSidebarFor('other-orders-approval'),
    getSidebarFor('orders'),
    getSidebarFor('other-media-pulse-orders'),
    getSidebarFor('other-shared-packs'),
    getSidebarFor('shared-packs'),
    client?.integrations.salesforce && getSidebarFor('other-preview-requests'),
    client?.integrations.salesforce && getSidebarFor('preview-request'),
    'separator',
    getSidebarFor('other-collections'),
    getSidebarFor('collections'),
    'separator',
    getSidebarFor('other-rights-partners'),
    getSidebarFor('other-rights-contracts'),
    getSidebarFor('other-licensed-packages'),
    getSidebarFor('licensed-package'),
    getSidebarFor('other-licensed-packages-connect2'),
    getSidebarFor('content-corner'),
    'separator',
    getSidebarFor('other-delivery-pack'),
    getSidebarFor('upload-jobs'),
  ].filter((item) => !!item) as AnySidebarItem[];
