import { Pipe, PipeTransform } from '@angular/core';
import { RowViewConfig } from '../logic/models';

@Pipe({
  name: 'disableIfRow',
  standalone: true,
})
export class DisableIfRowPipe<T> implements PipeTransform {
  transform(config: RowViewConfig<T>, item: T): { disabled: boolean } {
    const disabledIf = config?.disabledIf?.condition && config.disabledIf.condition(item);
    return { disabled: Boolean(disabledIf) };
  }
}
