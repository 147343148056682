import { inject, Injectable } from '@angular/core';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { AssetFlatCartItem, CartApiService, CartSummary, ORDER_TYPE, SortOptions } from '@vdms-hq/api-contract';
import { CartAssetViewModel, CartStateService } from '@vdms-hq/cart-core';
import { DataProviderService } from '@vdms-hq/selectors';
import {
  FieldConfigId,
  FieldsScopeKey,
  ResultDefinitionModel,
  SelectionManager,
  TableViewDataSource,
} from '@vdms-hq/shared';
import { StorageUrlService } from '@vdms-hq/storage';
import { ToastService } from '@vdms-hq/toast';
import { BehaviorSubject, combineLatest, of, shareReplay, switchMap, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { CartCheckoutFormService } from '../services/cart-checkout-form.service';
import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { FieldsFetcherService } from '@vdms-hq/fields';

@Injectable({ providedIn: 'root' })
export class CartDataSource extends RouterParamsPagination implements TableViewDataSource<CartAssetViewModel> {
  private cartApiService = inject(CartApiService);
  private cartCheckoutFormService = inject(CartCheckoutFormService);
  private toastService = inject(ToastService);
  private dataProvider = inject(DataProviderService);
  private storageUrlService = inject(StorageUrlService);
  private activatedClientService = inject(ActivatedClientService);
  private cartStateService = inject(CartStateService);
  private fieldsConfigService = inject(FieldsFetcherService);
  scope: FieldsScopeKey = 'cart';

  isLoading$ = this.cartStateService.isUpdating$;
  total$ = new BehaviorSubject<number>(0);

  sortBy$ = new BehaviorSubject<FieldConfigId>('');
  sortDirection$ = new BehaviorSubject<SortOptions['direction']>('asc');
  selection: SelectionManager<CartAssetViewModel>;

  cartSummary$ = new BehaviorSubject<CartSummary | null>(null);

  allData$ = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.fieldsConfigService.getConfiguration$(this.scope).pipe(take(1)),
    this.cartStateService.refresh$.pipe(
      tap(() => {
        this.selection.clear();
        this.isLoading$.next(true);
      }),
    ),
    this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$,
  ]).pipe(
    switchMap(([page, perPage, orderBy, orderDir, config]) => {
      const sort: ResultDefinitionModel | undefined = config.table.visible.find((field) => {
        return field.id == orderBy;
      });

      return this.cartApiService
        .getCartAssets({
          pagination: {
            page,
            perPage,
            orderBy: sort?.results2?.sortObjectPath ?? sort?.results2?.objectPath ?? orderBy,
            orderDir,
          },
        })
        .pipe(
          switchMap((res) => {
            const data = res.data.map((item: AssetFlatCartItem) =>
              CartAssetViewModel.fromCartItem(item, {
                dataProvider: this.dataProvider,
                storageUrlService: this.storageUrlService,
                activatedClientService: this.activatedClientService,
              }),
            );
            if (this.cartCheckoutFormService.deliveryMethod) {
              return this.getCartSummary().pipe(switchMap(() => of({ ...res, data })));
            } else {
              return of({ ...res, data });
            }
          }),
          catchError((err) => {
            this.isLoading$.next(false);
            this.toastService.error({ id: 'cart', message: err.message });

            return [];
          }),
        );
    }),
    map(({ data, total }) => {
      this.total$.next(total);
      this.isLoading$.next(false);
      return data;
    }),
    shareReplay(1),
  );

  connection$ = this.allData$;

  emptyResults$ = combineLatest([this.isLoading$, this.total$]).pipe(
    map(([isLoading, total]) => {
      return isLoading === false && total === 0;
    }),
  );

  constructor() {
    super();
    this.selection = new SelectionManager<CartAssetViewModel>(this, (item) => item.context?.uuid);
  }

  sortChange($event: { active: string; direction: SortOptions['direction'] }) {
    this.isLoading$.next(true);
    this.sortBy$.next($event.active);
    this.sortDirection$.next($event.direction);
    this.changePageIndex$.next(0);
  }

  getCartSummary(orderType?: ORDER_TYPE) {
    this.isLoading$.next(true);
    // discount code if null - no discount will be applied - nor department nor normal discount
    return this.cartApiService
      .getCartSummary(
        orderType ?? this.cartCheckoutFormService.orderType,
        this.cartCheckoutFormService.destinationsConfigs ?? undefined,
        this.cartStateService.discountCode === null
          ? null
          : this.cartStateService.department.uuid
          ? this.cartStateService.discountCode ?? undefined
          : this.cartStateService.discountCode,
        this.cartCheckoutFormService.jobsFormValid$.value ? this.cartCheckoutFormService.workflowJobs : undefined,
        this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$.value
          ? this.cartCheckoutFormService.processSummaryAfterDownloadLimitChanged$.value
          : undefined,
        this.cartStateService.department.uuid ?? undefined,
      )
      .pipe(
        tap((summary) => {
          this.cartSummary$.next(summary);

          // discount code if null - no discount will be applied - nor department nor normal discount
          this.cartStateService.discount$.next({
            discountCode:
              this.cartStateService.discountCode === null
                ? null
                : this.cartStateService.department.uuid
                ? this.cartStateService.discountCode ?? undefined
                : this.cartStateService.discountCode,
            discountStatus: summary.discount_status,
            discountName: summary.discount_name,
          });
          this.isLoading$.next(false);
        }),
        catchError((err) => {
          this.toastService.error({ id: 'cart', message: err.message });
          this.isLoading$.next(false);
          return throwError(() => err);
        }),
      );
  }
}
