import { Injectable, inject } from '@angular/core';
import {
  FilenameConventionsService,
  GetFilenameConvention,
  AnalyzeMetadataFilenameConvention,
  PostFilenameConvention,
} from '@vdms-hq/api-contract';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MetadataRecognitionService {
  private filenameConventionsService = inject(FilenameConventionsService);

  filenameConventions$ = this.filenameConventionsService.getAll();
  analyzeMetadataRecognition$ = this.filenameConventions$.pipe(
    map<GetFilenameConvention[], AnalyzeMetadataFilenameConvention[]>((conventions) =>
      conventions.map((convention) => ({
        uuid: convention.uuid,
        defaultFor: convention.default_for,
      })),
    ),
  );

  create = (data: PostFilenameConvention) => this.filenameConventionsService.create(data);
  delete = (uuid: string) => this.filenameConventionsService.delete(uuid);

  update = (uuid: string, data: PostFilenameConvention) => this.filenameConventionsService.edit(uuid, data);
}
