import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLabel',
  standalone: true,
})
export class FormatLabelPipe implements PipeTransform {
  transform(value: string): string {
    return String(value).charAt(0).toUpperCase() + String(value).slice(1).replace(/_/g, ' ');
  }
}
