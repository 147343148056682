import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule, DatePipe } from '@angular/common';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';
import { DestroyComponent, SelectOption } from '@vdms-hq/shared';
import { FormControl, Validators } from '@angular/forms';
import { ReindexAutomatizationRequestModel, ReindexAutomatizationService } from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { ReindexAutomatizationViewModel } from '../../logic/reindex.class';

@Component({
  selector: 'vdms-hq-update-reindex-dialog',
  templateUrl: './update-reindex-dialog.component.html',
  styleUrls: ['./update-reindex-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UIDialogWrapperModule, DragDropModule, TranslateModule, UIButtonModule, UIFormModule],
  providers: [],
})
export class UpdateReindexDialogComponent extends DestroyComponent implements OnInit {
  readonly REINDEX_NAME_PATTERN = 'assets-{{clientName}}-{{dateNow}}';
  readonly SUFFIX_VALIDATION = /^[a-z0-9_\-+]*$/;
  readonly PREFIX_VALIDATION = /^(?![-_+\s])[a-z0-9_\-+]*$/;
  readonly NAME_PATTERN_VALIDATION = /^(?![-_+])[a-z0-9_\-+]{1,255}$/;
  radioSelectOptions: SelectOption[] = [
    {
      key: 'prefix',
      label: 'Add prefix',
    },
    {
      key: 'suffix',
      label: 'Add suffix',
    },
  ];
  radioControl = new FormControl<string>('suffix', Validators.required);
  formatterValue = new FormControl<string>('', [Validators.pattern(this.SUFFIX_VALIDATION)]);
  requestedAtControl = new FormControl<string | null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entities: ReindexAutomatizationViewModel[]; sync: boolean },
    private datePipe: DatePipe,
    private ref: MatDialogRef<UpdateReindexDialogComponent>,
    private reindexService: ReindexAutomatizationService,
    private toast: ToastService,
  ) {
    super();
  }

  get reindexView() {
    return !this.data.sync ?? false;
  }

  ngOnInit(): void {
    this.#listenRadioChanges();
  }

  close() {
    this.ref.close();
  }

  get namePreviewInvalid() {
    const names = this.data.entities.map((entity) => this.#nameToIndexName(entity.props.label as string));
    return names.some((name) => !this.NAME_PATTERN_VALIDATION.test(name));
  }

  reindex() {
    const jobs = this.data.entities.map((entity) =>
      this.reindexView
        ? {
            index_name: this.#nameToIndexName(entity.props.label as string),
            group_uuid: entity.props.key as string,
          }
        : { group_uuid: entity.props.key as string },
    );

    if (!jobs?.length) {
      return;
    }

    const requestedAt = this.requestedAtControl.value ?? undefined;
    const partialIfSyncView = !this.reindexView;

    const payload: ReindexAutomatizationRequestModel = {
      jobs,
      is_partial: partialIfSyncView,
      requested_at: requestedAt,
    };

    this.reindexService.post(payload).subscribe(() => {
      this.close();
      this.toast.success({ id: 'reindex', message: 'Reindex has been started.' });
    });
  }

  #nameToIndexName(name: string) {
    const date = this.datePipe.transform(new Date(), 'yyMMdd');
    name = name.toLowerCase().replace(/[ \-_.]/g, '') + '-' + date;
    const formatterValue = this.formatterValue.value;
    return this.radioControl.value === 'prefix'
      ? (formatterValue ?? '') + (formatterValue ? '-assets' : 'assets') + '-' + name
      : 'assets-' + name + (formatterValue ? `-${formatterValue}` : '');
  }

  #listenRadioChanges() {
    this.formatterValue.reset();

    this.radioControl.valueChanges.pipe(this.takeUntilDestroyed()).subscribe((type) => {
      switch (type) {
        case 'prefix':
          this.formatterValue.setValidators([Validators.pattern(this.PREFIX_VALIDATION)]);
          break;
        case 'suffix':
          this.formatterValue.setValidators([Validators.pattern(this.SUFFIX_VALIDATION)]);
          break;
      }
      this.formatterValue.updateValueAndValidity();
    });
  }
}
