import { ParamsPagination } from '@vdms-hq/view-settings';
import { TableAdvancedDataSource } from '@vdms-hq/ui';
import { RefreshService, SelectOption } from '@vdms-hq/shared';
import { inject, Injectable } from '@angular/core';
import {
  ClientUserAdminService,
  ClientUserAdminView,
  GetUserDetailsForUserAdminType,
  PaginationAPIModel as Pagination,
  VidaAppType,
} from '@vdms-hq/api-contract';
import { ToastService } from '@vdms-hq/toast';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';

export interface ClientUserAdminAddData {
  key: string;
  label: string;
  email: string;
  appAccesses: string[];
}

export type FiltersView = {
  app: FormControl<VidaAppType | null>;
  keyword: FormControl<string>;
};

@Injectable({ providedIn: 'root' })
export class ClientUserAdminAddDataSource
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<FiltersView>, GetUserDetailsForUserAdminType>
{
  private clientUserAdminService = inject(ClientUserAdminService);
  private refreshService = inject(RefreshService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  readonly defaultPerPage = 192;
  readonly defaultPage = 0;

  isLoading$ = new BehaviorSubject(true);

  refresh$ = this.refreshService.refresh$;

  filters = new FormGroup<FiltersView>({
    app: new FormControl<VidaAppType | null>(null),
    keyword: new FormControl<string>('', { nonNullable: true }),
  });

  sortBy$ = new BehaviorSubject<string>('name');
  sortDirection$ = new BehaviorSubject<'asc' | 'desc'>('asc');

  filters$ = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(300),
    switchMap(() => {
      this.changePageIndex$.next(0);
      return of(this.filters.value);
    }),
  );

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.sortBy$,
    this.sortDirection$,
    this.filters$,
    this.activatedClientService.clientIdDefinite$,
  ]).pipe(
    switchMap(([, page, perPage, orderBy, orderDir, filters, clientId]) => {
      this.isLoading$.next(true);
      const pagination = Pagination.create({ page, perPage, orderBy, orderDir });

      return this.clientUserAdminService.getAllUsersForGroup(pagination, filters);
    }),
    shareReplay(1),
  );
  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<ClientUserAdminAddData[]> = this.responseData$.pipe(
    map((response) => response.data.map((user) => ClientUserAdminView.fromClientUserType(user))),
    map((data) =>
      data.map((user) => ({
        key: user.uuid,
        label: user.name,
        email: user.email,
        appAccesses: user.app_accesses,
      })),
    ),
    tap(() => this.isLoading$.next(false)),
    catchError((error) => {
      this.toastService.error({
        id: 'error',
        message: 'An error occurred while fetching client users data. Please try again.',
      });
      return EMPTY;
    }),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<FiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh() {
    if (this.filters.dirty || this.changePageIndex$.value !== 0) {
      this.filters.controls.app.setValue(null);
      this.filters.controls.keyword.setValue('');
      this.changePageIndex$.next(0);
    }
  }
}
