import { RouterParamsPagination } from '@vdms-hq/view-settings';
import { MultipleViewDataSource } from '@vdms-hq/ui';
import { PaginationAPIModel as Pagination, TeamsService } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Loadable, RefreshService } from '@vdms-hq/shared';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastService } from '@vdms-hq/toast';
import { ClientTeamsFiltersView, ClientTeamsListView, ClientTeamsListViewModel } from './client-teams.model';
import { ActivatedClientService } from '@vdms-hq/activated-client';

@Injectable({ providedIn: 'root' })
export class ClientTeamsListDataSource
  extends Loadable(RouterParamsPagination)
  implements MultipleViewDataSource<ClientTeamsListViewModel>
{
  private refreshService = inject(RefreshService);
  private teamsService = inject(TeamsService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  refresh$ = new BehaviorSubject(new Date());

  filters = new FormGroup({
    keyword: new FormControl<string>(''),
  });

  values$: Observable<ClientTeamsFiltersView> = this.filters.valueChanges.pipe(
    startWith(this.filters.value),
    debounceTime(400),
    switchMap(() => {
      const formValue = this.filters.value;
      const filters = <ClientTeamsFiltersView>{};

      if (formValue?.keyword) {
        filters.keyword = formValue.keyword;
      }

      this.changePageIndex$.next(0);
      return of(filters);
    }),
  );

  responseData$ = combineLatest([
    this.pageIndex$,
    this.pageSize$,
    this.values$,
    this.refresh$,
    this.refreshService.refresh$,
    this.activatedClientService.clientIdDefinite$,
  ]).pipe(
    tap(() => this.startLoading()),
    switchMap(([page, perPage, filters]) => {
      const headers = Pagination.create({
        page,
        perPage,
      });
      return this.teamsService
        .getTeams(headers, filters)
        .pipe(catchError((err: HttpErrorResponse) => this.#errorHandler(err)));
    }),
    shareReplay(1),
  );

  override emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<ClientTeamsListViewModel[]> = this.responseData$.pipe(
    map((response) => response.data.map((team) => ClientTeamsListView.fromClientTeam(team))),
    tap(() => this.stopLoading()),
  );

  connection$: Observable<ClientTeamsListViewModel[]> = this.allData$;

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'client-teams', message: 'Error fetching client Teams' });
    this.stopLoading();
    this.emptyResults$ = of(true);
    console.error(err);
    return EMPTY;
  }
}
