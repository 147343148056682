<div [formGroup]="form" [bdcWalkTriggerFor]="tourConfig.advancedSearch || defaultPopUp">
  <div class="header">
    <div class="breadcrumbs-wrapper">
      <vdms-hq-ui-layout-breadcrumbs
        size="medium"
        [loading]="(facetsEnabled && !facets) || loading"
        [breadcrumbs]="[{ name: ('pages.browse.advanced_filters' | translate), clickable: true }]"
      ></vdms-hq-ui-layout-breadcrumbs>
      <mat-icon [matTooltip]="'pages.browse.settings_filters' | translate" (click)="popFiltersConfigDialog()"
        >settings
      </mat-icon>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="dynamic-filters-container">
    <vdms-hq-ui-form-input-accordions *ngIf="!facetsEnabled || facets">
      <ng-container *ngFor="let definition of filterConfig">
        <ng-container *ngIf="form.controls[definition.id]; let ngControl">
          <ng-container [ngSwitch]="definition.filters.type">
            <ng-container *ngSwitchCase="FilterType.MASTER_TEXT">
              <vdms-hq-ui-form-input-text-area
                class="textarea--no-padding"
                placeholder="Keywords"
                cdkOverlayOrigin
                #trigger="cdkOverlayOrigin"
                [formControl]="$formControl(ngControl)"
                [minRows]="1"
                [label]="definition.label"
                [suffixConfig]="$suffixConfig(advancedSearchState.suffixConfig)"
                [disableEnterBreakline]="true"
                (clickSuffix)="advancedSearchOpen = !advancedSearchOpen"
              ></vdms-hq-ui-form-input-text-area>

              <ng-template
                cdkConnectedOverlay
                cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
                [cdkConnectedOverlayOrigin]="trigger"
                [cdkConnectedOverlayPositions]="advancedSearchState.overlayPositionConfig"
                [cdkConnectedOverlayOpen]="advancedSearchOpen"
                [cdkConnectedOverlayHasBackdrop]="true"
                (backdropClick)="advancedSearchOpen = false"
              >
                <vdms-hq-advanced-search-overlay></vdms-hq-advanced-search-overlay>
              </ng-template>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.TEXT">
              <vdms-hq-ui-form-input-text
                [formControl]="$formControl(ngControl)"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [mask]="definition.filters.mask ?? ''"
                [label]="definition.label"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-text>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.CHIPS">
              <vdms-hq-ui-form-input-chips
                [formControl]="$formControl(ngControl)"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [label]="definition.label"
                [chipsPosition]="'right'"
                [chipsLimit]="1000"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-chips>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.SELECTOR">
              <vdms-hq-selector
                [formControl]="$formControl(ngControl)"
                [label]="definition.label"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [nullValue]="false"
                [virtualScroll]="true"
                [sourceType]="definition.sourceListKey ?? ''"
                [newField]="definition.featureDate | newFeature"
                *ngIf="!facetsEnabled || !definition.filters.aggregationKey"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-selector>
              <vdms-hq-ui-form-input-select
                [formControl]="$formControl(ngControl)"
                [label]="definition.label"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [multiple]="!definition.filters.singleValue"
                [virtualScroll]="true"
                [selectOptions]="facets?.[definition.id]?.data ?? []"
                [newField]="definition.featureDate | newFeature"
                *ngIf="facetsEnabled && definition.filters.aggregationKey && facets?.[definition.id]?.data?.length"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-select>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.SELECTOR_GROUP">
              <vdms-hq-ui-form-input-select-group
                [selectOptions]="$selectOptionGroup(facets?.[definition.id]?.data)"
                [formControl]="$formControl(ngControl)"
                [label]="definition.label"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                *ngIf="facetsEnabled && definition.filters.aggregationKey && facets?.[definition.id]?.data?.length"
                [multiple]="true"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-select-group>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.TEXT_AUTOCOMPLETE">
              <vdms-hq-autocomplete
                [formControl]="$formControl(ngControl)"
                [label]="definition.label"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [sourceType]="$selectorSourceType(definition.sourceListKey)"
                *ngIf="!facetsEnabled || !definition.filters.aggregationKey"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-autocomplete>
              <vdms-hq-ui-form-input-text
                [formControl]="$formControl(ngControl)"
                [label]="definition.label"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [autocompleteList]="facets?.[definition.id]?.data ?? []"
                *ngIf="facetsEnabled && definition.filters.aggregationKey && facets?.[definition.id]?.data?.length"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-text>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.NUMBER">
              <vdms-hq-ui-form-input-number
                [formControl]="$formControl(ngControl)"
                [withTouchedIndicator]="withTouchedIndicator"
                [withFooter]="withFooter"
                [label]="definition.label"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
              </vdms-hq-ui-form-input-number>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.TIMECODE_RANGE">
              <vdms-hq-ui-form-input-accordion
                [wrappedFormControl]="$formControl(ngControl)"
                *ngIf="facets?.[definition.id]?.data?.length"
                [label]="definition.label"
                [optionsLength]="definition.filters.aggregationKey ? facets?.[definition.id]?.data?.length ?? 0 : 0"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
                <vdms-hq-form-input-timecode-range
                  [range]="$facets(facets?.[definition.id])"
                  [predefinedRangesOptions]="facets?.[definition.id]?.data ?? []"
                  [formControl]="$formControl(ngControl)"
                >
                </vdms-hq-form-input-timecode-range>
              </vdms-hq-ui-form-input-accordion>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.SIZE_RANGE">
              <vdms-hq-ui-form-input-accordion
                *ngIf="facets?.[definition.id]?.data?.length"
                [wrappedFormControl]="$formControl(ngControl)"
                [label]="definition.label"
                [optionsLength]="definition.filters.aggregationKey ? facets?.[definition.id]?.data?.length ?? 0 : 0"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
                <vdms-hq-form-input-size-range
                  [formControl]="$formControl(ngControl)"
                  [range]="$rangeBoundaries(facets?.[definition.id])"
                  [withTouchedIndicator]="withTouchedIndicator"
                  [predefinedRangesOptions]="facets?.[definition.id]?.data ?? []"
                  [withFooter]="withFooter"
                  [enabledUnits]="['B', 'kB', 'MB', 'GB']"
                >
                </vdms-hq-form-input-size-range>
              </vdms-hq-ui-form-input-accordion>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.BITRATE_RANGE">
              <vdms-hq-ui-form-input-accordion
                *ngIf="facets?.[definition.id]?.data?.length"
                [wrappedFormControl]="$formControl(ngControl)"
                [label]="definition.label"
                [optionsLength]="definition.filters.aggregationKey ? facets?.[definition.id]?.data?.length ?? 0 : 0"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
                <vdms-hq-form-input-bitrate-range
                  [withTouchedIndicator]="withTouchedIndicator"
                  [range]="$rangeBoundaries(facets?.[definition.id])"
                  [withFooter]="withFooter"
                  [predefinedRangesOptions]="facets?.[definition.id]?.data ?? []"
                  [formControl]="$formControl(ngControl)"
                  [enabledUnits]="['bps', 'kbps', 'Mbps']"
                >
                </vdms-hq-form-input-bitrate-range>
              </vdms-hq-ui-form-input-accordion>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.DATEPICKER_RANGE">
              <vdms-hq-ui-form-input-accordion
                [wrappedFormControl]="$formControl(ngControl)"
                [label]="definition.label"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
                <vdms-hq-form-input-date-range
                  [formControl]="$formControl(ngControl)"
                  [withTouchedIndicator]="withTouchedIndicator"
                  [withFooter]="withFooter"
                >
                </vdms-hq-form-input-date-range>
              </vdms-hq-ui-form-input-accordion>
            </ng-container>
            <ng-container *ngSwitchCase="FilterType.CHECKBOX_LIST">
              <vdms-hq-ui-form-input-accordion
                *ngIf="!facetsEnabled || facets?.[definition.id]?.data?.length"
                [wrappedFormControl]="$formControl(ngControl)"
                [label]="definition.label"
                [optionsLength]="definition.filters.aggregationKey ? facets?.[definition.id]?.data?.length ?? 0 : 0"
                [sharedE2eId]="'dynamic-filters-' + definition.id"
              >
                <vdms-hq-checkbox-list
                  [formControl]="$formControl(ngControl)"
                  [withTouchedIndicator]="withTouchedIndicator"
                  [withFooter]="withFooter"
                  [nullValue]="false"
                  [sourceType]="$selectorSourceType(definition.sourceListKey)"
                  *ngIf="!facetsEnabled || !definition.filters.aggregationKey"
                >
                </vdms-hq-checkbox-list>
                <vdms-hq-ui-form-checkbox-list
                  [available]="facets?.[definition.id]?.data ?? []"
                  [columns]="1"
                  [formControl]="$formControl(ngControl)"
                  *ngIf="facetsEnabled && definition.filters.aggregationKey"
                >
                </vdms-hq-ui-form-checkbox-list>
              </vdms-hq-ui-form-input-accordion>
            </ng-container>
            <ng-container *ngSwitchDefault>
              Unknown field type for field: {{ definition.label }} Unknown type: {{ definition.filters.type }}
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </vdms-hq-ui-form-input-accordions>
  </div>
</div>

<bdc-walk-popup #defaultPopUp>default popup workaround</bdc-walk-popup>
