<div class="inner-navbar-wrapper">
  <div class="inner-navbar-row">
    <div class="header">
      <div class="header-main">
        <vdms-hq-ui-layout-breadcrumbs
          *ngIf="breadcrumbs.length > 0"
          [breadcrumbs]="breadcrumbs"
          [size]="'medium'"
        ></vdms-hq-ui-layout-breadcrumbs>
        <ng-container *ngIf="action">
          <vdms-hq-ui-button
            [icon]="action.icon"
            [iconOnly]="true"
            [tooltip]="action.label"
            (click)="clickAction.emit()"
            [sharedE2eId]="'inner-header-button'"
          ></vdms-hq-ui-button>
        </ng-container>
        <ng-container *ngIf="status">
          <vdms-hq-ui-grid-item-header>
            <vdms-hq-grid-status [label]="'Status'" [color]="status.color"
              >{{ status.label | translate }}
            </vdms-hq-grid-status>
          </vdms-hq-ui-grid-item-header>
        </ng-container>
      </div>
      <h1 *ngIf="header">{{ header | translate }}</h1>
      <h2>{{ subheader | translate }}</h2>
      <ng-content select="[left]"></ng-content>
    </div>
    <div class="right">
      <ng-content select="[right]"></ng-content>
    </div>
  </div>
  <div class="inner-navbar-row">
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
