import { Component, inject } from '@angular/core';
import { ApiContractModule } from '@vdms-hq/api-contract';
import { ActivatedClientModule } from '@vdms-hq/activated-client';
import { CommonModule, DatePipe } from '@angular/common';
import { DynamicFilterInput, DynamicFiltersModule } from '@vdms-hq/dynamic-filters';
import { TranslateModule } from '@ngx-translate/core';
import { FlatMediaPulseViewModel, MediaPulseOrdersDs } from '../../logic/media-pulse-orders-ds';
import { MediaPulseOrdersForm } from '../../logic/media-pulse-form';
import {
  DataPresentationHeaderComponent,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  UIInfinityLoaderModule,
  UILayoutModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import { FieldsScopeKey, FormatBytesPipe } from '@vdms-hq/shared';
import { OrderMetadataTransformerService } from '../../../logic/order-metadata-transformer';
import { mediaPulseFilters } from '../../../logic/config';
import { MatDialog } from '@angular/material/dialog';
import { ColumnsConfigDialogComponent, FieldsFetcherService } from '@vdms-hq/fields';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'vdms-hq-media-pulse-orders',
  templateUrl: './media-pulse-orders.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DynamicFiltersModule,
    TranslateModule,
    ActivatedClientModule,
    ApiContractModule,
    UIInfinityLoaderModule,
    UILayoutModule,
    UISimpleDetailsListModule,
    MultipleDataPresentationComponent,
    DataPresentationHeaderComponent,
  ],
  providers: [MediaPulseOrdersDs, MediaPulseOrdersForm, OrderMetadataTransformerService, DatePipe, FormatBytesPipe],
})
export class MediaPulseOrdersComponent {
  private matDialog = inject(MatDialog);
  private fieldsFetcherService = inject(FieldsFetcherService);

  title = 'common.orders.media_pulse_title';
  filtersConfig: DynamicFilterInput[] = mediaPulseFilters;
  scope: FieldsScopeKey = 'other-media-pulse-orders';

  isLoading$ = this.dataSource.isLoading$;

  viewConfiguration$: Observable<MultipleViewConfiguration<FlatMediaPulseViewModel>> = this.fieldsFetcherService
    .getConfiguration$(this.scope)
    .pipe(
      map((config) => {
        return {
          tableAdvanced: {
            columnsEnabled: config.table.getVisibleKeys(),
            settingsAction: {
              id: 'settings',
              label: 'common.global.edit_columns',
              icon: 'settings',
              color: 'transparent',
            },
            columns: config.table.getColumns(),
          },
        };
      }),
    );

  constructor(public dataSource: MediaPulseOrdersDs, public filtersForm: MediaPulseOrdersForm) {}

  actionHandler(event: { key: string; item?: FlatMediaPulseViewModel }): void {
    switch (event.key) {
      case 'settings':
        this.matDialog.open(ColumnsConfigDialogComponent, { data: { scope: this.scope } });
        return;
    }
  }
}
