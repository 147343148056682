import { DataAction } from '@vdms-hq/ui';
import { FlatAsset, DownloadTypeEnum } from '../order/ordered-asset.model';
import { AssetSearchFilterParam } from '../asset';

export enum LICENSED_PACKAGE_ENDPOINTS {
  ROOT = 'licensed-package',
  ROOT_LIST = 'licensed-packages',
  CONTENT_CORNER = 'content-corner',
}

export enum LICENSED_PACKAGE_VIEW_STATE {
  NONE = 'none',
  HERO = 'hero',
  PROMO = 'promo',
}

export type LicensedPackageFiltersView = {
  keyword?: string;
  status?: string;
  sort?: string;
  direction?: string;
  partners?: string[];
  contracts?: string[];
  view_state?: LICENSED_PACKAGE_VIEW_STATE;
  expires_before?: number;
  contract_in?: number;
  contract_out?: number;
};

export const SORT_BY = [
  {
    key: 'licensedPackage.createdAt_desc',
    label: 'common.sort.created_newest_first',
  },
  {
    key: 'licensedPackage.createdAt_asc',
    label: 'common.sort.created_latest_first',
  },
  {
    key: 'licensedPackage.name_asc',
    label: 'common.sort.name_az',
  },
  {
    key: 'licensedPackage.name_desc',
    label: 'common.sort.name_za',
  },
  {
    key: 'contract_start_date_asc',
    label: 'common.sort.contract_date_start_newest_first',
  },
  {
    key: 'contract_start_date_desc',
    label: 'common.sort.contract_date_start_oldest_first',
  },
  {
    key: 'contract_end_date_asc',
    label: 'common.sort.contract_date_end_newest_first',
  },
  {
    key: 'contract_end_date_desc',
    label: 'common.sort.contract_date_end_oldest_first',
  },
];

export enum LICENSED_PACKAGE_STATUS {
  DRAFT = 'draft',
  ACTIVE = 'active',
}

export enum LICENSED_PACKAGE_CROP {
  FROM_BOTTOM = 'from_bottom',
  FROM_TOP = 'from_top',
  FROM_CENTER = 'from_center',
}

export interface LICENSED_PACKAGE_OPTIONS {
  custom_cover_position?: LICENSED_PACKAGE_CROP;
}

export type LicensedPackageDownloadPayload = {
  download_asset_uuids?: string[];
  keep_folders_structure: boolean;
  config_uuid?: string;
  search?: AssetSearchFilterParam;
  partner_uuid?: string;
  download_uuids?: string[];
  download_type?: keyof typeof DownloadTypeEnum;
};

export interface LicensedPackageListItem {
  uuid: string;
  name: string;
  description?: string;
  status: LICENSED_PACKAGE_STATUS;
  custom_cover: boolean;
  custom_cover_path?: string;
  custom_cover_filename?: string;
  number_of_assets: number;
  number_of_embargoed_assets: number;
  number_of_cold_assets: number;
  created_at: Date;
  updated_at: Date;
  view_state: LICENSED_PACKAGE_VIEW_STATE;
}

export interface LicensedPackage {
  owner_name: string;
  uuid: string;
  name: string;
  description?: string;
  status: LICENSED_PACKAGE_STATUS;
  contracts: {
    uuid: string;
    name: string;
  }[];
  items: LicensedPackageAsset[];

  number_of_assets: number;
  number_of_embargoed_assets: number;
  number_of_cold_assets: number;

  max_expires_at: string;

  custom_cover: boolean;
  custom_cover_path?: string;
  custom_cover_filename?: string;

  options?: {
    custom_cover_position?: string;
  };

  created_at: Date;
  updated_at: Date;
  view_state: LICENSED_PACKAGE_VIEW_STATE;
}

export type LicensedPackagePatchItems = {
  items: {
    asset_uuid: string;
  }[];
};

export interface LicensedPackageCreate {
  name: string;
  description?: string;
}

export interface LicensedPackageUpdate extends LicensedPackageCreate {
  status: LICENSED_PACKAGE_STATUS;
  view_state: LICENSED_PACKAGE_VIEW_STATE;
  custom_cover?: boolean;
  custom_cover_path?: string;
  custom_cover_filename?: string;
  contract_uuids: string[];
  options: {
    custom_cover_position: LICENSED_PACKAGE_CROP;
  };
}

export interface PartnerCanDownloadFile {
  canBeDownloaded: boolean;
  partner: LicensedPackagePartner;
}

export interface LicensedPackagePartner {
  createdAt: string;
  deletedAt: string;
  externalPartnerId: string;
  id: number;
  name: string;
  updatedAt: string;
  users: { uuid: string }[];
  uuid: string;
}

export type LicensedPackageAssetGet = {
  asset: FlatAsset;
  uuid: string;
};

export type LicensedPackageAsset = FlatAsset & {
  item_uuid: string;
  asset_uuid?: string;
};

export const licensePackageAssetsMenuConfig2: DataAction<LicensedPackageAsset>[] = [
  {
    key: 'tab',
    icon: 'tab',
    label: 'Open in new tab',
  },
  {
    key: 'window',
    icon: 'window',
    label: 'Open in new window',
  },
  {
    key: 'uuid',
    icon: 'uuid',
    label: 'Copy asset Uuid',
  },
];
