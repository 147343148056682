import { AnalyzeMetadataJobInterface, AnalyzeMetadataFilenameConvention } from '@vdms-hq/api-contract';

export class MetadataRecognitionModel {
  constructor(public props: AnalyzeMetadataJobInterface) {}
}

export interface MetadataRecognitionModel {
  filenameConventions: AnalyzeMetadataFilenameConvention[];
  minimumPercentageAccuracy: number;
}
