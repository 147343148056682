import { Component, inject } from '@angular/core';
import { UILayoutModule } from '@vdms-hq/ui';
import { AsperaSelectorComponent } from '@vdms-hq/storage';
import { AssetUploadService } from '../../logic/asset-upload.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'vdms-hq-asset-storage-aspera',
  templateUrl: './upload-aspera.component.html',
  styleUrls: ['./upload-aspera.component.scss'],
  imports: [UILayoutModule, AsperaSelectorComponent, TranslateModule],
  standalone: true,
})
export class UploadAsperaComponent {
  assetUploadService = inject(AssetUploadService);
}
