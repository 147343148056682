import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleLicensePackageDsService } from '../../../logic/single-license-package-ds.service';
import {
  BreadCrumb,
  DataColumn,
  MultipleDataPresentationComponent,
  MultipleViewConfiguration,
  ResultsWrapperViewConfiguration,
  SimpleDetailsList,
  UIEmptyResultsModule,
  UILayoutModule,
  UILoaderModule,
  UISimpleDetailsListModule,
} from '@vdms-hq/ui';
import {
  AssetActionsService,
  AssetResultsModule,
  BatchActionsButtonsConfig,
  BatchActionsButtonsLabels,
  ResultsActions,
} from '@vdms-hq/asset-results';
import {
  LicensedPackage,
  LicensedPackageAsset,
  LICENSED_PACKAGE_STATUS,
  licensePackageAssetsMenuConfig2,
} from '@vdms-hq/api-contract';
import { Permission } from '@vdms-hq/firebase-contract';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { FieldsScopeKey, SharedModule } from '@vdms-hq/shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LicensePackagesActionsService } from '../../../logic/license-packages-actions';
import { MatDividerModule } from '@angular/material/divider';
import { PermissionService } from '@vdms-hq/activated-client';
import { AuthService } from '@vdms-hq/auth';
import { Router } from '@angular/router';
import { LicensePackageAssetViewModel } from '../../../logic/license-package-asset-view.model';
import { ContextMenuActionsService } from '@vdms-hq/asset-results';
import { MatDialog } from '@angular/material/dialog';
import { TriggerNotificationsDialogComponent } from '@vdms-hq/notifications';
import { ColumnsConfigDialogComponent, ColumnsConfigDialogData, FieldsFetcherService } from '@vdms-hq/fields';

interface LicensePackageDetailsViewModel {
  name: BreadCrumb[];
  status: LICENSED_PACKAGE_STATUS;
  subtitle: string;
  details: SimpleDetailsList[];
}

@Component({
  selector: 'vdms-hq-single-license-package',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    UILayoutModule,
    UISimpleDetailsListModule,
    UILoaderModule,
    MatDividerModule,
    AssetResultsModule,
    UIEmptyResultsModule,
    MultipleDataPresentationComponent,
  ],
  templateUrl: './single-license-package.component.html',
  styles: [],
  providers: [SingleLicensePackageDsService],
})
export class SingleLicensePackageComponent implements OnInit, OnDestroy {
  Permission = Permission;
  uuid?: string;

  licensedPackageData$: Observable<LicensePackageDetailsViewModel> = this.dataSource.licensedPackage$.pipe(
    withLatestFrom(this.dataSource.uuid$),
    switchMap(([licensedPackage, uuid]) => {
      this.uuid = uuid;
      return this.#transformToViewModel(licensedPackage);
    }),
  );

  activeHeaderConfiguration: ResultsWrapperViewConfiguration = {
    headerActions: [],
    withLoading: true,
  };

  batchActions: BatchActionsButtonsConfig = {
    addToLicensedPackages: false,
    cart: false,
    batchEdit: false,
    addToCollection: false,
    delete: false,
    setAsColdOnly: false,
    triggerNotifications: true,
  };

  batchActionLabels: BatchActionsButtonsLabels = {
    delete: 'common.license_package.table.actions.remove_asset',
    triggerNotifications: 'common.notification_subscriptions.trigger.title',
  };
  columns: DataColumn[] = [];
  multiConfig$!: Observable<MultipleViewConfiguration<LicensedPackageAsset>>;

  scopeName: FieldsScopeKey = 'licensed-package';
  #destroyed = new Subject<void>();
  #destroyed$ = this.#destroyed.asObservable();

  permissions$ = this.permissionService.verifyWithOwnedPermissions$([Permission.EDIT_LICENSED_PACKAGES]).pipe(
    take(1),
    tap((canEdit) => {
      if (canEdit) {
        this.headerAction = {
          label: this.translate.instant('common.license_package.single_view.edit_btn'),
          icon: 'edit',
        };
        this.batchActions.delete = true;
      }
    }),
  );

  openAssetPreviewInPopup$ = this.authService.userAttributesDefinite$.pipe(
    map((attrs) => attrs.vida.openAssetPreviewInPopup),
  );

  selectedLength$ = new BehaviorSubject(0);
  headerAction: { label: string; icon: string } | undefined = undefined;

  get selectedLength() {
    return this.selectedLength$.getValue();
  }

  constructor(
    public dataSource: SingleLicensePackageDsService,
    private licensePackagesActionsService: LicensePackagesActionsService,
    private permissionService: PermissionService,
    private translate: TranslateService,
    private fieldsFetcherService: FieldsFetcherService,
    private authService: AuthService,
    private router: Router,
    private contextMenuActions: ContextMenuActionsService,
    private dialog: MatDialog,
    private assetActionsService: AssetActionsService,
  ) {
    this.dataSource.selection.total$.subscribe((total) => {
      this.selectedLength$.next(total);
      this.activeHeaderConfiguration.headerActions &&
        this.activeHeaderConfiguration.headerActions[0] &&
        (this.activeHeaderConfiguration.headerActions[0].disabled = this.selectedLength === 0);
    });
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
  }

  ngOnInit(): void {
    // todo this place should be based on AssetFlatView2Model
    // todo, this should use results2 component
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.multiConfig$ = combineLatest([
      this.fieldsFetcherService.getConfiguration$(this.scopeName),
      this.assetActionsService.buildQAResultsAction(),
      this.permissions$,
    ]).pipe(
      takeUntil(this.#destroyed$),
      map(([columns, qaResultAction, canEdit]) => {
        const actions = [];
        if (canEdit)
          actions.push({
            key: 'delete',
            label: 'common.license_package.table.actions.remove_asset',
            icon: 'delete',
          });

        actions.push(qaResultAction);

        return {
          multiView: {
            defaultView: 'tableAdvanced',
            fitToVisibleArea: true,
            pagination: true,
            emptyResults: {
              message: 'No packages to list.',
            },
          },
          tableAdvanced: {
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: licensePackageAssetsMenuConfig2,
            },
            columns: columns.table.getColumns(),
            columnsEnabled: columns.table.getVisibleKeys(),
            settingsAction: {
              id: 'settings',
              label: 'common.global.edit_columns',
              icon: 'settings',
              color: 'transparent',
            },
          },
          gridTiles: {
            content: {
              titlePath: 'props.original_filename',
              iconPath: 'tile.icon',
            },
            layout: {
              columns: 'up-to-6',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: [],
            },
          },
          gridAdvanced: {
            content: {
              titlePath: 'props.original_filename',
              iconPath: 'tile.icon',
              metadata: columns.list.getMetadata(),
            },
            layout: {
              columns: 'up-to-2',
            },
            image: {
              bgPath: 'background',
              bgHoverPath: 'backgroundHover',
            },
            actions: [
              {
                key: 'preview',
                icon: 'visibility',
                label: 'common.license_package.table.actions.preview_asset',
              },
              ...actions,
            ],
            contextMenu: {
              actions: [],
            },
          },
        };
      }),
    );
  }

  batchActionHandler(event: { key: ResultsActions | string }): void {
    switch (event.key) {
      case 'delete':
        this.licensePackagesActionsService.deleteAssetsFromPackage(
          this.dataSource.selection.identifiers$.value as string[],
          <string>this.uuid,
          true,
        );
        break;
      case 'trigger_notifications':
        this.dataSource.selection.entities$.pipe(take(1)).subscribe((data) => {
          const assetUuids = data.map(({ props }) => props.uuid);
          this.dialog.open(TriggerNotificationsDialogComponent, {
            data: {
              packageUuid: this.uuid,
              assetUuids,
            },
          });
        });
        break;
    }
  }

  customActionHandler(event: { key: ResultsActions | string; item?: LicensePackageAssetViewModel }): boolean {
    if (event.key == 'settings') {
      this.dialog.open<ColumnsConfigDialogComponent, ColumnsConfigDialogData>(ColumnsConfigDialogComponent, {
        data: { scope: this.scopeName },
      });
    }

    this.contextMenuActions.handleAction(event);

    if (!event.item) return false;
    switch (event.key) {
      case 'delete':
        this.licensePackagesActionsService.deleteAssetsFromPackage(
          [event.item.props.uuid],
          <string>this.uuid,
          true,
          event.item.props.original_filename,
        );
        return true;
      case 'preview':
        this.openAssetPreview(event.item.props.uuid);
        return true;
      default:
        return false;
    }
  }

  async openAssetPreview(uuid: string) {
    const openAssetPreviewInPopup = await firstValueFrom(this.openAssetPreviewInPopup$);
    if (openAssetPreviewInPopup) {
      window.open(`/asset/${uuid}`, 'AssetPreview', 'width=1300,height=700');
    } else {
      this.router.navigate([`/asset/${uuid}`]).then();
    }
  }

  #transformToViewModel(licensedPackage: LicensedPackage): Observable<LicensePackageDetailsViewModel> {
    const viewModel: LicensePackageDetailsViewModel = {
      name: [
        {
          name: 'common.license_package.table.title',
          clickable: true,
          path: 'licensed-packages',
        },
        {
          name: licensedPackage.name,
          clickable: false,
        },
      ],
      status: licensedPackage.status,
      subtitle: 'common.license_package.single_view.subtitle',
      details: [
        {
          title: 'common.license_package.single_view.created_by',
          value: licensedPackage.owner_name,
        },
        {
          title: 'common.license_package.single_view.created_at',
          value: licensedPackage.created_at,
          type: 'date-time',
        },
        {
          title: 'common.license_package.single_view.updated_at',
          value: licensedPackage.updated_at,
          type: 'date-time',
        },
        {
          title: 'common.license_package.single_view.description',
          value: licensedPackage.description,
          size: 'wide',
        },
        {
          title: 'common.license_package.single_view.contracts',
          value: licensedPackage.contracts?.map((contract) => contract.name).join(', '),
          size: 'wide',
        },
      ],
    };

    return of(viewModel);
  }

  editLicensePackageDetails() {
    if (!this.uuid) return;
    this.licensePackagesActionsService.editDialog(this.uuid);
  }
}
