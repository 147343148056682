<vdms-hq-ui-actions-footer
  class="action-bar"
  *ngIf="selectionManager?.total$ && (selectionManager.total$ | async); let total"
>
  <ng-container left>
    <vdms-hq-ui-button color="secondary" (click)="onClear()">
      {{ 'common.global.deselect_all' | translate }}
    </vdms-hq-ui-button>
  </ng-container>

  <ng-container center>
    <vdms-hq-ui-button [disabled]="true">
      {{ 'common.global.selected' | translate : { selected: total } }}
    </vdms-hq-ui-button>
  </ng-container>

  <ng-container right>
    <vdms-hq-ui-button
      color="primary"
      [disabled]="downloadButtonsDisabled"
      (click)="onDownload(ResultsActions.DOWNLOAD_SELECTED_ASPERA_FOLDERS)"
    >
      Download selected in folders
    </vdms-hq-ui-button>

    <vdms-hq-ui-button color="primary" [disabled]="downloadButtonsDisabled" (click)="onDownload()">
      Download selected
    </vdms-hq-ui-button>

    <vdms-hq-ui-button
      *requiresPermissions="[Permission.DOWNLOAD_HTTPS_FROM_DOWNLOADS]"
      color="primary"
      [disabled]="downloadButtonsDisabled"
      (click)="onDownload(ResultsActions.DOWNLOAD_SELECTED_HTTPS)"
    >
      Download selected in browser
    </vdms-hq-ui-button>
  </ng-container>
</vdms-hq-ui-actions-footer>
