import { Component, inject, OnInit } from '@angular/core';
import { Destroyable, E2eIdDirective, FilterType, Loadable, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamsService } from '@vdms-hq/api-contract';
import { ClientTeamDialogData } from '../../logic/client-teams.model';
import { ClientTeamsListDataSource } from '../../logic/client-teams-list-ds.service';
import { ClientTeamsActionsService } from '../../logic/client-teams-actions.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BehaviorSubject, take } from 'rxjs';
import {
  FormSectionComponent,
  TileSelectableConfig,
  UIButtonModule,
  UIDialogWrapperModule,
  UIFormModule,
} from '@vdms-hq/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  CollectionsAddDataSource,
  defaultFilters,
  STATUS_OPTIONS,
  CollectionsAddData,
  CollectionsActionsService,
} from '@vdms-hq/collections';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import { ActivatedClientService } from '@vdms-hq/activated-client';
import { Permission } from '@vdms-hq/firebase-contract';
import { ClientUserAdminAddData, ClientUserAdminAddDataSource } from '@vdms-hq/client-user-admin';
import { UiSelectableTilesComponent, SelectableWrapperConfig } from '@vdms-hq/selectable-tiles-wrapper';
import { takeUntil } from 'rxjs/operators';
import { TeamsAddDataSource } from '@vdms-hq/teams-shared';
import { ViewSettingsService } from '@vdms-hq/view-settings';

@Component({
  selector: 'vdms-hq-create-edit-client-team-dialog',
  standalone: true,
  imports: [
    UIDialogWrapperModule,
    TranslateModule,
    AsyncPipe,
    UIButtonModule,
    FormSectionComponent,
    ReactiveFormsModule,
    UIFormModule,
    CommonModule,
    UiSelectableTilesComponent,
    E2eIdDirective,
  ],
  templateUrl: './create-edit-client-team-dialog.component.html',
})
export class CreateEditClientTeamDialogComponent extends Loadable(Destroyable()) implements OnInit {
  private activatedClientService = inject(ActivatedClientService);
  private teamsService = inject(TeamsService);
  private teamsActionsService = inject(ClientTeamsActionsService);
  private teamsDS = inject(ClientTeamsListDataSource);
  private addTeamsDS = inject(TeamsAddDataSource);
  private dialogRef = inject(MatDialogRef<CreateEditClientTeamDialogComponent>);
  private translate = inject(TranslateService);
  public data = inject<ClientTeamDialogData>(MAT_DIALOG_DATA);
  public collectionsActions = inject(CollectionsActionsService);
  public collectionsDataSource = inject(CollectionsAddDataSource);
  public clientUsersDataSource = inject(ClientUserAdminAddDataSource);
  private viewSettingsService = inject(ViewSettingsService);

  clientUsersFiltersConfig: DynamicFilterInput[] = [
    {
      id: 'keyword',
      label: 'Name or email',
      resource: [ResourceModel.CLIENT_USER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'text',
        validFormat: 'keyword',
        type: FilterType.MASTER_TEXT,
      },
      scope: ['other-users'],
    },
    {
      id: 'app',
      label: 'Application Access',
      resource: [ResourceModel.CLIENT_USER],
      format: ValueFormat.AS_IS,
      filters: {
        objectPath: 'app',
        validFormat: 'keyword',
        type: FilterType.SELECTOR,
      },
      selectOptions: [
        { key: '', label: 'All' },
        { key: 'vida', label: 'Vida' },
        { key: 'launchpad', label: 'Launchpad' },
        { key: 'storefront', label: 'Storefront' },
        { key: 'connect2', label: 'Connect2' },
      ],
      scope: ['other-users'],
    },
  ];
  clientUsersTilesConfig: TileSelectableConfig<ClientUserAdminAddData> = {
    label: 'label',
    icon: 'person',
    key: 'key',
    metadata: [
      {
        label: 'Email',
        valuePath: 'email',
        viewFormat: {
          modifiers: {
            truncateCharacters: 40,
          },
        },
        fullLine: true,
      },
    ],
  };

  collectionsTilesConfig: TileSelectableConfig<CollectionsAddData> = {
    label: 'label',
    icon: 'playlist_add',
    key: 'key',
    disabledIf: {
      condition: (item: CollectionsAddData) => item?.access_type === 'dashboard',
      label: () => 'common.collection_not_available',
    },
    metadata: [
      {
        label: 'Owner',
        valuePath: 'owner',
        viewFormat: {
          fallback: 'Unknown',
          modifiers: {
            truncateCharacters: 40,
          },
        },
        fullLine: false,
        hiddenIf: (item) => item.type === 'owned',
      },
      {
        label: 'Desc',
        valuePath: 'description',
        fullLine: false,
        viewFormat: {
          modifiers: {
            truncateCharacters: 30,
          },
        },
      },
    ],
  };

  readonly permission = Permission;

  form = new FormGroup({
    name: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    shared_collection_uuids: new FormControl<string[] | undefined>(undefined),
    user_uuids: new FormControl<string[] | undefined>(undefined),
  });

  initialized$ = new BehaviorSubject(false);
  isEdit$ = new BehaviorSubject(!!this.data.teamUuid);
  usersShowing$ = new BehaviorSubject<boolean>(true);
  usersHide = new FormControl(false);

  title = 'common.client-teams.dialogs.title';
  previewUsers: string[] = [];
  previewCollections: string[] = [];

  collectionsBtn = 'Collections';
  usersBtn = 'Users';

  usersSelectableConfig: SelectableWrapperConfig<ClientUserAdminAddData> = {
    fieldsConfig: this.clientUsersFiltersConfig,
    tileConfig: this.clientUsersTilesConfig,
    selectedList: {
      data: [],
      title: 'Users in team',
    },
    searchView: {
      placeholder: 'Type name or email',
      label: 'Search users',
      showSearch: true,
    },
    datasourceTitle: 'All Users',
    emptyMessage: 'No users found',
    loadingText: this.translate.instant('common.client-teams.dialogs.fields.users-loading'),
    keepSelectedListState: true,
  };

  collectionsSelectableConfig: SelectableWrapperConfig<CollectionsAddData> = {
    fieldsConfig: [],
    tileConfig: this.collectionsTilesConfig,
    selectedList: {
      data: [],
      title: 'Collections in team',
    },
    searchView: {
      placeholder: 'Type name',
      label: 'Collections list',
      showSearch: true,
    },
    datasourceTitle: 'All Collections',
    emptyMessage: 'No collections found',
    loadingText: this.translate.instant('common.client-teams.dialogs.fields.collections-loading'),
    fullWidth: true,
    hasEmptyActionButton: true,
    emptyActionButtonText: 'Create new collection',
    keepSelectedListState: true,
  };

  ngOnInit() {
    this.viewSettingsService.preferredSelectableView$.pipe(takeUntil(this.isDestroyed$)).subscribe((view) => {
      this.usersSelectableConfig.searchView.showSearch = view === 'search';
      this.collectionsSelectableConfig.searchView.showSearch = view === 'search';
    });
    if (this.data.teamUuid) {
      this.startLoading();
      this.teamsService
        .getTeam(this.data.teamUuid)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: (team) => {
            this.form.patchValue({
              name: team.name,
              shared_collection_uuids: team.shared_collections.map((collection) => collection.uuid),
              user_uuids: team.users.map((user) => user.uuid),
            });
            this.collectionsSelectableConfig.selectedList.data = team.shared_collections.map((collection) => ({
              name: collection.name,
              uuid: collection.uuid,
            }));

            this.usersSelectableConfig.selectedList.data = team.users.map((user) => ({
              name: user.name,
              uuid: user.uuid,
            }));

            if (this.data.preview) {
              this.previewUsers = team.users.map((user) => user.name);
              this.previewCollections = team.shared_collections.map((collection) => collection.name);
            }
            this.initialize();
            this.stopLoading();
          },
          error: () => {
            this.teamsActionsService.popToast.LOAD_FAILURE();
            this.stopLoading();
            this.closeDialog();
          },
        });
    } else {
      this.initialize();
    }
  }

  initialize() {
    if (this.data.preview) {
      this.title = this.translate.instant('common.client-teams.dialogs.preview.title', {
        teamName: this.form.controls.name.value,
      });
      return;
    }
    if (this.data.teamUuid) {
      this.title = 'common.client-teams.dialogs.edit.title';
      this.collectionsBtn = 'common.client-teams.dialogs.view_edit.collections';
      this.usersBtn = 'common.client-teams.dialogs.view_edit.users';
    } else {
      this.title = 'common.client-teams.dialogs.create.title';
      this.collectionsBtn = 'common.client-teams.dialogs.view_create.collections';
      this.usersBtn = 'common.client-teams.dialogs.view_create.users';
    }
    this.activatedClientService.permissions$.pipe(takeUntil(this.isDestroyed$)).subscribe((permissions) => {
      this.collectionsDataSource.filters.controls['keyword'].reset();
      this.collectionsSelectableConfig.fieldsConfig = defaultFilters;
      if (!permissions.includes(this.permission.BROWSE_SHARED_COLLECTIONS)) {
        this.collectionsSelectableConfig.fieldsConfig = this.collectionsSelectableConfig.fieldsConfig.map((f) => {
          if (f.id == 'collectionType') {
            return {
              ...f,
              selectOptions: STATUS_OPTIONS.filter((opt) => {
                return !['all', 'shared'].includes(opt.key);
              }),
            };
          }
          return f;
        });
      }
      this.initialized$.next(true);
    });
  }

  toggleView() {
    this.usersShowing$.next(!this.usersHide.value);
  }

  onSave() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.startLoading();
    const payload = {
      name: this.form.value.name ?? 'N/A',
      shared_collection_uuids: this.form.value.shared_collection_uuids ?? undefined,
      user_uuids: this.form.value.user_uuids ?? undefined,
    };
    if (this.isEdit$.value && this.data.teamUuid) {
      this.teamsService
        .updateTeam(this.data.teamUuid, payload)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: () => {
            this.teamsActionsService.popToast.UPDATE_SUCCESS();
            this.successActions();
          },
          error: () => {
            this.teamsActionsService.popToast.UPDATE_FAILURE();
            this.stopLoading();
          },
        });
    } else {
      this.teamsService
        .createTeam(payload)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.teamsActionsService.popToast.CREATE_SUCCESS();
            this.successActions();
          },
          error: () => {
            this.teamsActionsService.popToast.CREATE_FAILURE();
            this.stopLoading();
          },
        });
    }
  }

  successActions() {
    this.teamsDS.refresh$.next(new Date());
    this.addTeamsDS.refresh$.next(new Date());
    this.stopLoading();
    this.closeDialog();
  }

  closeDialog(): void {
    this.clientUsersDataSource.refresh();
    this.collectionsDataSource.refresh();
    this.dialogRef.close();
  }

  createNewCollection() {
    this.collectionsActions.openCreateDialog(this.collectionsDataSource.filters.controls.keyword.value, true);
  }

  saveViewSettings(showSearch: boolean) {
    this.viewSettingsService.savePreferredSelectableView(showSearch ? 'search' : 'list');
  }
}
