<div class="timecode-pair" [class.with-touched-indicator]="withTouchedIndicator" [formGroup]="innerFormControl">
  <div class="timecode-pair__fields" formArrayName="pair">
    <vdms-hq-form-input-timecode
      formControlName="0"
      [withFooter]="
        (withFooter && (tcSecond?.invalid ?? false)) || ((tcSecond?.valid ?? false) && innerFormControl.valid)
      "
      [label]="labels[0] | translate"
      (blur)="propagateTouch()"
      [framerate]="framerate"
      [hideFramerate]="hideFramerate"
      [required]="isRequired"
      [hint]="hint"
      [mask]="mask"
      [buttonConfig]="tcInButtonConfig"
      (buttonClick)="emitMarkIn.emit()"
      [sharedE2eId]="'tc-in'"
    ></vdms-hq-form-input-timecode>
    <vdms-hq-form-input-timecode
      formControlName="1"
      [withFooter]="
        (withFooter && (tcSecond?.invalid ?? false)) || ((tcSecond?.valid ?? false) && innerFormControl.valid)
      "
      [label]="labels[1] | translate"
      (blur)="propagateTouch()"
      [framerate]="framerate"
      [hideFramerate]="hideFramerate"
      [required]="isRequired"
      [mask]="mask"
      [buttonConfig]="tcOutButtonConfig"
      (buttonClick)="emitMarkOut.emit()"
      [sharedE2eId]="'tc-out'"
    ></vdms-hq-form-input-timecode>
  </div>
  <div
    class="timecode-pair__footer"
    *ngIf="withFooter && tcFirst?.valid && tcSecond?.valid && innerFormControl.invalid"
  >
    <mat-error class="mat-error">
      <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
    </mat-error>
    <vdms-hq-ui-loader matSuffix [size]="20" *ngIf="innerFormControl.pending"></vdms-hq-ui-loader>
  </div>
</div>
