<div
  class="material-custom-field toggle-outer"
  [class.with-touched-indicator]="withTouchedIndicator"
  [class.hide-footer]="!withFooter"
  [class.toggle-outer__hide_padding]="hidePadding"
>
  <div class="toggle-outer__buttons">
    <button
      class="toggle-outer__button"
      mat-button
      [class.toggle-outer__button--active]="!innerFormControl.value"
      (click)="innerFormControl.setValue(false)"
      [sharedE2eId]="'toggle-button-first'"
    >
      {{ leftOptionLabel }}
    </button>
    <button
      class="toggle-outer__button"
      mat-button
      [class.toggle-outer__button--active]="innerFormControl.value"
      (click)="innerFormControl.setValue(true)"
      [sharedE2eId]="'toggle-button-second'"
    >
      {{ rightOptionLabel }}
    </button>
  </div>
  <mat-hint *ngIf="withFooter && hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="withFooter && innerFormControl.touched">
    <span *ngFor="let error of errors">{{ error.transLang | translate : error.contextParams }}</span>
  </mat-error>
</div>
