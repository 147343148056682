import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedClientModule, ActivatedClientService, WithPermissions } from '@vdms-hq/activated-client';
import {
  DialogResponse,
  UIButtonModule,
  UIDataSelectorComponent,
  UIEmptyResultsModule,
  UIFormModule,
  UILoaderModule,
} from '@vdms-hq/ui';
import { UserApiService } from '@vdms-hq/api-contract';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DiacriticsRevertConverter, emailPatternValidator, SelectionIdentifier, SelectOption } from '@vdms-hq/shared';
import { UsersShareDataSource } from '../../logic/users-share.ds';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, debounceTime, map, Observable, of, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import { transformUserToSelectOptionMail } from '../../logic/user-transformer';
import { MatDialog } from '@angular/material/dialog';
import { ToastService } from '@vdms-hq/toast';
import { MatDividerModule } from '@angular/material/divider';
import { AuthService } from '@vdms-hq/auth';

@Component({
  selector: 'vdms-hq-users-selector',
  templateUrl: './users-selector.component.html',
  styleUrls: ['./users-selector.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    UILoaderModule,
    UIFormModule,
    UIButtonModule,
    ActivatedClientModule,
    TranslateModule,
    MatPaginatorModule,
    UIDataSelectorComponent,
    MatDividerModule,
    UIEmptyResultsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UsersSelectorComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSelectorComponent
  extends WithPermissions()
  implements OnChanges, OnInit, OnDestroy, ControlValueAccessor
{
  #destroy$ = new Subject<void>();

  @Input() listType: 'share' | 'all' = 'share';
  @Input() previewOnly = false;
  @Input() respectHideUsersSetting = true;
  @Input() onlySelectedUsers = false;

  hideUsersList = false;
  @Output() openAddUserDialog = new EventEmitter<string>();
  @Output() updateUsers = new EventEmitter<string[]>();
  users = new FormControl<SelectOption<SelectionIdentifier>[]>([], { nonNullable: true });
  disabled = false;

  usersSearch = new FormControl<string>('', { nonNullable: true });
  shareCreate = new FormControl<string>('', {
    nonNullable: true,
    validators: [emailPatternValidator()],
  });
  filteredOptions$: Observable<SelectOption<SelectionIdentifier>[]> = of([]);

  userEmail$ = this.authService.email$;

  isFilteredEmpty$ = new BehaviorSubject(false);
  noUserFound = false;
  userSearching = false;
  tmpSearchedUser = { key: '', label: '' };

  constructor(
    public dataSource: UsersShareDataSource,
    private dialog: MatDialog,
    private clientService: ActivatedClientService,
    private authService: AuthService,
    private toastService: ToastService,
    private userApiService: UserApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = (value: SelectOption<SelectionIdentifier>[]) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {};

  writeValue(users: SelectOption<SelectionIdentifier>[]): void {
    if (users.length === 0) {
      this.isFilteredEmpty$.next(true);
    }
    this.users.setValue(users);
    this.filteredOptions$ = of(users);
    this.dataSource.selection?.identifiers$.next(users.map((u) => u.key));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges() {
    this.dataSource.selectedUsers$.next(this.onlySelectedUsers ? this.users.value : null);
    this.dataSource.refresh();
  }

  ngOnInit() {
    this.dataSource.listType = this.listType;
    this.clientService.userListHidden$.pipe(takeUntil(this.#destroy$)).subscribe((hidden) => {
      if (!this.respectHideUsersSetting) {
        return;
      }
      this.hideUsersList = hidden;
    });
    this.dataSource.pagination$.next({ page: '0', perPage: this.dataSource.pageSize$.value.toString() });
    this.dataSource.applyFilter('');
    this.dataSource.text$.pipe().subscribe((text) => {
      this.tmpSearchedUser.label = text;
    });
    this.dataSource.selection?.identifiers$.pipe(takeUntil(this.#destroy$)).subscribe((ids) => {
      this.updateUsers.emit(ids.map((id) => id.toString()));
    });
    this.tmpSearchedUser.label = this.dataSource.text$.value;
    this.usersSearch.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map((value: string) => this.users.value.filter((user) => user.label.includes(value))),
      )
      .subscribe((users) => {
        if (users.length === 0) {
          this.isFilteredEmpty$.next(true);
        } else {
          this.isFilteredEmpty$.next(false);
          this.filteredOptions$ = of(users);
        }
      });

    this.shareCreate.valueChanges
      .pipe(
        withLatestFrom(this.userEmail$),
        filter(([value]) => value.length > 3),
        tap(() => (this.userSearching = true)),
        tap(() => (this.noUserFound = false)),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(([value, email]) => {
          if (value === email) {
            return of([]);
          } else {
            return this.userApiService.getPaginatedUsersShareMailStrict(value);
          }
        }),
        tap((response) => {
          if (!('data' in response)) {
            this.resetTmpSearchedUser();
          } else {
            if (response.data.length === 0) {
              this.noUserFound = true;
              this.tmpSearchedUser.label = this.shareCreate.value;
            } else {
              this.tmpSearchedUser = {
                key: response.data[0].uuid,
                label: response.data[0].email,
              };
            }
          }
        }),
        tap(() => {
          this.userSearching = false;
          this.changeDetectorRef.markForCheck();
        }),
      )
      .subscribe();

    this.dataSource.selection.entities$.pipe(takeUntil(this.#destroy$), filter(Boolean)).subscribe((users) =>
      this.users.setValue(
        users.map(({ key, label }) => ({
          key,
          label,
        })),
      ),
    );
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  onNewCreate(email = DiacriticsRevertConverter(this.tmpSearchedUser.label)): void {
    this.dialog
      .open(AddUserDialogComponent, { data: { email } })
      .afterClosed()
      .pipe(
        filter((dialogResponse) => dialogResponse?.status === DialogResponse.OK),
        filter((dialogResponse) => !!dialogResponse?.user),
        map((data) => data.user),
      )
      .subscribe(
        (user) => {
          if (!user) {
            return;
          }
          this.dataSource.text$.next(email);
          this.shareCreate.setValue('');
          this.users.setValue([...this.users.value, transformUserToSelectOptionMail(user)]);
          this.updateList();
          this.noUserFound = false;
          this.resetTmpSearchedUser();
        },
        () => {
          this.toastService.error({
            id: 'create_field_failure',
            message: 'common.notifications.users.create.exists_other_client',
            interpolatedParams: { reason: 'Not specified' },
          });
        },
      );
  }

  removeChip(user: SelectOption<SelectionIdentifier>) {
    this.users.setValue(this.users.value.filter((u) => u.key !== user.key));
    this.updateList();
  }

  addToList() {
    this.users.setValue([...this.users.value, this.tmpSearchedUser]);
    this.shareCreate.setValue('');
    this.updateList();
    this.resetTmpSearchedUser();
  }

  updateList() {
    if (this.users.value.length === 0) {
      this.isFilteredEmpty$.next(true);
      this.filteredOptions$ = of([]);
      this.updateUsers.emit([]);
    } else {
      this.isFilteredEmpty$.next(false);
      this.filteredOptions$ = of(this.users.value);
      this.updateUsers.emit(this.users.value.map((u) => u.key.toString()));
    }
  }

  resetTmpSearchedUser() {
    this.tmpSearchedUser = { key: '', label: '' };
  }
}
