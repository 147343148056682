import { AssetSearchFilters, PersistenceFlatAsset, Thumbnail } from '../../asset';

/** todo this shouldn't be in api-contract (transformation models should be in business logic layer) */
import { SimpleDetailsList, TileModel, GridListInput } from '@vdms-hq/ui';
import { AwsV4Signature } from '../../upload/upload';
import { SimpleType } from '../../types.model';

export enum CollectionAccessType {
  DASHBOARD = 'dashboard',
  SHARED = 'shared',
  OWNED = 'owned',
  TEAM = 'team',
  ALL = 'all',
}

export interface CollectionModelFlat {
  collection?: CollectionModelFlat;
  uuid: string;
  owner?: string;
  name: string;
  description: string;
  custom_cover: boolean;
  custom_cover_path: string | null;
  items?: CollectionItemGet[];
  users?: CollectionsUserGet[];
  groups: CollectionsGroupGet[];
  parents: SimpleType[];
  order?: number;
  owner_name?: string;
  created_at: string;
  updated_at: string;
  shared_to_teams?: SimpleType[];
  number_of_assets: number;
  children: CollectionChildGet[];
  thumbnails: Thumbnail[];
  access_type: CollectionAccessType;
}

export interface CollectionAccessTypes {
  access_type: CollectionModelFlat['access_type'];
  uuid: CollectionModelFlat['uuid'];
}

export interface CollectionsFilters {
  filter?: string;
}

export interface CollectionModel extends CollectionModelGet {
  assets: CollectionItemGet[];
}

export interface CollectionModelGet {
  uuid: string;
  owner?: string;
  owner_name?: string;
  name: string;
  description: string;
  custom_cover: boolean;
  custom_cover_path: string;
  items: CollectionItemGet[];
  total: number;
  users?: CollectionsUserGet[];
  parents: SimpleType[];
  children: CollectionChildGet[];
  groups: CollectionsGroupGet[];
  shared_to_teams?: SimpleType[];
  created_at: string;
  updated_at: string;
  number_of_assets: number;
  order?: number;
  access_type?: CollectionAccessType;
}

export interface CollectionChildGet extends CollectionViewModel {
  uuid: string;
  name: string;
  custom_cover: boolean;
  custom_cover_path: string;
  description: string;
  owner?: string;
  owner_name?: string;
  items: CollectionItemGet[];
  total: number;
  created_at: string;
  updated_at: string;
  number_of_assets: number;
  groups: CollectionsGroupGet[];
  children: CollectionViewModel[];
  tile: TileModel;
  routerLink: string;
}

export interface CollectionModelPost {
  uuid: string;
  name: string;
  description: string;
}

export interface CollectionModelPatch {
  name?: string;
  description?: string;
  custom_cover?: boolean;
  filename?: string;
  shared_to_team_uuids?: string[];
  add_items?: {
    item_uuid?: string;
    asset_uuid?: string;
    logging_type?: string;
    timecode_in?: string;
    timecode_out?: string;
    note?: string;
    type?: string;
  }[];
  delete_items?: string[];
  owner?: string;
  users?: string[];
  parentIds?: {
    uuid: string;
    attached: boolean;
  }[];
  collection_uuids?: string[];
}

export interface CollectionItemsPatch {
  items: CollectionItemPatch[];
}

export interface CollectionExportMetadataPost {
  fields: string[];
}

export interface CollectionItemPatch {
  item_uuid?: string;
  asset_uuid?: string;
  logging_type?: string;
  timecode_in?: string;
  timecode_out?: string;
  note?: string;
  type?: string;
}

export interface IdData {
  id: number;
}

export interface CollectionAssetFilters {
  filter: string;
  'tx-date-from': string;
  'tx-date-to': string;
  'facility-of-origin': string;
}

export interface CollectionItemGet extends PersistenceFlatAsset {
  parent_uuids: string[];
  item_uuid: string;
  asset_uuid: string;
}

interface CollectionsUserGet {
  emailAddress?: string;
  uuid: string;
  name: string;
  picture: string;
}

interface CollectionsGroupGet {
  uuid: string;
  name: string;
}

// todo view models should NOT be in api contract
export type CollectionViewModel = GridListInput<CollectionModelGet> & {
  title: string;
  metadata: SimpleDetailsList[];
  ownerType?: string;
  statuses: {
    label: string;
    color: 'success' | 'default' | 'alert';
    hideDot: boolean;
  };
};

// todo view models should NOT be in api contract
export type FlatCollectionViewModel = GridListInput<CollectionModelFlat> & {
  title: string;
  metadata: SimpleDetailsList[];
  ownerType?: string;
  statuses: {
    label: string;
    color: 'success' | 'default' | 'alert';
    hideDot: boolean;
  };
};

// todo view models should NOT be in api contract
export interface CollectionsGridInput {
  uuid: string;
  name: string;
  order: number;
  custom_cover: boolean;
  custom_cover_path: string;
  description: string;
}

export interface CollectionCreateUploadRequest {
  filename: string;
  file_uuid: string;
  file_type: string;
}

export interface CollectionCreateUploadResponse {
  data: {
    upload_access: AwsV4Signature;
  };
  status: 'success' | 'error';
}

export interface CollectionUploadConfirmationRequest {
  filename: string;
  file_key: string;
}

export interface CollectionUploadConfirmationResponse {
  data: string;
  status: string;
}

export interface FolderCollectionUploadRequest {
  query: FoldersQuery;
  collections: string[];
  collection_name?: string;
  users?: string[];
}

export interface FoldersQuery {
  text: string;
  filters: AssetSearchFilters;
}
