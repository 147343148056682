import { InjectionToken, Provider } from '@angular/core';

export interface CredentialsConfig {
  oAuthGoogleClientId: string;
}

export const CREDENTIALS_TOKEN: InjectionToken<CredentialsConfig> = new InjectionToken<CredentialsConfig>(
  'CREDENTIALS_TOKEN',
);

export const configureCredentials = (params: CredentialsConfig) => {
  const providers: Provider[] = [{ provide: CREDENTIALS_TOKEN, useValue: params }];

  return providers;
};
