<vdms-hq-ui-dialog-wrapper>
  <h1 title>{{ title | translate }}</h1>
  <div content class="relative" [formGroup]="form">
    <vdms-hq-ui-form-section layout="grid-3-columns" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="name"
        [label]="'common.dialogs.rights_contracts.create.fields.name' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-date
        formControlName="start_date"
        format="utc"
        [label]="'common.dialogs.rights_contracts.create.fields.start_date' | translate"
      ></vdms-hq-ui-form-input-date>
      <vdms-hq-ui-form-input-date
        formControlName="end_date"
        format="utc"
        [label]="'common.dialogs.rights_contracts.create.fields.end_date' | translate"
      ></vdms-hq-ui-form-input-date>
    </vdms-hq-ui-form-section>
    <vdms-hq-ui-form-section layout="grid-3-columns" [divider]="false">
      <vdms-hq-ui-form-input-text
        formControlName="contract_id"
        [label]="'common.dialogs.rights_contracts.create.fields.contract_id' | translate"
      ></vdms-hq-ui-form-input-text>
      <vdms-hq-ui-form-input-number
        formControlName="download_limit"
        [label]="'common.dialogs.rights_contracts.create.fields.download_limit' | translate"
        [matTooltip]="'common.dialogs.rights_contracts.create.download_limit_tooltip' | translate"
      ></vdms-hq-ui-form-input-number>
      <vdms-hq-ui-form-input-select
        [disabled]="partnersOptions.length === 0"
        [label]="'common.dialogs.rights_contracts.create.fields.partners' | translate"
        [selectOptions]="partnersOptions"
        [loading]="rightsPartnersAddDsService.isLoading$ | async"
        formControlName="partner_uuid"
      >
      </vdms-hq-ui-form-input-select>
    </vdms-hq-ui-form-section>
    <div class="flex-row justify-content-start flex-gap-2">
      <vdms-hq-ui-form-input-checkbox
        formControlName="notifications_enabled"
        [label]="'common.dialogs.rights_contracts.create.fields.notifications_enabled' | translate"
      ></vdms-hq-ui-form-input-checkbox>
      <vdms-hq-ui-form-input-checkbox
        [formControl]="readyToReleaseControl"
        [label]="'common.dialogs.rights_contracts.create.set_ready_to_release' | translate"
      ></vdms-hq-ui-form-input-checkbox>
    </div>
    <vdms-hq-ui-selectable-tiles
      *ngIf="isInitialized$ | async"
      [dataSource]="licensedPackagesAddDsService"
      [filters]="licensedPackagesAddDsService.filters"
      [config]="licensedPackagesSelectableConfig"
      (updateSelectedListEmit)="licensedPackagesSelectableConfig.selectedList.data = $event"
      [selected]="this.selectedLicensePackagesControl.value ?? []"
      (selectedChangeEmit)="this.selectedLicensePackagesControl.setValue($event)"
      (updateConfigEmit)="licensedPackagesSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
    >
    </vdms-hq-ui-selectable-tiles>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <vdms-hq-ui-button color="primary" [loading]="isLoading$ | async" (click)="onSubmit()" [disabled]="form.invalid">
      {{ saveButton | translate }}
    </vdms-hq-ui-button>
  </div>
</vdms-hq-ui-dialog-wrapper>
