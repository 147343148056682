import { Component, Input } from '@angular/core';
import { AssetSearchService, PersistenceSearchParams } from '@vdms-hq/api-contract';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { ToastService } from '@vdms-hq/toast';
import { UIFormModule, UILoaderModule, UIModule } from '@vdms-hq/ui';
import { TranslateModule } from '@ngx-translate/core';
import { SearchResultsConfigFieldsService } from '../../../logic/services/search-results-config-fields.service';

@Component({
  selector: 'vdms-hq-simple-ai-query-input',
  templateUrl: './simple-ai-query-input.component.html',
  styleUrls: ['../search-hero-input/search-hero-input.component.scss'],
  imports: [UIModule, UILoaderModule, UIFormModule, TranslateModule],
  standalone: true,
})
export class SimpleAiQueryInputComponent {
  currentQuery = '';
  loading = false;
  @Input() placeholder = 'pages.home.search.ai_hero.placeholder';
  @Input() alternativeAppearance = false;

  constructor(
    public assetSearchService: AssetSearchService,
    private configService: SearchResultsConfigFieldsService,
    private toastService: ToastService,
  ) {}

  updateQuery() {
    this.loading = true;
    this.configService.filters$
      .pipe(
        take(1),
        withLatestFrom(this.configService.table$),
        switchMap(([filterDefinitions, table]) =>
          this.assetSearchService.aiSearch(filterDefinitions, table, this.currentQuery),
        ),
      )
      .subscribe({
        next: (params: PersistenceSearchParams) => {
          this.assetSearchService.applyParams(params);
          this.loading = false;
        },
        error: () => {
          this.toastService.warning({ id: 'ai_search_failed', message: 'common.notifications.generic.try_again' });
          this.loading = false;
        },
      });
  }

  keyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.updateQuery();
    }
  }
}
