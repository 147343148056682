<div class="batch" *ngIf="batch.state$ | async; let state">
  <div class="batch--head">
    <span class="batch--title">
      <ng-container [ngSwitch]="state.status">
        <ng-container *ngSwitchCase="'not_started'">
          {{ 'common.aspera.not_started' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'entity_created'">
          {{ 'common.aspera.entity_created' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'failed'">
          {{ 'common.aspera.failed' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'done'">
          {{ 'common.aspera.done' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'cancelling'">
          {{ 'common.aspera.cancelling' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="'queued'">
          {{ 'common.aspera.queued' | translate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{
            (batch.files.length === 1 ? 'common.aspera.uploading' : 'common.aspera.uploading.plural')
              | translate : { amount: batch.files.length }
          }}

          <ng-container *ngIf="isProgressBar">
            {{ 'common.aspera.upload_progress' | translate : { percent: state.progress } }}
          </ng-container>
        </ng-container>
      </ng-container>
    </span>
    <vdms-hq-ui-button class="close" (click)="closeElement.emit()" [iconOnly]="true" icon="close"></vdms-hq-ui-button>
  </div>

  <div class="batch--subhead">
    <vdms-hq-ui-progress-status
      *ngIf="isProgressBar"
      [value]="state.progress"
      [mode]="state.progress === 0 ? 'indeterminate' : 'determinate'"
      [labelVisible]="false"
    ></vdms-hq-ui-progress-status>
    <vdms-hq-ui-button
      *ngIf="isProgressBar"
      [disabled]="!cancellableStates.includes(state.status)"
      (click)="deleteElement.emit()"
      color="transparent"
      >{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button
    >
  </div>
  <div class="rows">
    <div class="row" *ngFor="let file of batch.files | slice : 0 : VISIBLE_FILES">
      {{ file.filename }}
    </div>
    <div class="row" *ngIf="batch.files.length > 3">
      {{ 'common.aspera.and_more' | translate : { amount: batch.files.length - VISIBLE_FILES } }}
    </div>

    <div class="row error" *ngIf="batch.error">
      <mat-icon>warning</mat-icon>
      <span><p>Something went wrong, please refresh the page.</p></span>
    </div>
  </div>
</div>
