<vdms-hq-ui-dialog-wrapper>
  <div title>
    <span class="title">{{ 'common.dialogs.license_package.add_assets.title' | translate }}</span>
  </div>
  <div content class="relative">
    <vdms-hq-ui-loader *ngIf="dataSource.isLoading$ | async" [backdrop]="true" mode="over-parent"></vdms-hq-ui-loader>
    <form [formGroup]="form" class="form-content">
      <vdms-hq-dynamic-form-group-renderer
        [form]="dataSource.filters"
        [fieldsConfig]="filtersConfig"
        [hasLoader]="true"
        [fullWidth]="true"
      ></vdms-hq-dynamic-form-group-renderer>
      <vdms-hq-ui-add-dialog-selectable-tiles
        [config]="licensedPackagesTilesConfig"
        [items]="(dataSource.connection$ | async) ?? []"
        [selected]="selectedLicensePackagesControl.value"
        (selectedChange)="selectLicensedPackage($event)"
      ></vdms-hq-ui-add-dialog-selectable-tiles>

      <vdms-hq-ui-empty-results
        *ngIf="dataSource.emptyResults$ | async"
        class="data__results-empty"
        [message]="'Could not find any licensed packages'"
        [type]="'compact'"
        [icon]="'folder_special'"
      ></vdms-hq-ui-empty-results>

      <mat-paginator
        [pageSizeOptions]="dataSource.pageSizeOptions"
        [length]="dataSource.total$ | async"
        [pageIndex]="dataSource.pageIndex$ | async"
        [pageSize]="dataSource.pageSize$ | async"
        (page)="dataSource.pageChange($event)"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </form>
  </div>
  <ng-container footer>
    <vdms-hq-ui-button (click)="close()" color="secondary">{{ 'common.global.cancel' | translate }}</vdms-hq-ui-button>
    <vdms-hq-ui-button
      (click)="save()"
      [disabled]="form.invalid || (!data.filters && !data.selectedIds?.length)"
      color="primary"
      >{{ 'common.dialogs.license_package.add_assets.button_confirm' | translate }}</vdms-hq-ui-button
    >
  </ng-container>
</vdms-hq-ui-dialog-wrapper>
