import { ParamsPagination } from '@vdms-hq/view-settings';
import { SelectableTilesDataSource } from '@vdms-hq/selectable-tiles-wrapper';
import { SelectOption } from '@vdms-hq/shared';
import { FormControl, FormGroup } from '@angular/forms';
import { inject, Injectable } from '@angular/core';
import { ToastService } from '@vdms-hq/toast';
import { GetTeamInterface, PaginationAPIModel as Pagination, TeamsService } from '@vdms-hq/api-contract';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  EMPTY,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedClientService } from '@vdms-hq/activated-client';

export interface TeamsAddData {
  key: string;
  label: string;
  number_of_users: number;
  number_of_collections: number;
  created_by: string;
}

export type TeamsAddFiltersView = {
  keyword: FormControl<string>;
};

export type TeamsFiltersView = {
  keyword: string;
};

@Injectable({ providedIn: 'root' })
export class TeamsAddDataSource
  extends ParamsPagination
  implements SelectableTilesDataSource<SelectOption, FormGroup<TeamsAddFiltersView>, GetTeamInterface>
{
  private teamsService = inject(TeamsService);
  private toastService = inject(ToastService);
  private activatedClientService = inject(ActivatedClientService);

  filters = new FormGroup({
    keyword: new FormControl<string>('', { nonNullable: true }),
  });

  refresh$ = new BehaviorSubject(new Date());
  isLoading$ = new BehaviorSubject<boolean>(true);

  responseData$ = combineLatest([
    this.refresh$,
    this.pageIndex$,
    this.pageSize$,
    this.filters.valueChanges.pipe(
      startWith(this.filters.value),
      debounceTime(300),
      switchMap(() => {
        const formValue = this.filters.value;
        const filters = <TeamsFiltersView>{};

        if (formValue?.keyword) {
          filters.keyword = formValue.keyword;
        }

        this.changePageIndex$.next(0);
        return of(filters);
      }),
    ),
    this.activatedClientService.clientIdDefinite$,
  ]).pipe(
    switchMap(([, page, perPage, filters]) => {
      const headers = Pagination.create({
        page,
        perPage,
      });
      return this.teamsService.getTeams(headers, filters);
    }),
    shareReplay(1),
  );

  emptyResults$ = this.responseData$.pipe(map((data) => data.total === 0));
  total$ = this.responseData$.pipe(map((data) => data.total));

  allData$: Observable<TeamsAddData[]> = this.responseData$.pipe(
    tap(() => this.isLoading$.next(true)),
    catchError((error) => {
      this.#errorHandler(error);
      throw error;
    }),
    map((response) =>
      response.data.map((team) => {
        return {
          key: team.uuid,
          label: team.name,
          number_of_users: team.users.length,
          number_of_collections: team.shared_collections.length,
          created_by: team.created_by.name,
        };
      }),
    ),
    tap(() => this.isLoading$.next(false)),
    shareReplay(1),
  );

  connection$ = this.allData$;

  getFormGroup() {
    return this.filters as FormGroup<TeamsAddFiltersView>;
  }

  searchKeyword(keyword: string) {
    this.filters.controls['keyword'].setValue(keyword);
  }

  refresh(force: boolean) {
    if (this.filters.dirty || this.changePageIndex$.value !== 0 || force) {
      this.filters.controls['keyword'].setValue('');
      this.changePageIndex$.next(0);
    }
  }

  #errorHandler(err: HttpErrorResponse) {
    this.toastService.error({ id: 'client-teams', message: 'Error fetching client Teams' });
    this.isLoading$.next(false);
    this.emptyResults$ = of(true);
    console.error(err);
    return EMPTY;
  }
}
