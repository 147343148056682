<vdms-hq-ui-dialog-wrapper [sharedE2eId]="'team-dialog-wrapper'" [size]="'wide'">
  <div title class="flex-row justify-between w100">
    <h1 class="mb-0" [sharedE2eId]="'team-name-preview'">
      {{ title | translate }}
    </h1>
    <vdms-hq-form-input-buttons-toggle
      [formControl]="usersHide"
      (valueChange)="toggleView()"
      [leftOptionLabel]="usersBtn | translate"
      [rightOptionLabel]="collectionsBtn | translate"
      [hidePadding]="true"
    ></vdms-hq-form-input-buttons-toggle>
  </div>
  <div content class="relative p-scroll mh-15vh" [formGroup]="form">
    <vdms-hq-ui-loader
      *ngIf="isLoading$ | async"
      [backdrop]="true"
      mode="over-parent"
      [text]="'common.client-teams.dialogs.loading' | translate"
    ></vdms-hq-ui-loader>
    <ng-container *ngIf="!data.preview">
      <vdms-hq-ui-form-section layout="single" [divider]="false">
        <vdms-hq-ui-form-input-text
          formControlName="name"
          [label]="'common.client-teams.dialogs.fields.name' | translate"
          [sharedE2eId]="'team-name-input'"
          class="d-block w50"
        ></vdms-hq-ui-form-input-text>
      </vdms-hq-ui-form-section>
      <div *ngIf="initialized$ | async" class="relative">
        <ng-container *ngIf="usersShowing$ | async; else manageCollections">
          <vdms-hq-ui-form-section layout="single" [divider]="false">
            <vdms-hq-ui-selectable-tiles
              [dataSource]="clientUsersDataSource"
              [filters]="clientUsersDataSource.filters"
              [config]="usersSelectableConfig"
              (updateSelectedListEmit)="usersSelectableConfig.selectedList.data = $event"
              [selected]="form.controls.user_uuids.value ?? []"
              (selectedChangeEmit)="form.controls.user_uuids.setValue($event)"
              (updateConfigEmit)="usersSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
            >
            </vdms-hq-ui-selectable-tiles>
          </vdms-hq-ui-form-section>
        </ng-container>
        <ng-template #manageCollections>
          <vdms-hq-ui-form-section layout="single" [divider]="false">
            <vdms-hq-ui-selectable-tiles
              [dataSource]="collectionsDataSource"
              [filters]="collectionsDataSource.filters"
              [config]="collectionsSelectableConfig"
              (updateSelectedListEmit)="collectionsSelectableConfig.selectedList.data = $event"
              [selected]="form.controls.shared_collection_uuids.value ?? []"
              (selectedChangeEmit)="form.controls.shared_collection_uuids.setValue($event)"
              (updateConfigEmit)="collectionsSelectableConfig = $event; saveViewSettings($event.searchView.showSearch)"
              (emptyActionButtonClick)="createNewCollection()"
            >
            </vdms-hq-ui-selectable-tiles>
          </vdms-hq-ui-form-section>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="data.preview">
      <div content class="relative p-scroll">
        <vdms-hq-ui-form-section layout="grid-2-columns-wide" [divider]="false">
          <vdms-hq-ui-form-section
            layout="single"
            class="border-right pb-1"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.users' | translate"
            [icon]="'groups'"
          >
            <ul>
              <li *ngFor="let user of previewUsers">
                {{ user }}
              </li>
            </ul>
          </vdms-hq-ui-form-section>
          <vdms-hq-ui-form-section
            layout="single"
            class="pb-1"
            [divider]="false"
            [titleSize]="'medium'"
            [title]="'common.client-teams.dialogs.fields.collections' | translate"
            [icon]="'playlist_add'"
          >
            <ul>
              <li *ngFor="let collection of previewCollections">
                {{ collection }}
              </li>
            </ul>
          </vdms-hq-ui-form-section>
        </vdms-hq-ui-form-section>
      </div>
    </ng-container>
  </div>
  <div footer>
    <vdms-hq-ui-button color="secondary" (click)="closeDialog()" [sharedE2eId]="'team-create-edit-cancel-button'">
      {{ 'common.global.cancel' | translate }}
    </vdms-hq-ui-button>
    <ng-container *ngIf="(initialized$ | async) && !this.data.preview">
      <vdms-hq-ui-button
        color="primary"
        [sharedE2eId]="'team-create-edit-save-button'"
        [loading]="isLoading$ | async"
        [disabled]="form.invalid"
        (click)="onSave()"
      >
        {{
          ((isEdit$ | async) ? 'common.client-teams.dialogs.edit.button' : 'common.client-teams.dialogs.create.button')
            | translate
        }}
      </vdms-hq-ui-button>
    </ng-container>
  </div>
</vdms-hq-ui-dialog-wrapper>
