import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  CUSTOM_ELEMENTS_SCHEMA,
  Input,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselData } from '../logic/model';
import { UIPipesModule } from '../../ui-pipes/ui-pipes.module';
import { ConnectableDataSource, CountdownPipe, LoadableDataSource } from '@vdms-hq/shared';
import { UILoaderModule } from '../../ui-loader';
import { ValueByPathPipe } from '../../ui-data-presentation/pipes/value-by-path.pipe';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperDirective } from '../swiper.directive';

export type CarouselDataSource<T = CarouselData> = ConnectableDataSource<T> & LoadableDataSource;

@Component({
  selector: 'vdms-hq-ui-carousel[dataSource]',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UIPipesModule, UILoaderModule, CountdownPipe, ValueByPathPipe, SwiperDirective],
  providers: [],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselComponent {
  @Input() dataSource!: CarouselDataSource;
  @ContentChild(TemplateRef) template!: TemplateRef<unknown>;

  config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 10,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
  };
}
