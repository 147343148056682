import { Injectable } from '@angular/core';
import { FilterDefinitionModel, FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Filters } from '@vdms-hq/api-contract';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take, tap } from 'rxjs/operators';
import { CurrentOrderAssetsDataSource } from './current-order-assets.ds';
import { AssetFiltersForm } from './models';
import { AssetMasterType, DataProviderService, SelectorSourceType } from '@vdms-hq/selectors';
import { getFieldDefById } from '@vdms-hq/fields';

@Injectable()
export class OrderAssetsFilterService {
  appliedFilters$ = new BehaviorSubject<Filters>({});

  config?: FilterDefinitionModel[];

  constructor(
    private translationService: TranslateService,
    private currentAssetsService: CurrentOrderAssetsDataSource,
    private dataProvider: DataProviderService,
  ) {}

  form = new UntypedFormGroup({
    text: new UntypedFormControl(),
    txDate: new UntypedFormControl(),
    facilityOfOrigin: new UntypedFormControl(),
    assetMasterType: new UntypedFormControl(),
  });

  listenUntil = (subject: Observable<void>) => {
    this.form.valueChanges
      .pipe(
        takeUntil(subject),
        tap((filters) => {
          this.currentAssetsService.applyFilters(filters);
        }),
      )
      .subscribe(() => {
        this.#setAppliedFilters();
      });
  };

  #setAppliedFilters() {
    const filtersToApply: Filters = {};

    this.config?.forEach((filterDef) => {
      const filterDefId = filterDef.id as keyof AssetFiltersForm;
      let value = this.form.value[filterDefId];

      if ('assetMasterType' in this.form.value && Array.isArray(value)) {
        value = value.map((v) => AssetMasterType.find((type) => type.key === v)?.label) as string[];
      }

      if (value) {
        filtersToApply[filterDef.id] = {
          filterDef,
          value,
        };
      }
    });

    this.appliedFilters$.next(filtersToApply);
  }

  initConfig() {
    if (this.config) {
      return;
    }

    this.dataProvider
      .listForSelectors(SelectorSourceType.FACILITY_OF_ORIGIN)
      .pipe(take(1))
      .subscribe((facilityOriginOptions) => {
        this.config = [
          {
            id: 'text',
            label: 'Keywords',
            filters: {
              objectPath: 'text',
              validFormat: 'keyword',
              type: FilterType.MASTER_TEXT,
            },
          },
          getFieldDefById('txDate'),
          {
            ...getFieldDefById('facilityOfOrigin'),
            selectOptions: facilityOriginOptions,
          },
          {
            id: 'assetMasterType',
            label: 'common.asset.general.type',
            format: ValueFormat.AS_IS,
            sourceListKey: SelectorSourceType.ASSET_MASTER_TYPE,
            filters: {
              objectPath: 'type',
              type: FilterType.SELECTOR,
              validFormat: 'keyword',
              aggregationKey: 'type',
            },
            isMultiple: true,
            selectOptions: AssetMasterType,
          },
        ].map((config) => ({
          ...config,
          label: this.translationService.instant(config.label),
        })) as FilterDefinitionModel[];
      });
  }
}
