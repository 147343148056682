import { FilterType, ResourceModel, ValueFormat } from '@vdms-hq/shared';
import { DELIVERY_STATUS, DOWNLOAD_STATUS, ORDER_TYPE, SORT_BY, STATUS_OPTIONS } from './orders-filters-provider';
import { DynamicFilterInput } from '@vdms-hq/dynamic-filters';
import moment from 'moment-timezone';

export const sharedPacksMetadata = [
  'sharedBy',
  'sharedWith',
  'totalAssets',
  'expiryDate',
  'totalSize',
  'usedDepartment',
  'comment',
];

export const mediaPulseMetadata = ['purchaseOrderNo', 'deliveryDate', 'status', 'comment'];

export const FILTER_DATE_DAYS_DURATION = 90;
export const dateInitial = (duration = FILTER_DATE_DAYS_DURATION) => ({
  from: moment(new Date()).subtract(duration, 'days'),
  to: moment(new Date()),
});

export const salesForceFiltersInitialValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: 'own',
  txDate: dateInitial(),
};

export const defaultInitialValues = (duration: number) => ({
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: 'own',
  txDate: dateInitial(duration),
});

export const sharedPacksInitialValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: 'shared-packs',
  txDate: dateInitial(),
};

export const approvalInitialValues = (duration: number) => ({
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'pending',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: null,
  txDate: dateInitial(duration),
});

export const salesForceFiltersApprovalValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'pending',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: null,
  txDate: dateInitial(),
};

export const mediaPulseOrdersValues = {
  keyword: '',
  sort: SORT_BY[0].key,
  status: 'all',
  deliveryStatus: null,
  downloadStatus: null,
  ordersType: 'own',
  txDate: {
    from: null,
    to: null,
  },
};

export const mediaPulseFilters: DynamicFilterInput[] = [
  {
    id: 'orderNo',
    label: 'common.orders.filters.order_no',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'wo_desc',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'keyword',
    label: 'common.orders.filters.order_title',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'poNo',
    label: 'common.orders.filters.po_no',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'po',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.due_date',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const defaultFiltersOnlyMine: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: '',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'status',
    label: '',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'status',
      type: FilterType.RADIO_LIST,
    },
    selectOptions: STATUS_OPTIONS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
];

export const defaultFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'status',
    label: '',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'status',
      type: FilterType.RADIO_LIST,
    },
    selectOptions: STATUS_OPTIONS,
    scope: ['other-orders'],
  },
  {
    id: 'ordersType',
    label: 'common.orders.filters.orders_type',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'ordersType',
      type: FilterType.SELECTOR,
    },
    selectOptions: ORDER_TYPE,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const salesForceFiltersOnlyMine: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_sales_force',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'deliveryStatus',
    label: 'common.orders.filters.delivery_status',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'deliveryStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: DELIVERY_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const salesForceFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_sales_force',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'deliveryStatus',
    label: 'common.orders.filters.delivery_status',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'deliveryStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: DELIVERY_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'ordersType',
    label: 'common.orders.filters.orders_type',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'ordersType',
      type: FilterType.SELECTOR,
    },
    selectOptions: ORDER_TYPE,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
  {
    id: 'txDate',
    label: 'common.orders.filters.order_date',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.DATE,
    filters: {
      validFormat: 'range',
      objectPath: 'delivery_date',
      type: FilterType.DATEPICKER_RANGE,
    },
    scope: ['other-orders'],
  },
];

export const sharedPacksFilters: DynamicFilterInput[] = [
  {
    id: 'keyword',
    label: 'common.orders.filters.keyword_shared_pack',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      objectPath: 'keyword',
      validFormat: 'keyword',
      type: FilterType.MASTER_TEXT,
    },
    scope: ['other-orders'],
  },
  {
    id: 'downloadStatus',
    label: 'common.orders.filters.download_status',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'downloadStatus',
      type: FilterType.SELECTOR,
    },
    selectOptions: DOWNLOAD_STATUS,
    scope: ['other-orders'],
  },
  {
    id: 'sort',
    label: 'common.orders.filters.sort',
    resource: [ResourceModel.ASSET_ORDER],
    format: ValueFormat.AS_IS,
    filters: {
      validFormat: 'keyword',
      objectPath: 'sort',
      type: FilterType.SELECTOR,
    },
    selectOptions: [...SORT_BY],
    scope: ['other-orders'],
  },
];
