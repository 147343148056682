import { ContractListItem } from '@vdms-hq/api-contract';
import { snakeToCapitalizedString } from '@vdms-hq/shared';

export type RightsContractsViewModel = ContractListItem & {
  props: ContractListItem;
  licensed_package_names: string[];
  licensed_package_uuids: string[];
  licensed_package_count: number;
};

export class RightsContractsView implements RightsContractsViewModel {
  constructor(public props: ContractListItem) {}

  static fromContract(contract: ContractListItem) {
    return new RightsContractsView(contract);
  }

  get uuid() {
    return this.props.uuid;
  }

  get name() {
    return this.props.name;
  }

  get contract_id() {
    return this.props.contract_id;
  }

  get license_id() {
    return this.props.license_id;
  }

  get start_date() {
    return this.props.start_date;
  }

  get end_date() {
    return this.props.end_date;
  }

  get created_at() {
    return this.props.created_at;
  }

  get updated_at() {
    return this.props.updated_at;
  }

  get status() {
    return snakeToCapitalizedString(this.props.status ?? '').trim();
  }

  get partner() {
    return this.props.partner;
  }

  get licensed_packages() {
    return this.props.licensed_packages;
  }

  get licensed_package_uuids() {
    return this.props.licensed_packages.map((lp) => lp.uuid);
  }

  get licensed_package_names() {
    return this.props.licensed_packages.map((lp) => lp.name);
  }

  get licensed_package_count() {
    return this.props.licensed_packages.length;
  }
}
