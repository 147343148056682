import { inject, Injectable, NgZone } from '@angular/core';
import { ASPERA_CONFIG, AsperaConfig } from '../config-token';
import { pathSource } from '@vdms-hq/api-contract';
import { AsperaNotifier } from '../../../aspera-notifier.service';
import { TransferEvent, TransfersEvent } from './model/aspera-transfer-event.model';
import { BehaviorSubject } from 'rxjs';

declare const AW4: any;

@Injectable({
  providedIn: 'root',
})
export class AsperaDownloaderService {
  private config = inject<AsperaConfig>(ASPERA_CONFIG);
  private ngZone = inject(NgZone);
  private asperaNotifier = inject(AsperaNotifier);

  connected$ = new BehaviorSubject<boolean>(false);
  #completedTransferSubject$ = new BehaviorSubject<TransferEvent | null>(null);
  completedTransfer$ = this.#completedTransferSubject$.asObservable();

  asperaWeb: any;

  destroy() {
    delete this.asperaWeb;
    this.asperaWeb.removeEventListener();
    this.connected$.next(false);
  }

  initAsperaConnect() {
    if (this.asperaWeb) {
      return;
    }

    this.asperaWeb = new AW4.Connect();
    this.asperaWeb.initSession();

    this.asperaWeb.addEventListener('transfer', (event: 'transfer', obj: TransfersEvent) => {
      obj.transfers.forEach((eventTransfer) => {
        if (this.connected$.value && eventTransfer.status === 'completed') {
          this.#completedTransferSubject$.next(eventTransfer);
        }
      });

      if (!this.connected$.value) {
        this.connected$.next(true);
      }
    });

    this.asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType: unknown, data: unknown) => {
      if (
        eventType === AW4.Connect.EVENT.STATUS &&
        (data === AW4.Connect.STATUS.EXTENSION_INSTALL || data === AW4.Connect.STATUS.FAILED)
      ) {
        this.asperaNotifier.notDetected();
      }
    });
  }

  runOutsideAngular(transferSpec: { [p: string]: string | pathSource[] | number }): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.config.debug) {
        // eslint-disable-next-line no-restricted-syntax
        console.debug('DEBUG: Aspera download triggered', transferSpec);
      }

      this.asperaWeb.startTransfer(transferSpec);
    });
  }
}
