import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { DialogResponse, FieldsScopeKey, SelectOption } from '@vdms-hq/shared';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { UIButtonModule, UIDialogWrapperModule, UIFormModule } from '@vdms-hq/ui';
import { FieldsFetcherService } from '../../logic/fields-fetcher.service';
import { TranslateModule } from '@ngx-translate/core';
import { fieldsToSelectOptions } from '../../logic/transformers';
import { TableFieldsConfiguration } from '../../logic/fields-scoped-configuration.model';
import { FieldsSaverService } from '../../logic/fields-saver.service';

export type ColumnsConfigDialogData = { scope: FieldsScopeKey };

@Component({
  selector: 'vdms-hq-fields-columns-dialog',
  templateUrl: './columns-config-dialog.component.html',
  styleUrls: ['./columns-config-dialog.component.scss'],
  standalone: true,
  imports: [UIDialogWrapperModule, UIFormModule, UIButtonModule, TranslateModule],
})
export class ColumnsConfigDialogComponent implements OnInit, OnDestroy {
  private fieldsSaverService = inject(FieldsSaverService);

  private columnsConfigDialogRef = inject(MatDialogRef<ColumnsConfigDialogComponent>);
  private fieldsFetcher = inject(FieldsFetcherService);
  public data = inject<ColumnsConfigDialogData>(MAT_DIALOG_DATA);

  formGroup = new FormGroup({
    listSortableCheckboxValue: new FormControl<string[]>([], Validators.required),
  });
  currentSettings: SelectOption[] = [];
  availableList: SelectOption[] = [];
  defaultList: SelectOption[] = [];
  isUserConfig = false;
  alwaysEnabledList = [];
  #destroyed = new Subject<void>();

  get listSortableCheckboxValues(): AbstractControl | null {
    return this.formGroup.get('listSortableCheckboxValue');
  }

  ngOnInit() {
    this.#initialize();
  }

  ngOnDestroy(): void {
    this.#destroyed.next();
    this.#destroyed.complete();
  }

  #initialize = () => {
    if (!this.data.scope) {
      console.error('RuntimeError: ScopeName or table config is required');
      return;
    }

    this.fieldsFetcher.getConfiguration$(this.data.scope).subscribe((config) => {
      if (!config.table) {
        console.error('RuntimeError: Table configuration does not exist for scope ' + this.data.scope);
        return;
      }

      this.#initConfig(config.table);
    });
  };

  cancel(): void {
    this.columnsConfigDialogRef.close({ status: DialogResponse.ABORT });
  }

  onSubmit() {
    const columnsConfig = this.listSortableCheckboxValues?.value;

    this.fieldsSaverService.saveUserColumnsConfig(this.data.scope, columnsConfig).subscribe((results) => {
      results &&
        this.columnsConfigDialogRef.close({
          status: DialogResponse.OK,
          columns: this.listSortableCheckboxValues?.value,
        });
    });
  }

  selectAll() {
    this.listSortableCheckboxValues?.patchValue(this.availableList.map((item) => item.key));
  }

  deselectAll() {
    this.listSortableCheckboxValues?.patchValue([]);
  }

  revertDefaults() {
    this.listSortableCheckboxValues?.patchValue(this.defaultList.map((item) => item.key));
  }

  #initConfig(table: TableFieldsConfiguration) {
    this.currentSettings = fieldsToSelectOptions(table.visible);
    this.defaultList = fieldsToSelectOptions(table.client.enabled);
    this.availableList = fieldsToSelectOptions(table.getEnabledWithAvailable());

    this.isUserConfig = table.user !== null;

    this.listSortableCheckboxValues?.patchValue(this.currentSettings.map((item) => item.key));
  }
}
