import { FilterType, ResultDefinitionModel } from '@vdms-hq/shared';
import { Timecode } from '@vdms-hq/timecode';
import { isEqual } from 'lodash';
import { Moment } from 'moment/moment';
import objectPath from 'object-path';
import { AssetSearchFilterParam } from '../rest/asset';
import { hasRangeValues } from './filters.model';
import { PageOptions } from './pagination.model';
import { PersistenceSearchQuery } from './persistence-search-query';
import { SortOptions } from './sort.model';
import { AppOptions } from './app-type.model';

export class PersistenceSearchParams {
  static readonly defaultPerPage = 24;
  static readonly defaultPage = 0;

  constructor(
    public query: PersistenceSearchQuery,
    public pageOptions: PageOptions,
    public sortOptions?: SortOptions,
    public appOption: AppOptions | undefined = undefined,
    readonly initial = false,
    public clientId?: string,
  ) {}

  static empty() {
    return new PersistenceSearchParams(PersistenceSearchQuery.empty(), {
      perPage: PersistenceSearchParams.defaultPerPage,
      page: PersistenceSearchParams.defaultPage,
    });
  }

  static initialQuery(query: PersistenceSearchQuery, pageSize?: number) {
    return new PersistenceSearchParams(
      query,
      {
        perPage: pageSize ?? PersistenceSearchParams.defaultPerPage,
        page: PersistenceSearchParams.defaultPage,
      },
      undefined,
      undefined,
      true,
    );
  }

  static defaultQuery(query: PersistenceSearchQuery) {
    return new PersistenceSearchParams(query, {
      perPage: PersistenceSearchParams.defaultPerPage,
      page: PersistenceSearchParams.defaultPage,
    });
  }

  static toApiParams(next: PersistenceSearchParams): AssetSearchFilterParam {
    const filters = {};

    Object.entries({
      ...next.query.hiddenFilters,
      ...next.query.filters,
    }).forEach(([, filter]) => {
      const givenValue = filter.value;
      let value;

      const isRangeValue = hasRangeValues(givenValue);

      switch (filter.filterDef.filters.type) {
        case FilterType.BITRATE_RANGE:
        case FilterType.SIZE_RANGE:
          if (isRangeValue) {
            value = {
              from: isRangeValue && isRangeValue.from,
              to: isRangeValue && isRangeValue.to,
            };
          }
          break;

        case FilterType.TIMECODE_RANGE:
          if (isRangeValue) {
            value = {
              from: isRangeValue && (isRangeValue.from as Timecode)?.countSeconds(),
              to: isRangeValue && (isRangeValue.to as Timecode)?.countSeconds(),
            };
          }
          break;
        case FilterType.DATEPICKER_RANGE:
          if (isRangeValue) {
            value = {
              from: isRangeValue && (isRangeValue.from as Moment)?.toISOString(),
              to: isRangeValue && (isRangeValue.to as Moment)?.toISOString(),
            };
          }
          break;
        case FilterType.NUMBER:
          value = Array.isArray(givenValue) ? givenValue : [Number(givenValue)];
          break;
        case FilterType.SELECTOR_GROUP:
        case FilterType.SELECTOR:
          if (
            ['is_dirty', 'yes_no', 'asset_status', 'is_quarantined'].includes(filter.filterDef?.sourceListKey ?? '')
          ) {
            value = Number(givenValue);
            break;
          }
          if (['is_purged', 'is_deleted'].includes(filter.filterDef?.sourceListKey ?? '')) {
            const plainValue = Array.isArray(givenValue) ? givenValue[0] : givenValue;
            value = !!Number(plainValue?.toString());
            break;
          }

          value = Array.isArray(givenValue) ? givenValue : [String(givenValue)];
          break;
        case FilterType.TEXT_AUTOCOMPLETE:
        case FilterType.TEXT:
        case FilterType.MASTER_TEXT:
        case FilterType.CHECKBOX_LIST:
        case FilterType.CHIPS:
          value = Array.isArray(givenValue) ? givenValue : [String(givenValue)];
      }

      if (value === null || value === undefined) {
        return;
      }
      objectPath.set(filters, filter.filterDef.filters.objectPath, value);
    });

    return {
      text: next.query.text,
      filters,
    };
  }

  get page() {
    return this.pageOptions.page;
  }

  get perPage() {
    return this.pageOptions.perPage;
  }

  get sortBy() {
    return this.sortOptions?.field;
  }

  get sortDirection() {
    return this.sortOptions?.direction;
  }

  setApp(app: AppOptions) {
    this.appOption = app;
  }

  setSort(field?: ResultDefinitionModel, direction?: 'asc' | 'desc') {
    if (!field || !direction) {
      this.sortOptions = undefined;
      return;
    }

    this.sortOptions = {
      field,
      direction: direction === 'asc' ? 'asc' : 'desc',
    };
  }

  setPage(page: number, perPage: number) {
    this.pageOptions = {
      page,
      perPage,
    };
  }

  equals = (next: PersistenceSearchParams): boolean => {
    return (
      isEqual(this.pageOptions, next.pageOptions) &&
      isEqual(this.sortOptions, next.sortOptions) &&
      isEqual(this.appOption, next.appOption) &&
      this.query.isEqual(next.query) &&
      isEqual(this.clientId, next.clientId)
    );
  };

  clone = () =>
    new PersistenceSearchParams(
      this.query.clone(),
      { ...this.pageOptions },
      this.sortOptions ? { ...this.sortOptions } : undefined,
      this.appOption ? this.appOption : undefined,
      false,
      this.clientId,
    );
}
