import { BehaviorSubject, Observable } from 'rxjs';
import { TrackByFunction } from '@angular/core';
import { SortBy, SortDirection } from '../../models/sort/sort.model';

export interface ConnectableDataSource<T> {
  /**
   * @deprecated use connection instead
   */
  allData$: Observable<T[]>;
  connection$: Observable<T[]>;
  trackBy?: TrackByFunction<T>;
  disconnect?: () => void;
  sortBy$?: BehaviorSubject<SortBy>;
  sortDirection$?: BehaviorSubject<SortDirection>;
}

export class BaseConnectableDataSource<T> implements ConnectableDataSource<T> {
  allData$: Observable<T[]> = new Observable();
  connection$: Observable<T[]> = new Observable();
  trackBy?: TrackByFunction<T>;
  disconnect?: () => void;
}
