import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AssetType,
  CredentialTypeEnum,
  DestinationAccessMethodEnum,
  DestinationConfigModel,
  DestinationModel,
  DestinationRule,
  DestinationStatus,
  SimpleDestinationRule,
  UpdateDestinationModel,
  VideoDestinationAudioTrackModel,
  VideoDestinationRule,
} from '@vdms-hq/api-contract';
import { bucketNameValidator, emailsArrayValidator, filterEmpty, prefixValidator } from '@vdms-hq/shared';
import { BehaviorSubject, of, take } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DeliveryDestinationsValidators } from './delivery-destinations-validators';
import { isVideoType, transformVideoRuleToReadableObject } from './delivery-destination-transformer';

@Injectable({
  providedIn: 'root',
})
export class DestinationFormService {
  initialized$ = new BehaviorSubject(false);
  #currentModel?: DestinationModel;

  get value() {
    const value = this.form.getRawValue();
    let isPublishNull = true;

    const configs = (value.configs ?? []).map((config) => ({
      ...config,
      rules: (config.rules ?? []).map((rule) => {
        if (rule.publish?.enabled && rule.publish.type) {
          isPublishNull = false;
          switch (rule.publish.type) {
            case CredentialTypeEnum.AWS:
              rule.publish = {
                type: rule.publish.type,
                credentialUuid: rule.publish.credentialUuid,
                configData: {
                  region: rule.publish.configData?.region,
                  bucketName: rule.publish.configData?.bucketName,
                  prefix: rule.publish.configData?.prefix,
                  earPassphrase: undefined,
                  path: undefined,
                  channel: undefined,
                },
              };
              break;
            case CredentialTypeEnum.FTP:
              rule.publish = {
                type: rule.publish.type,
                credentialUuid: rule.publish.credentialUuid,
                configData: {
                  path: rule.publish.configData?.path,
                  channel: undefined,
                  region: undefined,
                  bucketName: undefined,
                  earPassphrase: undefined,
                  prefix: undefined,
                },
              };
              break;
            case CredentialTypeEnum.ASPERA_YOUTUBE:
            case CredentialTypeEnum.ASPERA_AOC:
            case CredentialTypeEnum.ASPERA_HSTS:
              rule.publish = {
                type: rule.publish.type,
                credentialUuid: rule.publish.credentialUuid,
                configData: {
                  region: undefined,
                  bucketName: undefined,
                  prefix: rule.publish.configData?.prefix,
                  earPassphrase: rule.publish.configData?.earPassphrase,
                  path: undefined,
                  channel: rule.publish.configData?.channel,
                },
              };
              break;
          }
        } else {
          isPublishNull = true;
        }

        if (rule.assetType !== 'video') {
          return {
            assetType: rule.assetType,
            contentClass: rule.contentClass ?? [],
            publish: isPublishNull ? null : rule.publish,
          } as SimpleDestinationRule;
        }

        if (rule.transcode?.enabled && rule.transcode.type) {
          const { enabled, ...transcode } = rule.transcode;

          return {
            ...rule,
            publish: isPublishNull ? null : rule.publish,
            transcode,
          } as VideoDestinationRule;
        }

        const { transcode, ...nextRule } = rule;
        nextRule.publish = isPublishNull ? null : rule.publish;
        return nextRule as VideoDestinationRule;
      }),
    }));

    return {
      ...value,
      configs,
    } as UpdateDestinationModel;
  }

  form = this.initializeForm();

  get configs() {
    return this.form.controls.configs;
  }

  get currentConfigFormGroup() {
    return this.#findConfig(this.currentConfigId$.value);
  }

  get currentRules() {
    return this.currentConfigFormGroup?.controls.rules;
  }

  get publishMethod() {
    return this.form.get('method')?.value;
  }

  currentDestination: string | null = null;

  currentConfigId$ = new BehaviorSubject<string | null>(null);

  currentConfigFormGroup$ = this.currentConfigId$.pipe(
    filterEmpty(),
    map((id) => this.#findConfig(id)),
  );

  currentConfigValue$ = this.currentConfigFormGroup$.pipe(
    switchMap((formGroup) => formGroup?.valueChanges.pipe(startWith(formGroup?.value ?? null)) ?? of(null)),
  );

  initializeForm() {
    return new FormGroup({
      method: new FormControl<DestinationAccessMethodEnum>(
        { disabled: true, value: DestinationAccessMethodEnum.VIDA },
        { nonNullable: true },
      ),
      type: new FormControl<'file' | 'title'>({ disabled: true, value: 'file' }, { nonNullable: true }),
      name: new FormControl<string>('', {
        validators: Validators.required,
        nonNullable: true,
      }),
      status: new FormControl<DestinationStatus>(DestinationStatus.DRAFT, {
        validators: Validators.required,
        nonNullable: true,
      }),
      email: new FormGroup({
        delivery: new FormControl<string[]>([], {
          validators: [Validators.required, emailsArrayValidator(true)],
          nonNullable: true,
        }),
        notification: new FormControl<string[]>([], {
          validators: [emailsArrayValidator(true)],
          nonNullable: true,
        }),
      }),
      publish: new FormGroup<{
        type: FormControl<CredentialTypeEnum | null>;
        enabled?: FormControl<boolean>;
        credentialUuid: FormControl<string | null>;
        configData: FormGroup<{
          channel: FormControl<string | null | undefined>;
          region: FormControl<string | null | undefined>;
          bucketName: FormControl<string | null | undefined>;
          prefix: FormControl<string | undefined>;
          earPassphrase: FormControl<string | null | undefined>;
          path: FormControl<string | null | undefined>;
        }>;
      } | null>({
        type: new FormControl<CredentialTypeEnum | null>(null),
        enabled: new FormControl<boolean>(true, { nonNullable: true }),
        credentialUuid: new FormControl<string | null>(null),
        configData: new FormGroup({
          channel: new FormControl<string | null | undefined>(null),
          region: new FormControl<string | null | undefined>(null),
          bucketName: new FormControl<string | null | undefined>(null),
          prefix: new FormControl<string | undefined>(''),
          earPassphrase: new FormControl<string | null | undefined>(null),
          path: new FormControl<string | null | undefined>(null),
        }),
      }),
      configs: new FormArray<
        FormGroup<{
          uuid: FormControl<string>;
          name: FormControl<string>;
          rules: FormArray<
            FormGroup<{
              assetType: FormControl<string>;
              contentClass: FormControl<string[]>;
              resolutions: FormControl<string[]>;
              videoCodecs: FormControl<string[]>;
              transcode: FormGroup<{
                type: FormControl<string | null>;
                enabled: FormControl<boolean>;
                overrideProfile: FormControl<string | null>;
                audioTracks: FormArray<
                  FormGroup<{
                    languageUuid: FormControl<string | undefined>;
                    contentClass: FormControl<string>;
                    layout: FormControl<string>;
                  }>
                >;
              }>;
              publish: FormGroup<{
                type: FormControl<CredentialTypeEnum | null>;
                enabled?: FormControl<boolean>;
                credentialUuid: FormControl<string | null>;
                configData: FormGroup<{
                  channel: FormControl<string | null | undefined>;
                  region: FormControl<string | null | undefined>;
                  bucketName: FormControl<string | null | undefined>;
                  prefix: FormControl<string | undefined>;
                  earPassphrase: FormControl<string | null | undefined>;
                  path: FormControl<string | null | undefined>;
                }>;
              }>;
            }>
          >;
        }>
      >([]),
    });
  }

  addConfig(value: DestinationConfigModel) {
    const group = new FormGroup({
      uuid: new FormControl<string>(value.uuid, { nonNullable: true }),
      name: new FormControl<string>(value.name, {
        validators: Validators.required,
        nonNullable: true,
      }),
      rules: new FormArray<FormGroup>([]),
    });

    if (value?.rules?.length) {
      value?.rules.forEach((rule) => {
        group.controls.rules.push(this.#buildRuleForm(rule));
      });
    }

    this.configs.push(group);
    return group;
  }

  addEmptyAudioTrack(rules: FormGroup) {
    const audioTracks = rules.get(['transcode', 'audioTracks']) as FormArray;
    audioTracks.push(this.#buildAudioTrack());
    audioTracks.markAsDirty();
  }

  deleteAudioTrack(audioTrackIndex: number, ruleIndex: number): void {
    const audioTracks = this.currentRules?.at(ruleIndex).get(['transcode', 'audioTracks']) as FormArray;
    audioTracks.removeAt(audioTrackIndex);
    audioTracks.markAsDirty();
  }

  deleteRule(index: number): void {
    this.currentRules?.removeAt(index);
    this.currentRules?.markAsDirty();
  }

  addNewRule(assetType: AssetType) {
    this.currentRules?.push(this.#buildRuleForm({ assetType }) as FormGroup);
    this.currentRules?.markAsDirty();
  }

  #buildAudioTrack(value?: Partial<VideoDestinationAudioTrackModel>) {
    return new FormGroup({
      languageUuid: new FormControl(value?.languageUuid ?? undefined),
      contentClass: new FormControl(value?.contentClass ?? '', { nonNullable: true }),
      layout: new FormControl(value?.layout ?? '', { nonNullable: true }),
    });
  }

  #buildRuleForm(value: DestinationRule | { assetType: AssetType }) {
    const video = isVideoType(value as DestinationRule);
    const hasTranscode = video && !!(value as VideoDestinationRule)?.transcode?.type;
    const hasPublish = !!(value as DestinationRule)?.publish?.type;
    const audioTracks = hasTranscode
      ? ((value as VideoDestinationRule)?.transcode?.audioTracks ?? []).map(
          (item) => this.#buildAudioTrack(item) as FormGroup,
        )
      : [];

    const ruleForm = new FormGroup({
      assetType: new FormControl<string>(value.assetType, { nonNullable: true }),
      contentClass: new FormControl<string[]>([], { nonNullable: true }),
      resolutions: new FormControl<string[]>([], { nonNullable: true }),
      videoCodecs: new FormControl<string[]>([], { nonNullable: true }),
      transcode: new FormGroup({
        type: new FormControl<string | null>(null),
        enabled: new FormControl<boolean>(hasTranscode, { nonNullable: true }),
        burnInText: new FormControl<boolean>(false, { nonNullable: true }),
        overrideProfile: new FormControl<any>(null),
        audioTracks: new FormArray(audioTracks),
      }),
      publish: new FormGroup({
        type: new FormControl<CredentialTypeEnum | null>(null),
        enabled: new FormControl<boolean>(hasPublish, { nonNullable: true }),
        credentialUuid: new FormControl<string | null>(null),
        configData: new FormGroup({
          channel: new FormControl<string | null | undefined>(null),
          region: new FormControl<string | null | undefined>(null),
          bucketName: new FormControl<string | null | undefined>(null),
          prefix: new FormControl<string | undefined>(''),
          earPassphrase: new FormControl<string | null | undefined>(null),
          path: new FormControl<string | null | undefined>(null),
        }),
      }),
    });

    const transcodeType = ruleForm.controls.transcode.controls?.type;
    if (hasTranscode || ruleForm.controls.transcode.controls?.enabled.value === true) {
      transcodeType.setValidators(Validators.required);
    } else {
      transcodeType.removeValidators(Validators.required);
      ruleForm.controls.transcode.controls.overrideProfile.patchValue(null);
      ruleForm.controls.transcode.controls.burnInText.patchValue(false);
    }

    const publishType = ruleForm.controls.publish.controls?.type;
    const publish = ruleForm.controls.publish.controls;
    if (hasPublish || publish?.enabled.value === true) {
      publishType.setValidators(Validators.required);
      switch (publishType.value) {
        case CredentialTypeEnum.AWS:
          publish.credentialUuid?.setValidators(Validators.required);
          publish.configData.controls.region?.setValidators(Validators.required);
          publish.configData.controls.bucketName?.setValidators([Validators.required, bucketNameValidator()]);
          publish.configData.controls.prefix?.setValidators(prefixValidator());
          break;
        case CredentialTypeEnum.ASPERA_YOUTUBE:
          publish.credentialUuid?.setValidators(Validators.required);
          publish.configData.controls.channel?.setValidators(Validators.required);
          publish.configData.controls.earPassphrase?.removeValidators(Validators.required);
          publish.configData.controls.prefix?.setValidators(prefixValidator());
          break;
        case CredentialTypeEnum.ASPERA_AOC:
        case CredentialTypeEnum.ASPERA_HSTS:
          publish.credentialUuid?.setValidators(Validators.required);
          publish.configData.controls.earPassphrase?.removeValidators(Validators.required);
          publish.configData.controls.prefix?.setValidators(prefixValidator());
          break;
      }
    } else {
      publishType.removeValidators(Validators.required);
      publish.credentialUuid?.removeValidators(Validators.required);
      publish.configData.controls.region?.removeValidators(Validators.required);
      publish.configData.controls.channel?.removeValidators(Validators.required);
      publish.configData.controls.bucketName?.removeValidators(Validators.required);
      publish.configData.controls.prefix?.removeValidators(prefixValidator());
      publish.configData.controls.earPassphrase?.removeValidators(Validators.required);
    }

    ruleForm.controls.transcode.controls.overrideProfile.setValidators(
      DeliveryDestinationsValidators.hybrikProfileJsonValidator(ruleForm),
    );

    ruleForm.patchValue(value);

    return ruleForm;
  }

  #findConfig(uuid: string | null) {
    return this.configs.controls.find((item) => item.controls.uuid.value === uuid);
  }

  setConfigId(configId: string | null) {
    this.initialized$.pipe(filterEmpty()).subscribe(() => {
      this.currentConfigId$.next(configId);
    });
  }

  initialize(model: DestinationModel) {
    if (!model) {
      return;
    }

    model = {
      ...model,
      configs: model.configs
        .reduce((prev, curr) => {
          const elementExist = prev.some(({ uuid }) => curr.uuid === uuid);
          if (elementExist) {
            return prev;
          }

          return [curr, ...prev];
        }, <DestinationConfigModel[]>[])
        .map((config) => ({
          ...config,
          rules: config?.rules?.map(transformVideoRuleToReadableObject),
        })),
    };

    if (!this.initialized$.value || this.currentDestination != model.uuid) {
      this.form = this.initializeForm();
      this.form.patchValue({
        ...model,
        email: model.email,
        method: model.method,
        type: model.type,
        name: model.name,
        status: model.status ?? DestinationStatus.DRAFT,
        publish: model.publish,
        configs: model.configs,
      });
    } else {
      this.form.patchValue({
        email: model.email,
        method: model.method,
        type: model.type,
        name: model.name,
        status: model.status ?? DestinationStatus.DRAFT,
        publish: model.publish,
        configs: model.configs,
      });
    }
    this.currentDestination = model.uuid;

    model.configs.forEach((config) => {
      const uuid = config.uuid;

      if (uuid && !this.configs.value.find((cfg) => cfg.uuid === uuid)) {
        this.addConfig(config);
      }
    });

    this.form.markAsPristine();
    this.#currentModel = model;
    this.initialized$.next(true);
  }

  resetToCurrent() {
    if (!this.#currentModel) {
      return;
    }

    this.initialize(this.#currentModel);
  }
}
