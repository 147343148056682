import { Routes } from '@angular/router';
import { CredentialsListComponent } from './pages/credentials-list/credentials-list.component';
import { YoutubeAuthComponent } from './pages/youtube-auth/youtube-auth.component';

export enum CREDENTIALS_ROUTES {
  ROOT = 'credentials',
  ROOT_YOUTUBE_AUTH = 'youtube-auth',
}

export const CREDENTIALS_ROUTING: Routes = [
  {
    path: '',
    component: CredentialsListComponent,
  },
  {
    path: '**',
    redirectTo: `/${CREDENTIALS_ROUTES.ROOT}`,
    pathMatch: 'full',
  },
];

export const YOUTUBE_AUTH_ROUTING: Routes = [
  {
    path: '',
    component: YoutubeAuthComponent,
  },
];
