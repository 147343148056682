<vdms-hq-ui-layout-page-wrapper
  [breadcrumbs]="[{ name: 'Quarantine Config' }]"
  *ngIf="form$ | async as form; else loading"
>
  <vdms-hq-ui-form-section
    *requiresPermissions="[Permission.MANAGE_QUARANTINE_CONFIGS]"
    [divider]="true"
    layout="single"
  >
    <div [formGroup]="form">
      <vdms-hq-ui-form-checkbox-list
        [loading]="isLoading$ | async"
        [columns]="1"
        [label]="'pages.admin.quarantine' | translate"
        orientation="horizontal"
        formControlName="quarantine"
        [available]="quarantineSelectOptions"
      >
      </vdms-hq-ui-form-checkbox-list>
    </div>
  </vdms-hq-ui-form-section>
  <ng-container *ngIf="suppliersQuarantineRules$ | async as suppliersRules">
    <vdms-hq-ui-form-section
      *requiresPermissions="[Permission.MANAGE_QUARANTINE_CONFIGS]"
      layout="single"
      [divider]="false"
      [title]="'Suppliers configs with quarantine enabled'"
      [titleSize]="'medium'"
    >
      <div class="suppliers-wrapper">
        <div class="supplier-config" *ngFor="let supplier of suppliersRules">
          <div class="supplier-config_title">{{ supplier.name }}</div>
          <div class="pill done" *ngFor="let rule of supplier.rules">{{ rule }}</div>
        </div>
        <vdms-hq-ui-empty-results
          *ngIf="suppliersRules.length === 0"
          [message]="'common.global.no_quarantined_results' | translate"
          [type]="'compact'"
          [icon]="'add_moderator'"
        >
        </vdms-hq-ui-empty-results>
      </div>
    </vdms-hq-ui-form-section>
  </ng-container>
</vdms-hq-ui-layout-page-wrapper>

<vdms-hq-ui-actions-footer *ngIf="form$ | async as form">
  <vdms-hq-ui-button
    right
    color="primary"
    (click)="save()"
    [loading]="isSaving$ | async"
    [disabled]="form.invalid || form.pristine"
  >
    {{ 'common.global.save' | translate }}
  </vdms-hq-ui-button>
</vdms-hq-ui-actions-footer>

<ng-template #loading>
  <vdms-hq-ui-loader></vdms-hq-ui-loader>
</ng-template>
