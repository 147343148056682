import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgModel } from '@angular/forms';
import { concatMap, Observable, repeat, timer } from 'rxjs';
import { delay, map, take, withLatestFrom } from 'rxjs/operators';
import { UIButtonModule, UIFormModule } from '@vdms-hq/ui';
import { filterEmpty, FilterType, SelectOption } from '@vdms-hq/shared';
import { AssetSearchService, PersistenceSearchParams, PersistenceSearchQuery } from '@vdms-hq/api-contract';
import { LastFiltersService } from '../../../logic/services/last-filters.service';
import { MatIconModule } from '@angular/material/icon';
import { TourItemComponent } from '@vdms-hq/tour-guide';
import { StringPipesModule } from 'ng-pipes';
import { AuthService } from '@vdms-hq/auth';
import { SearchResultsConfigFieldsService } from '../../../logic/services/search-results-config-fields.service';

@Component({
  selector: 'vdms-hq-search-hero-input',
  templateUrl: './search-hero-input.component.html',
  styleUrls: ['./search-hero-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    TourItemComponent,
    UIFormModule,
    UIButtonModule,
    StringPipesModule,
  ],
  providers: [],
})
export class SearchHeroInputComponent implements OnInit {
  #assetSearchService = inject(AssetSearchService);
  #lastFiltersService = inject(LastFiltersService);
  #fieldsConfigService = inject(SearchResultsConfigFieldsService);
  #authService = inject(AuthService);

  focus = false;
  touched = false;

  currentQuery = '';
  selectedFilter = 'keyword';
  @Input() alternativeAppearance = false;
  @Input() displayReturnButton = true;
  @Input() displaySearchButton = true;

  @ViewChild('input', { read: NgModel }) input?: NgModel;
  readonly text = 'Type something here...';

  placeholder$ = timer(0, 100).pipe(
    take(this.text.length),
    map((i) => this.text.slice(0, i + 1)),
    concatMap((char) => timer(0).pipe(map(() => char))),
    delay(3000),
    repeat(),
  );

  latestQueries$: Observable<SelectOption[]> = this.#lastFiltersService.getSavedSearchesSelectOption$();

  availableFilters: SelectOption<string>[] = [];

  ngOnInit(): void {
    this.#fieldsConfigService.filters$.subscribe((config) => {
      this.availableFilters = [
        {
          key: 'keyword',
          label: 'Keyword',
        },
        ...config
          .filter((filter) =>
            [FilterType.TEXT, FilterType.TEXT_AUTOCOMPLETE, FilterType.CHIPS].includes(filter.filters.type),
          )
          .filter((filter) => ['tags', 'transcriptions', 'uuid', 'originalFilename'].includes(filter.id))
          .map((filter) => ({
            key: filter.id,
            label: filter.label,
          })),
      ];
    });
  }

  updateQuery() {
    if (!this.input?.valid) {
      return;
    }

    if (this.selectedFilter === 'keyword') {
      this.#authService.userAttributes$.pipe(filterEmpty(), take(1)).subscribe((attributes) => {
        const query = this.currentQuery
          ? PersistenceSearchQuery.freeText(this.currentQuery)
          : PersistenceSearchQuery.empty();

        this.#assetSearchService.applyParams(
          PersistenceSearchParams.initialQuery(query, attributes.vida.preferredPageSize),
        );
      });

      return;
    }

    this.#fieldsConfigService.filters$
      .pipe(take(1), withLatestFrom(this.#authService.userAttributes$))
      .subscribe(([filters, attributes]) => {
        const filter = filters.find((filter) => filter.id === this.selectedFilter);

        if (!filter) {
          return;
        }

        const query = PersistenceSearchQuery.fromFilters(undefined, {
          [filter.id]: {
            filterDef: filter,
            value: this.currentQuery,
          },
        });

        this.#assetSearchService.applyParams(
          PersistenceSearchParams.initialQuery(query, attributes?.vida?.preferredPageSize ?? 12),
        );
      });
  }

  removeAutocompleteItem(item: SelectOption) {
    this.#lastFiltersService.removeSavedSearchPhrase(item);
  }

  keyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.updateQuery();
    }
  }

  selectFromHistory(key: string) {
    console.log('implement me', key);
  }
}
